import React, { useState, useEffect } from "react";
import { Table, message, Space, Modal, Pagination, Button } from "antd";
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { get, put, remove } from "../../../utils/apiUtils";

interface DeletedProjectsModalProps {
  visible: boolean;
  onCancel: () => void;
  workspaceId: any;
  restore: any;
}

const DeletedProject: React.FC<DeletedProjectsModalProps> = ({
  visible,
  onCancel,
  workspaceId,
  restore,
}) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionType, setSelectionType] = useState<"checkbox">("checkbox");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalProjects, setTotalProjects] = useState(0);

  useEffect(() => {
    if (visible) {
      fetchDeletedProjects();
    }
  }, [visible, currentPage, pageSize]);

  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Time remaining",
      dataIndex: "time_remaining",
      key: "time_remaining",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          <ReloadOutlined onClick={() => restoreProject(record.id)} />
          <DeleteOutlined onClick={() => deleteProjectPermanently(record.id)} />
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled Project", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const fetchDeletedProjects = async () => {
    setLoading(true);
    try {
      let response = await get<any>("projects/deleted");
      if (response.type === "success") {
        setProjects(response.data);
      } else {
        message.error("Failed to fetch deleted projects");
      }
    } catch (error: any) {
      message.error("An error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeletedProjects();
  }, []);

  const restoreProject = async (id: any) => {
    try {
      setLoading(true);

      let response = await put<any>(`projects/${id}/restore`, {});
      if (response.type === "success") {
        message.success(response?.data.message);
        await fetchDeletedProjects();

        if (restore) {
          restore();
        }
      }
    } catch (error: any) {
      message.error("Failed to restore project: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteProjectPermanently = async (id: any) => {
    try {
      if (!window.confirm("Are you sure you want to delete this project?")) {
        return;
      }

      setLoading(true);

      let response = await remove(`projects/${id}/permanent`);

      if (response?.type === "success") {
        message.success("Project deleted successfully");
        await fetchDeletedProjects();
      }
    } catch (error: any) {
      message.error("Failed to delete project: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <Modal
      title="Deleted Projects"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} className="go-back-button">
          Go back
        </Button>,
      ]}
      className="archived-project-modal"
      style={{ minWidth: "1046px" }}
    >
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={projects}
        rowKey="id"
        scroll={{ x: 700 }}
      />
    </Modal>
    /* {loading ? (
        <p>Loading...</p>
      ) : (
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={projects}
          rowKey="id"
          scroll={{ x: 700 }}
        />
      )} */
  );
};

export default DeletedProject;

import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Button, Input, Select, Switch, Form, message } from "antd";
import ProfileInformationHeader from "../../../assets/img/profileInformation.png";
import "./index.css";
import { get, put } from "../../../utils/apiUtils";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const ProfileInformation = () => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = localStorage.getItem("user");
      let userId;
      if (userData) {
        userId = JSON.parse(userData).user_id;
        setUserId(userId);
      }
      console.log("userIddd:", userId);
      try {
        const response = await get<any>(`Users/users/${userId}`, {});
        if (response.type === "success") {
          setUserData(response.data);
          form.setFieldsValue({
            fullName: response.data.username,
            email: response.data.email,
            phoneNumber: response.data.phone_number,
            organizationName: response.data.organization_name,
            typeOfOrganization: response.data.typeid,
            country: response.data.countryid,
            emailNotifications: response.data.agree_to_terms,
            whatsappNotifications: response.data.opt_info,
          });
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await get<any>("countries/", {});
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries", error);
      }
    };

    const fetchOrganizationTypes = async () => {
      try {
        const response = await get<any>("organization_types/", {});
        setOrganizationTypes(response.data);
      } catch (error) {
        console.error("Error fetching organization types", error);
      }
    };

    fetchUserData();
    fetchCountries();
    fetchOrganizationTypes();
  }, [userId, form]);

  //   useEffect(() => {
  //     const fetchUserData = async () => {
  //       const userData = localStorage.getItem("user");

  //       //   const user = JSON.parse(localStorage.getItem("user"));
  //       if (userData) {
  //         const user = JSON.parse(userData);
  //       }

  //       try {
  //         const response = await get<any>(`Users/users/${userId}`);
  //         setUserData(response.data);
  //         form.setFieldsValue({
  //           fullName: response.data.username,
  //           email: response.data.email,
  //           phoneNumber: response.data.phone_number,
  //           organizationName: response.data.organization_name,
  //           typeOfOrganization: response.data.typeid,
  //           country: response.data.countryid,
  //           emailNotifications: response.data.agree_to_terms,
  //           whatsappNotifications: response.data.opt_info,
  //         });
  //       } catch (error) {
  //         console.error("Error fetching user data", error);
  //       }
  //     };

  //     fetchUserData();
  //   }, [userId, form]);

  const updateUserData = async (values: any) => {
    try {
      const response = await put<any>(`Users/users/${userId}`, values, {});
      if (response?.type === "success") {
        message.success("The data have been updated successfully!");
        navigate("/");
      }
    } catch (error) {
      console.error("Error updating user data", error);
    }
  };

  const onFinish = (values: any) => {
    console.log("Form values:", values);
    updateUserData({
      username: values.fullName,
      email: values.email,
      phone_number: values.phoneNumber,
      organization_name: values.organizationName,
      typeid: values.typeOfOrganization,
      countryid: values.country,
      agree_to_terms: values.emailNotifications,
      opt_info: values.whatsappNotifications,
    });
  };

  if (!userData) {
    return <div>Loading...</div>;
  }
  const onCancelhandle = () => {
    navigate("/workspaces");
  };
  const handleResetPassword = () => {
    navigate("/forgot-password");
  };
  return (
    <div style={{ padding: "2rem" }} className="profile-information-layoutt">
      <Content className="content-styles-profile-information">
        <div
          style={{
            position: "relative",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
          }}
        >
          <img
            src={ProfileInformationHeader}
            alt="AI Tool Header"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div className="translation-tool-content-profile">
          <div className="title-button-profile">
            <h2>Profile information</h2>
            <div className="reset-filters-button">
              <Button
                className="button-style-information-profile"
                onClick={handleResetPassword}
              >
                Reset your password
              </Button>
            </div>
          </div>
          <Form
            form={form}
            layout="vertical"
            style={{ marginTop: "1rem" }}
            onFinish={onFinish}
          >
            <div className="first-section-profile">
              <Form.Item
                label="Full name"
                name="fullName"
                className="width"
                style={{ width: "100%" }}
              >
                <Input placeholder="Enter your full name" />
              </Form.Item>
              <Form.Item
                label="Phone number"
                name="phoneNumber"
                style={{ width: "100%" }}
              >
                <Input
                  //   addonBefore="+39"
                  placeholder="Enter your phone number"
                />
              </Form.Item>
            </div>
            <div className="first-section-profile">
              <Form.Item label="Email" name="email" style={{ width: "100%" }}>
                <Input placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                name="email"
                style={{ width: "100%" }}
                className="dddd"
              >
                {/* <Input placeholder="Enter your email" /> */}
              </Form.Item>
            </div>
            <div className="title-button-profile-2">
              <h2>Company information</h2>
            </div>
            <div className="first-section-profile">
              <Form.Item
                label="Type of organization"
                name="typeOfOrganization"
                style={{ width: "100%" }}
              >
                <Select placeholder="Select an option">
                  {organizationTypes.map((type: any) => (
                    <Option key={type.id} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Organization's name"
                name="organizationName"
                style={{ width: "100%" }}
              >
                <Input placeholder="Enter your organization's name" />
              </Form.Item>
            </div>
            <div className="first-section-profile">
              <Form.Item
                label="Country"
                name="country"
                style={{ width: "100%" }}
              >
                <Select placeholder="Select an option">
                  {countries.map((country: any) => (
                    <Option key={country.id} value={country.id}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                // label="Country"
                name="country"
                style={{ width: "100%" }}
                className="dddd"
              >
                {/* <Select placeholder="Select an option">
                  {countries.map((country: any) => (
                    <Option key={country.id} value={country.id}>
                      {country.name}
                    </Option>
                  ))}
                </Select> */}
              </Form.Item>
            </div>
            {/* <Form.Item
              label="Notification preferences"
              name="emailNotifications"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Allow email notifications"
                unCheckedChildren="Allow email notifications"
              />
            </Form.Item>
            <Form.Item name="whatsappNotifications" valuePropName="checked">
              <Switch
                checkedChildren="WhatsApp notifications"
                unCheckedChildren="WhatsApp notifications"
                style={{ marginLeft: "1rem" }}
              />
            </Form.Item> */}

            <div className="footer">
              <Button className="cancelButton" onClick={onCancelhandle}>
                Cancel
              </Button>
              <Button htmlType="submit" className="save-changes">
                Save changes
              </Button>
            </div>
          </Form>
        </div>
      </Content>
    </div>
  );
};

export default ProfileInformation;

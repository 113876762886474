import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import icon from "../assets/img/kapinsightsLogo.png";
import { useDispatch } from "react-redux";
import { setUser } from "../hooks/userSlice";
import axios from "axios";
import { ToastContainer } from "../components/CustomToast/index";
import { Spin } from "antd";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    // If you have client_id and client_secret you can append them here
    // formData.append('client_id', 'your_client_id');
    // formData.append('client_secret', 'your_client_secret');

    try {
      const response = await axios.post(
        "https://aitools.kapinsights.com/Users/token",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log("responseFFFF:", response);
      if (response.status === 200) {
        const token = response.data.access_token;
        localStorage.setItem("token", token);
        localStorage.setItem("organizationId", response?.data.organization_id);
        localStorage.setItem("refreshToken", response?.data.refresh_token);
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("isAuthenticates", "true");

        if (rememberMe) {
          localStorage.setItem("rememberMe", "true");
          // localStorage.setItem("rememberMe_username", values.email);
          // localStorage.setItem("rememberMe_password", values.password);
        } else {
          localStorage.removeItem("rememberMe");

          // localStorage.removeItem("rememberMe_username");
          // localStorage.removeItem("rememberMe_password");
        }
        // Dispatch the setUser action with the user data

        dispatch(setUser(response.data.user));
        setTimeout(() => toast.success("You logged in successfully"), 0);

        // Navigate to the dashboard or home page
        setTimeout(() => {
          setLoading(false);
          if (
            response.data?.roles[0].role_id !== 1 &&
            response.data?.roles[0].role_id !== 2 &&
            response.data?.roles[0].role_id !== 7
          ) {
            navigate("/workspaces");
            return;
          }
          navigate("/workspaces");
        }, 1500);
      }
    } catch (error) {
      // If there's an error, you can show it to the users
      toast.error("Login failed. Please check your username and password.");
      setLoading(false);
    }
  };

  const signInGoogle = async () => {};
  // const handleRememberMe = (val: any) => {
  //   console.log("val:", val.target.checked);
  //   if (val.target.checked) {
  //     localStorage.setItem("rememberMe", "true");
  //     setRememberMe(true);
  //   } else {
  //     // localStorage.setItem("rememberMe", "false");
  //     setRememberMe(false);
  //     setUsername("");
  //     setPassword("");
  //   }
  // };

  return (
    <section className="sign-in">
      <div className="image-icon">
        <ToastContainer />
        {/* <img
          onClick={() => navigate("/")}
          src={icon}
          className="kapinsights-icon"
          alt="KAPinsights icon"
        /> */}
      </div>
      <div className="sign-in-form-container">
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form
            form={form}
            name="normal_login"
            className="sign-in-form"
            initialValues={{
              remember: rememberMe,
              username: username,
              password: password,
            }}
            onFinish={onFinish}
          >
            <h3 className="welcome-back">Welcome back!</h3>
            <p className="sign-in-text">Sign in</p>
            <Typography className="typography">Email</Typography>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                //   prefix={<YourEmailIcon />}
                placeholder="Type your email"
                onChange={(e) => setUsername(e.target.value)}
                className="emailInput"
              />
            </Form.Item>
            <Typography className="typography">Password</Typography>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                //   prefix={<YourPasswordIcon />}
                type="password"
                placeholder="Type your password"
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={rememberMe}
                  // onChange={() => setRememberMe(!rememberMe)}
                  onChange={(val) => setRememberMe(val.target.checked)}
                  style={{ color: "gray" }}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <a
                  className="forgot-password"
                  href="/forgot-password"
                  style={{ color: "#A072EB" }}
                >
                  Forgot password?
                </a>
              </Form.Item>
            </div>

            <Form.Item>
              <Button
                // type="primary"
                htmlType="submit"
                className="login-form-button-style"
              >
                Sign In
              </Button>
              {/* <Button
                onClick={signInGoogle}
                icon={<GoogleOutlined />}
                className="button-google"
              >
                Sign in with Google
              </Button> */}
            </Form.Item>
          </Form>
        )}
        <div>
          <p className="do-not-account">
            Don’t have an account? &#160;
            <a href="/signup" type="button" className="sign-upp">
              Sign up
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Login;

import React, { useState } from "react";
import { Form, Input, Button, Typography, Modal } from "antd";
import icon from "../assets/img/forgotPassword.png";
import wave from "../assets/img/waveSvgg.svg";
import { useNavigate } from "react-router-dom";
import "./ResetPassword.css";
import kapinsightsLogo from "../assets/img/kapinsightsLogoo.png";
import gotIt from "../assets/img/gotIt.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Changed from "../assets/img/changedPass.png";
import { Spin } from "antd";

const { Title, Paragraph } = Typography;

const ResetPassword: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    const { token, newPassword } = values;

    try {
      const response = await axios.post(
        `https://aitools.kapinsights.com/Users/reset-password/?token=${token}&new_password=${newPassword}`
      );

      if (response) {
        setTimeout(() => toast.success("Password successfully reset"), 0);
        setTimeout(() => {
          setLoading(false);
          setIsModalVisible(true);
        }, 4000);
      }

      // handle your response data here
      // console.log(response.data);
      // setIsModalVisible(true);
    } catch (error: any) {
      // handle your error here
      console.error("Error resetting password:", error);
      // If error.response is available, it means the request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        // console.error(error.response.headers);
      }
      setLoading(false);
    }
  };

  // Function to handle modal closure
  const handleOk = () => {
    setIsModalVisible(false);
    navigate("/");
  };

  return (
    <div className="reset-password-container">
      <div className="kapinsightsLogo">
        <img src={kapinsightsLogo} alt="kapinsights logo" />
      </div>
      <div className="container">
        <img src={icon} alt="reset password" className="resetPassImg" />
        <div className="reset-password-form-container">
          <Title level={2} className="title">
            Reset your password
          </Title>
          <p className="password-instructions">
            Your new password must:
            <ul>
              <li>Be at least 8 characters long</li>
              <li>Have one uppercase character</li>
              <li>Have one lowercase character</li>
              <li>Have one number</li>
              <li>Have one special character</li>
            </ul>
          </p>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form name="reset_password" form={form} onFinish={onFinish}>
              <Typography className="password">New password</Typography>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  // Additional validation rules can be added here
                ]}
              >
                <Input.Password placeholder="Type your password" />
              </Form.Item>
              {/* Token input field */}
              <Typography className="password">Token</Typography>
              <Form.Item
                name="token"
                rules={[
                  { required: true, message: "Please input your token!" },
                  // Additional validation rules can be added here
                ]}
              >
                <Input placeholder="Type your token" />
              </Form.Item>
              <Form.Item className="buttons">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="reset-password-form-button"
                >
                  Reset Password
                </Button>
                <Button
                  htmlType="button"
                  onClick={() => form.resetFields()}
                  className="cancel-form-button"
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
      <div>
        <img src={wave} alt="wave" className="wave" />
      </div>

      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Got it!
          </Button>,
        ]}
        style={{ width: "670px" }} // Ensuring it is set here
        wrapClassName="custom-modal"
      >
        <img src={Changed} alt="Email sent" className="modal-image" />
        <h2 className="email-title">Password changed successfully</h2>
        {/* <p className="email-paragraph">
          
        </p> */}
      </Modal>
    </div>
  );
};

export default ResetPassword;

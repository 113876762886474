import { createSlice } from "@reduxjs/toolkit";

const storedUser = localStorage.getItem("user");
// const storedDomainId = localStorage.getItem("domainId");
// const storedDomainName = localStorage.getItem("domainName");
const initialState = {
  user: storedUser ? JSON.parse(storedUser) : {},
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      // localStorage.setItem("user", JSON.stringify(action.payload));
    },
  },
});
export const { setUser } = userSlice.actions;
export default userSlice.reducer;

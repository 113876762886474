import React, { useEffect, useState } from "react";
import { Modal, Table, Button, message, Space } from "antd";
import "./index.css";
import { get, put } from "../../../utils/apiUtils";
import { ReactComponent as UnArchiveIcon } from "../../../assets/svg/unarchive.svg";

interface ArchivedProjectsModalProps {
  visible: boolean;
  onCancel: () => void;
  workspaceId: any;
  onUnarchive: any;
}

const ArchivedProjectsModal: React.FC<ArchivedProjectsModalProps> = ({
  visible,
  onCancel,
  workspaceId,
  onUnarchive,
}) => {
  const [loading, setLoading] = useState(false);
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectionType, setSelectionType] = useState<"checkbox">("checkbox");

  useEffect(() => {
    if (visible) {
      fetchArchivedProjects(currentPage, pageSize);
    }
  }, [visible, currentPage, pageSize]);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled Project", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const fetchArchivedProjects = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await get<any>(
        `projects/archived?workspace_id=${workspaceId}&skip=${
          (page - 1) * pageSize
        }&limit=${pageSize}`
      );
      if (response.type === "success") {
        setArchivedProjects(
          response.data.map((project: any) => ({
            key: project.id,
            projectname: project.name,
            manager: project.project_manager?.username || "No Manager",
            members:
              project.team_members
                ?.map((member: any) => member.username)
                .join(", ") || "No team members",
            country: project.country?.name || "No country",
            date: `${project.start_date || "N/A"} - ${
              project.end_date || "N/A"
            }`,
          }))
        );
        // setTotalProjects(response.totalCount);
      } else {
        console.error("Error fetching archived projects:", response);
      }
    } catch (error) {
      console.error("Failed to fetch archived projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectname",
      key: "projectname",
    },
    {
      title: "Project Manager",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Team Members",
      dataIndex: "members",
      key: "members",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          <UnArchiveIcon
            onClick={() => unarchiveProject(record.key)}
            style={{ color: "#A072EB" }}
          />
        </Space>
      ),
    },
  ];

  const unarchiveProject = async (id: any) => {
    try {
      let response = await put<any>(`projects/${id}/unarchive`, {});
      if (response.type === "success") {
        message.success("Project unarchived successfully");
        fetchArchivedProjects(currentPage, pageSize);

        //this is a callback function, pra e njofton parentin per me update-u listen kryesore
        if (onUnarchive) {
          onUnarchive();
        }
      }
    } catch (error: any) {
      message.error("Failed to archive the project: " + error.message);
    }
  };
  return (
    <Modal
      title="Archived Projects"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} className="go-back-button">
          Go back
        </Button>,
      ]}
      className="archived-project-modal"
      style={{ minWidth: "1046px" }}
    >
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={archivedProjects}
        rowKey="id"
        scroll={{ x: 700 }}
        loading={loading}
      />
    </Modal>
  );
};

export default ArchivedProjectsModal;

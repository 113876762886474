import { createSlice } from "@reduxjs/toolkit";

const storedUser = localStorage.getItem("user");
// const storedDomainId = localStorage.getItem("domainId");
// const storedDomainName = localStorage.getItem("domainName");
const initialState = {
  user: storedUser ? JSON.parse(storedUser) : {},
  //   domainId: storedDomainId ? JSON.parse(storedDomainId) : {},
  //   domainName: storedDomainName ? JSON.parse(storedDomainName) : {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      // localStorage.setItem("user", JSON.stringify(action.payload));
    },
    // setDomain: (state, action) => {
    //   state.domainId = action.payload;
    //   localStorage.setItem("domainId", JSON.stringify(action.payload));
    // },
    // setDomainName: (state, action) => {
    //   state.domainName = action.payload;
    //   localStorage.setItem("domainName", JSON.stringify(action.payload));
    // },
  },
});

export function getDomainId() {}

export const { setUser } = userSlice.actions;
export default userSlice.reducer;

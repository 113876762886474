import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Tabs,
  Typography,
  Upload,
  message,
  Spin,
  Table,
  Space,
  Divider,
  Radio,
  TableColumnsType,
  DatePicker,
  Form,
} from "antd";
import { toast } from "react-toastify";
// import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import { Modal } from "antd";
import HeaderAITool from "../../../../assets/img/header.png";
import "./index.css";
import ArchivedProjectsModal from "../ArchivedProject";
import HeaderOfList from "../../../assets/img/Banner.png";
import { ReactComponent as ArchiveIcon } from "../../../assets/svg/icon.svg";
import {
  PlusOutlined,
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownloadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { get, put } from "../../../utils/apiUtils";
import ProjectModal from "../ProjectModal";
import EditProjectModal from "../EditProject";
import { getUserRole } from "../../../hooks/getUserRoles";
import DeletedProject from "../DeletedProject";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

// interface DataType {
//   key: React.Key;
//   projectname: string;
//   manager: string;
//   //   linkedsurveys: number;
//   members: number;
//   languages: string[];
//   actions: JSX.Element;
// }

interface DataType {
  key: React.Key;
  projectname: string;
  manager: string;
  members: string[];
  languages: string[];
  actions: JSX.Element;
}

// const columns: TableColumnsType<DataType> = [
//   {
//     title: "History of translations",
//     dataIndex: "projectname",
//     // render: (text: string) => <a>{text}</a>,
//   },
//   {
//     title: "Type",
//     dataIndex: "type_name",
//   },
//   {
//     title: "Date",
//     dataIndex: "date",
//     render: (text) =>
//       text === "Invalid date" ? (
//         <span style={{ color: "red" }}>{text}</span>
//       ) : (
//         text
//       ),
//   },
//   {
//     title: "",
//     dataIndex: "actions",
//   },
// ];

const data: DataType[] = [
  {
    key: "1",
    projectname: "Project Alpha",
    manager: "John Doe",
    // linkedsurveys: 10,
    members: [],
    languages: ["English", "Spanish"],
    actions: <div>Actions here</div>, // This will be replaced by render in columns
  },
];
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record: DataType) => ({
    disabled: record.projectname === "Disabled User", // Column configuration not to be checked
    name: record.projectname,
  }),
};

const ProjectsList = () => {
  const userRole = getUserRole();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const navigate = useNavigate();
  const [selectionType, setSelectionType] = useState<"checkbox">("checkbox");
  const [searchText, setSearchText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<DataType[]>(data);
  const [typeFilter, setTypeFilter] = useState<string>("Type");
  const [dateFilter, setDateFilter] = useState<Dayjs | string>("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [translationList, setTranslationList] = useState([]);
  const [translationTypes, setTranslationTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedManager, setSelectedManager] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [modalMode, setModalMode] = useState("create");
  const [isArchivedModalVisible, setIsArchivedModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);

  useEffect(() => {
    if (workspaceId) {
      localStorage.setItem("currentWorkspaceId", workspaceId);
      fetchData();
    }
  }, [workspaceId]);
  const openModalForEdit = (projectId: any) => {
    setModalMode("edit");
    setCurrentProjectId(projectId);
    setIsModalVisible(true);
  };
  const showModalForCreate = () => {
    setModalMode("create");
    setCurrentProjectId(null);
    setIsModalVisible(true);
  };
  const viewProjectDetails = (projectId: any) => {
    setCurrentProjectId(projectId);
    setModalMode("view");
    setIsModalVisible(true);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Project Name",
      dataIndex: "projectname",
      key: "projectname",
      sorter: (a: DataType, b: DataType) =>
        a.projectname.localeCompare(b.projectname),
    },
    {
      title: "Project Manager",
      dataIndex: "manager",
      key: "manager",
      sorter: (a: DataType, b: DataType) => a.manager.localeCompare(b.manager),
    },
    {
      title: "Team Members",
      dataIndex: "members",
      key: "members",
      sorter: (a: DataType, b: DataType) => {
        // Convert members to strings safely
        const membersA = Array.isArray(a.members) ? a.members.join(", ") : "";
        const membersB = Array.isArray(b.members) ? b.members.join(", ") : "";
        return membersA.localeCompare(membersB);
      },
    },
    // {
    //   title: "Languages",
    //   dataIndex: "languages",
    //   key: "languages",
    //   render: (languages) => <span>{languages?.join(", ")}</span>,
    // },
    {
      //   title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined
            onClick={() => viewProjectDetails(record.key)}
            style={{ fill: "#A072EB", color: "#A072EB" }}
          />
          {(userRole === "admin" || userRole === "Project Manager") && (
            <>
              <EditOutlined
                onClick={() => openModalForEdit(record.key)}
                style={{ fill: "#A072EB", color: "#A072EB" }}
              />
              <ArchiveIcon
                onClick={() => archiveProject(record.key)}
                style={{ color: "#A072EB", width: "1em", height: "1em" }}
              />
              <DeleteOutlined
                onClick={() => deleteProject(record.key)}
                style={{ fill: "#A072EB", color: "#A072EB" }}
              />{" "}
            </>
          )}
          <ArrowRightOutlined
            style={{ fill: "#A072EB", color: "#A072EB" }}
            onClick={() => navigate(`/projects/${record.key}/details`)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://aitools.kapinsights.com/countries/"
        );
        console.log("countries response::", response);
        setCountries(response.data); // Assuming response data is the array of countries
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        toast.error("Failed to load countries data!");
      }
    };
    fetchCountries();
  }, []);
  useEffect(() => {
    const fetchProjectManager = async () => {
      try {
        const response = await get<any>(
          `users/project-managers?workspace_id=${workspaceId}`
        );
        if (response.type === "success") {
          setManagers(response.data);
        }
      } catch (error) {
        message.error("Failed to load managers data!");
      }
    };

    fetchProjectManager();
  }, []);
  const filterData = (): void => {
    const lowercasedSearchText = searchText.toLowerCase();
    const filtered = translationList.filter((item: any) => {
      const searchTextMatch = item.projectname
        .toLowerCase()
        .includes(lowercasedSearchText);
      const typeMatch = typeFilter === "Type" || item.type_name === typeFilter;
      const dateMatch =
        !dateFilter || dayjs(item.date).isSame(dayjs(dateFilter), "day");
      return searchTextMatch && typeMatch && dateMatch;
    });
    setFilteredData(filtered);
  };

  // const deleteProject = (id: any) => {
  //   Modal.confirm({
  //     title: "Are you sure you want to delete this translation?",
  //     content: (
  //       <div style={{ marginBottom: "1rem" }}>
  //         This action cannot be undone.
  //       </div>
  //     ),
  //     okText: <div style={{ width: "150px" }}>Yes</div>,
  //     okType: "danger",
  //     cancelText: <div style={{ width: "150px" }}>No</div>,
  //     onOk: async () => {
  //       try {
  //         setLoading(true); // Set loading to true while deleting

  //         // Make an API call to delete the translation
  //         const token = localStorage.getItem("token"); // Retrieve token
  //         await axios.delete(
  //           `https://aitools.kapinsights.com/translations/${id}`,
  //           {
  //             headers: {
  //               Accept: "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         message.success("Translation deleted successfully");

  //         // Refresh the list of translations
  //         await fetchTranslations();
  //       } catch (error: any) {
  //         message.error("Failed to delete translation: " + error.message);
  //       } finally {
  //         setLoading(false); // Ensure loading is false after operation
  //       }
  //     },
  //   });
  // };

  const deleteProject = (id: any) => {
    Modal.confirm({
      title: "Delete project",
      content: (
        <>
          <span style={{ textAlign: "center" }}>
            You're about to delete this Project. This action can’t be undone.{" "}
          </span>
          <br />
          <strong>Are you sure you want to continue? </strong>
        </>
      ),
      // "Deleting this project cannot be undone. This will remove all the project data.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "delete-modal",
      onOk: async () => {
        setLoading(true); // Show loading indication until the operation is complete
        try {
          const token = localStorage.getItem("token"); // Assuming you store token in localStorage
          await axios.delete(`https://aitools.kapinsights.com/projects/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          });
          message.success("Project deleted successfully");
          // Refresh the list after deletion
          fetchData();
        } catch (error: any) {
          message.error("Failed to delete the project: " + error.message);
        } finally {
          setLoading(false); // Stop loading indication
        }
      },
    });
  };
  const archiveProject = async (id: any) => {
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      let response = await put<any>(`projects/${id}/archive`, {});
      if (response.type === "success") {
        message.success("Project archived successfully");
        fetchData();
      }
    } catch (error: any) {
      message.error("Failed to archive the project: " + error.message);
    }
  };

  //   const deleteProject = async (id: any) => {
  //     try {
  //       console.log("id", id);
  //       // Confirm deletion with the user before proceeding
  //       if (
  //         !window.confirm("Are you sure you want to delete this translation?")
  //       ) {
  //         return;
  //       }

  //       setLoading(true); // Set loading to true while deleting

  //       // Make an API call to delete the translation
  //       const token = localStorage.getItem("token"); // Retrieve token
  //       await axios.delete(`https://aitools.kapinsights.com/project/${id}`, {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       message.success("Project deleted successfully");

  //       // Refresh the list of translations
  //       await fetchData();
  //     } catch (error: any) {
  //       message.error("Failed to delete translation: " + error.message);
  //     } finally {
  //       setLoading(false); // Ensure loading is false after operation
  //     }
  //   };

  // Utility function to validate date format
  const openArchivedProjectsModal = () => {
    setIsArchivedModalVisible(true);
  };

  const closeArchivedProjectsModal = () => {
    setIsArchivedModalVisible(false);
  };

  const openDeletedProjectsModal = () => {
    setIsDeletedModalVisible(true);
  };

  const closeDeletedProjectsModal = () => {
    setIsDeletedModalVisible(false);
  };

  //   const fetchTranslations = async () => {
  //     setLoading(true);
  //     const token = localStorage.getItem("token");
  //     let response = await get<any>("users/translations", {});
  //     if (response.type === "success") {
  //       const fetchedTranslations = response.data.map((item: any) => {
  //         // Validate the date format before adding to state
  //         const isValid = isValidDate(item.date);
  //         if (!isValid) {
  //           console.error(`Invalid date format for item with id ${item.id}`);
  //         }
  //         // setJsonName(item.type_name)
  //         console.log("item::", item);
  //         return {
  //           ...item,
  //           key: item.id,
  //           date: isValid
  //             ? dayjs(item.date).format("MM/DD/YYYY")
  //             : "Invalid date",
  //           actions: (
  //             <Space size="middle" className="actions">
  //               {item.type_name !== "excel" && (
  //                 <CopyOutlined onClick={() => copyTranslation(item.id)} />
  //               )}
  //               <DownloadOutlined
  //                 onClick={() =>
  //                   downloadTranslation(item.id, item.projectname, item.type_name)
  //                 }
  //               />
  //               <DeleteOutlined onClick={() => deleteProject(item.id)} />
  //             </Space>
  //           ),
  //         };
  //       });
  //       setTranslationList(fetchedTranslations);
  //       setFilteredData(fetchedTranslations); // Set filtered data here after fetching
  //       setLoading(false);
  //     }
  //   };
  const fetchData = async (searchTerm = "", managerId = "", countryId = "") => {
    setLoading(true);
    try {
      console.log("search term:", searchTerm);
      const queryParams = new URLSearchParams();
      if (searchTerm) queryParams.append("search", searchTerm);
      if (managerId) queryParams.append("manager_id", managerId);
      if (countryId) queryParams.append("country_id", countryId);

      // const workspaceId = "ae4236d1-672e-4d01-b506-8bb506bf8129"; // Replace with your actual workspace ID
      if (workspaceId) {
        queryParams.append("workspace_id", workspaceId);
      } else {
        console.error("Workspace ID is undefined");
        setLoading(false);
        return;
      }
      const response = await get<any>(
        `projects/?${queryParams.toString()}`,
        {}
      );
      console.log("responseee in projectss:", response);
      if (response.type === "success") {
        const projectsData = response.data.map((project: any) => ({
          key: project.id,
          projectname: project.name,
          manager: project.project_manager?.username || "No Manager",
          members:
            project.team_members?.map((m: any) => m.username).join(", ") ||
            "No team members",
          actions: (
            <Space size="middle">
              <EyeOutlined onClick={() => viewProjectDetails(project.id)} />
              {(userRole === "admin" || userRole === "Project Manager") && (
                <>
                  <EditOutlined onClick={() => openModalForEdit(project.id)} />
                  <DeleteOutlined onClick={() => deleteProject(project.id)} />
                </>
              )}
              <ArrowRightOutlined
                onClick={() => navigate(`/projects/${project.id}/details`)}
              />
            </Space>
          ),
        }));
        setProjects(projectsData);
        setFilteredData(projectsData);
        setLoading(false);
      } else {
        console.error("Error fetching data:", response);
        toast.error("Failed to load workspaces!");
      }
    } catch (error) {
      console.error("Failed to fetch workspaces:", error);
      toast.error("Failed to load workspaces!");
    }
  };

  //   useEffect(() => {
  //     fetchData();
  //   }, []);

  useEffect(() => {
    fetchData(search, selectedManager, selectedCountry);
  }, [search, selectedManager, selectedCountry]);

  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get<any>("users/translations", {});
  //       const projects = response.data.map((project: any) => ({
  //         key: project.id,
  //         projectname: project.name,
  //         manager: project.manager,
  //         linkedsurveys: project.surveys,
  //         members: project.members,
  //         languages: project.languages.map((lang: any) => lang.name),
  //       }));
  //       setFilteredData(projects);
  //     } catch (error) {
  //       console.error("Error fetching projects:", error);
  //       message.error("Failed to load projects.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  useEffect(() => {
    setTypeFilter("Type");
    setDateFilter("");
    setSearchText("");
  }, []);
  // Initial states should be non-restrictive
  const fetchTranslationTypes = () => {
    setLoading(true);
    axios
      .get("https://aitools.kapinsights.com/translation-types", {
        headers: {
          Accept: "application/json",
          // Include other headers as needed, e.g., Authorization if required
        },
      })
      .then((response) => {
        setTranslationTypes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Failed to fetch translation types: " + error.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchTranslationTypes();
  }, []); // The empty array ensures this effect runs only once after initial render

  const handleDateChange = (date: Dayjs | null, dateString: string) => {
    setSelectedDate(date);
    setDateFilter(date ? date.format("YYYY-MM-DD") : ""); // Adjust the format as necessary
    filterData();
  };
  const handleModalClose = () => {
    console.log("Closing modal...");

    setIsModalVisible(false);
  };

  const handleReset = () => {
    setTypeFilter("Type");
    setDateFilter("");
    setSearchText("");
    filterData();
  };

  useEffect(() => {
    filterData();
  }, [searchText, typeFilter, dateFilter, translationList]);

  const handleCreate = (values: any) => {
    console.log("Received values of form: ", values);
    setVisible(false);
    fetchData();
  };
  return (
    <>
      <div className="project-layout" style={{ marginLeft: 0 }}>
        <Content className="content-styles">
          <div className="header-image">
            <img
              src={HeaderOfList}
              alt="workspaces"
              style={{ width: "100%" }}
            />
          </div>
          <Form className="survey-form-workspaces" layout="vertical">
            <div className="card" style={{ borderRadius: "8px" }}>
              <div className="title-button">
                <h2>My projects</h2>
                {(userRole === "admin" || userRole === "Project Manager") && (
                  <div className="reset-filters-button">
                    <Button
                      className="deleted-button-style"
                      onClick={openArchivedProjectsModal}
                    >
                      <DeleteOutlined /> Archived project
                    </Button>

                    <Button
                      className="deleted-button-style"
                      onClick={openDeletedProjectsModal}
                    >
                      <DeleteOutlined /> Deleted project
                    </Button>

                    <Button
                      className="button-style"
                      onClick={showModalForCreate}
                    >
                      <PlusOutlined /> New project
                    </Button>
                  </div>
                )}
              </div>
              <p>Select a project to see the linked surveys</p>

              <div className="searchManagerCity-section">
                <div className="search-part">
                  <Form.Item
                    name="search"
                    label="Search"
                    className="title-filter-style"
                    style={{ marginBottom: "unset" }}
                  >
                    {/* <Search
                      placeholder="Search translations"
                      onSearch={(value) => fetchData(value, selectedCountry)}
                      style={{ width: "100%" }}
                      enterButton
                      onChange={(e) => setSearch(e.target.value)}
                    /> */}
                    <Search
                      // disabled
                      placeholder="Search projects"
                      onSearch={(value) =>
                        fetchData(value, selectedManager, selectedCountry)
                      }
                      style={{ width: "100%" }}
                      enterButton
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="mananger-part">
                  <Form.Item
                    name="manager"
                    label="Manager"
                    className="title-filter-style"
                    style={{ marginBottom: "unset" }}
                  >
                    <Select
                      showSearch
                      placeholder="Select a manager"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setSelectedManager(value);
                        fetchData(search, value, selectedCountry);
                      }}
                      allowClear
                    >
                      {managers.map((manager: any) => (
                        <Option key={manager.id} value={manager.id}>
                          {manager.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="country-part">
                  <Form.Item
                    name="country"
                    label="Country"
                    className="title-filter-style"
                    style={{ marginBottom: "unset" }}
                  >
                    {/* <Select
                      showSearch
                      placeholder="Select a country"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value: any) => {
                        setSelectedCountry(value);
                        fetchData(search, value);
                      }}
                    >
                      {countries.map((country: any) => (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      ))}
                    </Select> */}
                    <Select
                      // disabled
                      showSearch
                      placeholder="Select a country"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setSelectedCountry(value);
                        fetchData(search, selectedManager, value);
                      }}
                      allowClear
                    >
                      {countries.map((country: any) => (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                {/* <div>
                  <button className="reset-all-filters-style">
                    Reset all filters
                  </button>
                </div> */}
              </div>

              <div>
                <Table
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  className="dd"
                  columns={columns}
                  dataSource={filteredData} // Make sure to use filteredData here
                  scroll={{ x: 700 }}
                />
              </div>
            </div>
          </Form>
        </Content>

        <ProjectModal
          visible={isModalVisible}
          onCancel={handleModalClose}
          onCreate={handleCreate}
          projectId={currentProjectId}
          mode={modalMode}
        />

        <ArchivedProjectsModal
          visible={isArchivedModalVisible}
          onCancel={closeArchivedProjectsModal}
          workspaceId={workspaceId}
          onUnarchive={fetchData}
        />
        <DeletedProject
          visible={isDeletedModalVisible}
          onCancel={closeDeletedProjectsModal}
          workspaceId={workspaceId}
          restore={fetchData}
        />
      </div>
    </>
  );
};

export default ProjectsList;

import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Tabs,
  Select,
  message,
} from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import "./Register.css";
import icon from "../assets/img/kapinsightsLogo.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  showSuccessToast,
  ToastContainer,
} from "../components/CustomToast/index";
import { Spin } from "antd";
import axios from "axios";

const { TabPane } = Tabs; // Destructure TabPane from Tabs
const { Option } = Select;

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state
  const [modalLoading, setIsModalLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [sendMe, setSendMe] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [isCodeModalVisible, setIsCodeModalVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [countries, setCountries] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://aitools.kapinsights.com/countries/"
        );
        console.log("countries response::", response);
        setCountries(response.data); // Assuming response data is the array of countries
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        toast.error("Failed to load countries data!");
      }
    };

    const fetchOrganizationType = async () => {
      try {
        const response = await axios.get(
          "https://aitools.kapinsights.com/organization_types/"
        );
        console.log("orgtypes response::", response);
        setOrganizationTypes(response.data); // Assuming response data is the array of countries
      } catch (error) {
        console.error("Failed to fetch organization types:", error);
        toast.error("Failed to load organization types data!");
      }
    };
    fetchCountries();
    fetchOrganizationType();
  }, []);
  const showModal = (key: any) => {
    setActiveTabKey(key);
    setIsModalVisible(true);
  };
  const showCodeModal = () => {
    setIsCodeModalVisible(true);
  };
  const changeTab = (key: any) => {
    setActiveTabKey(key);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  // const handleCodeModalOk = async () => {
  //   setIsCodeModalVisible(false);
  //   setLoading(true); // Show loading indicator
  //   // try {
  //   axios
  //     .post(
  //       `https://aitools.kapinsights.com/Users/verify-email/?email=${userEmail}&code=${verificationCode}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Add other headers as required
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log("Email verified successfully!", response.data);
  //       toast.success("Email verified successfully, you can now log in.");
  //       navigate("/");
  //     })
  //     .catch((error) => {
  //       console.error("Verification error:", error.response || error);
  //       toast.error(
  //         "Failed to verify email. Please check the code and try again."
  //       );
  //     });
  //   // } catch (error) {
  //   //   toast.error(
  //   //     "Failed to verify email. Please check the code and try again."
  //   //   );
  //   //   console.error("Verification error:", error);
  //   // }
  //   setLoading(false); // Hide loading indicator
  // };

  const handleCodeModalOk = async () => {
    setIsCodeModalVisible(false);
    // setIsModalLoading(true); // Show loading indicator

    try {
      const response = await axios.post(
        `https://aitools.kapinsights.com/Users/verify-email/?email=${userEmail}&code=${verificationCode}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            // Add other headers as required
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data?.message);
        console.log("Email verified successfully!", response);
        // toast.success("Email verified successfully, you can now log in.");
        // setIsModalLoading(false);
        navigate("/");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error: any) {
      console.error("Verification error:", error.response || error);
      toast.error(
        "Failed to verify email. Please check the code and try again."
      );
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCodeModalCancel = () => {
    setIsCodeModalVisible(false);
  };
  const onFinish = async (values: any) => {
    console.log("Success:", values);
    setLoading(true);
    const payload = {
      username: values.fullname, // assuming fullname is the username
      email: values.email,
      password: values.password,
      password_confirmation: values.confirm,
      phone_number: values.phone,
      organization_name: values.organization_name,
      typeid: values.organizationTypes, // ensure this is the correct field from the form
      countryid: values.country, // ensure this is the correct field from the form
      agree_to_terms: rememberMe,
      opt_info: sendMe,
    };

    try {
      const response = await axios.post(
        "https://aitools.kapinsights.com/Users/register/",
        payload
      );
      console.log("Registration response:", response.data);
      // if (response.status === 200) {
      //   toast.success("Registration successful!");
      //   navigate("/"); // redirect to home or other page
      //   setLoading(false);
      // }
      if (response.status === 200) {
        message.success("Please check your email for verification code!");
        setUserEmail(response.data.email); // Assuming email is in the response data
        showCodeModal(); // Show code modal only on success
        setLoading(false);
      }
    } catch (error: any) {
      console.log("Registration error:", error);
      toast.error(error.response?.data?.message || "Registration failed!");
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const signInGoogle = async () => {
    // event.preventDefault();
    // if (env.NODE_ENV === "production") {
    //   window.location.href = `${env.BACK_URL_PRODUCTION}/login`;
    // } else if (env.NODE_ENV === "development") {
    //   window.location.href = `${env.BACK_URL_DEVELOPMENT}/login`;
    // } else if (env.NODE_ENV === "developmentlocal") {
    //   window.location.href = `${env.BACK_URL_DEVELOPMENT_LOCAL}/login`;
    // } else {
    //   window.location.href = `${env.BACK_URL}/login`;
    // }
  };
  return (
    <section className="sign-up">
      <div className="image-icon-register">
        <ToastContainer /> {/* Place this at the top level of your component */}
        {/* <img
          onClick={() => navigate("/")}
          src={icon}
          className="kapinsights-icon"
          alt="KAPinsights icon"
        /> */}
      </div>
      <div className="sign-up-form-container">
        {/* {loading ? (
        ) : ( */}
        <Form
          name="register"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          className="sign-up-form"
        >
          <div>
            <p className="have-account">
              <span
                style={{
                  color: "#A072EB",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                {" "}
                Already have an account?{" "}
              </span>
              <a
                href="/"
                style={{
                  color: "#4A1C96",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                Log in here
              </a>
            </p>
          </div>
          <h3 className="welcome-back-register">Create an account</h3>

          <label className="label-style">Login Details</label>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input placeholder="Type your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
          >
            <Input.Password placeholder="Type your password" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Type your password again" />
          </Form.Item>
          <label className="label-style">User information</label>
          <Form.Item
            label="Full name"
            name="fullname"
            rules={[
              { required: true, message: "Please input your full name!" },
            ]}
          >
            <Input placeholder="Type your full name" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder="Enter your phone number"
            />
          </Form.Item>
          <label className="label-style">Organization details</label>

          <Form.Item
            label="Organization's name"
            name="organization_name"
            rules={[
              {
                required: true,
                message: "Please input your organization's name!",
              },
            ]}
          >
            <Input placeholder="Enter organization's name" />
          </Form.Item>

          <Form.Item
            label="Organization types"
            name="organizationTypes"
            rules={[
              {
                required: true,
                message: "Please select your organization type!",
              },
            ]}
          >
            <Select placeholder="Select your organization type">
              {organizationTypes.map((orgType: any) => (
                <Option key={orgType.id} value={orgType.code}>
                  {orgType.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please select your country!" }]}
          >
            <Select
              showSearch
              placeholder="Select your country"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map((country: any) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="register-form-button"
            >
              Sign up
            </Button>
            <Button
              icon={<GoogleOutlined />}
              className="register-form-button-google"
            >
              Sign up with Google
            </Button>
          </Form.Item> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item
              name="remember"
              valuePropName="unchecked"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "2px",
              }}
            >
              <Checkbox
                checked={rememberMe}
                onChange={(val) => setRememberMe(val.target.checked)}
                style={{ color: "gray" }}
              >
                I have read and agreed with the{" "}
                {/* <a
                    style={{ color: "#a072eb" }}
                    href="https://www.kapinsights.com/terms-of-service"
                  >
                    Terms of Service,
                  </a>{" "} */}
                <a onClick={() => showModal("1")} style={{ color: "#a072eb" }}>
                  Terms of Service,{" "}
                </a>
                <a onClick={() => showModal("2")} style={{ color: "#a072eb" }}>
                  Privacy Policy,{"  "}
                </a>
                <a onClick={() => showModal("3")} style={{ color: "#a072eb" }}>
                  Cookie Policy.
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="optInfo"
              valuePropName="unchecked"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                checked={sendMe}
                onChange={(val) => setSendMe(val.target.checked)}
                style={{ color: "gray" }}
              >
                Send me information about products, services, deals or
                recommendations by email (optional).
              </Checkbox>
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              // type="primary"
              htmlType="submit"
              className="login-form-button-continue"
              style={{
                minHeight: "40px",
                backgroundColor: "#4A1C96",
                color: "white",
                width: "140px",
              }}
            >
              Continue{" "}
              {loading && <Spin size="small" style={{ color: "white" }} />}
            </Button>
            {/* <Button
              onClick={signInGoogle}
              icon={<GoogleOutlined />}
              className="button-google"
            >
              Sign up with Google
            </Button> */}
          </Form.Item>
        </Form>
        {/* )} */}
        <Modal
          title=""
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            // <Button key="back" onClick={handleCancel}>
            //   Return
            // </Button>,
            <Button
              key="submit"
              // type="primary"
              onClick={handleOk}
              className="gotIt-button"
              style={{
                height: "44px",
                width: "74px",
                backgroundColor: "#4A1C96",
                color: "white",
              }}
            >
              Got it
            </Button>,
          ]}
          style={{ width: "736px" }} // Ensuring it is set here
          wrapClassName="terms-of-service-modal"
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={activeTabKey}
            onChange={changeTab}
          >
            <TabPane tab="Terms of Service" key="1">
              <div className="scrollable-content">
                <h2 className="firstHeading">Terms of service</h2>

                <p className="paragraphhhh">
                  These Terms of Service were last updated on March 4, 2024.{" "}
                  <br></br> <br></br>
                  These Terms of Service govern your use of our websites located
                  at
                  <a style={{ color: "#A072EB" }} href="https://www.i-aps.com">
                    {" "}
                    https://www.i-aps.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kaptechgroup.com"
                  >
                    {" "}
                    https://kaptechgroup.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://www.kapsurvey.com"
                  >
                    {" "}
                    https://www.kapsurvey.com
                  </a>
                  ,
                  <a style={{ color: "#A072EB" }} href="https://www.kaporg.com">
                    {" "}
                    https://www.kaporg.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kapinsights.com"
                  >
                    {" "}
                    https://kapinsights.com
                  </a>
                  , and
                  <a style={{ color: "#A072EB" }} href="https://kapcourse.com">
                    {" "}
                    https://kapcourse.com
                  </a>
                  , as well as any related services provided by International
                  Advisory, Products and Systems Ltd.
                  <br></br> <br></br>
                  By accessing our websites you agree to abide by these Terms of
                  Service and to comply with all applicable laws and
                  regulations. If you do not agree with these Terms of Service,
                  you are prohibited from using or accessing those websites or
                  using any other services provided by International Advisory,
                  Products and Systems Ltd.
                  <br />
                  <br />
                  We, International Advisory, Products and Systems Ltd, reserve
                  the right to review and amend any of these Terms of Service at
                  our sole discretion. Upon doing so, we will update this page.
                  Any changes to these Terms of Service will take effect
                  immediately from the date of publication.
                </p>
                <h2 className="heading">Overview of Services</h2>
                <p className="paragraphhhh">
                  International Advisory, Products and Systems Ltd. (i-APS),
                  together with its affiliated entities, including but not
                  limited to KAPsurvey, KAPorg, KAPinsights, and KAPcourse
                  (hereinafter collectively referred to as “KAPtech Group”),
                  provides technical advisory services, innovative technological
                  solutions, and comprehensive data collection and analysis
                  tools. The offerings of KAPtech Group are aimed at supporting
                  the execution and management of humanitarian and development
                  projects. These services utilize cutting-edge technology and
                  capitalize on extensive local expertise to assist partner
                  organizations and communities in effectively responding to and
                  overcoming challenges in humanitarian aid and development
                  sectors. <br />
                  <br />
                  By using the services provided by KAPtech Group, including
                  accessing our websites, you agree to comply with and be bound
                  by the terms and conditions set forth in this agreement. The
                  services offered are subject to the terms outlined herein,
                  along with any additional guidelines or rules applicable to
                  specific services or features of the services.
                </p>
                <h2 className="heading">Limitations of Use</h2>
                <div className="paragraphhhh">
                  By using our websites, you warrant on behalf of yourself, your
                  users, and other parties you represent that you will not:{" "}
                  <br />
                  <br />
                  <ul>
                    <li>
                      modify, copy, prepare derivative works of, decompile, or
                      reverse engineer any materials and software contained on
                      those websites;
                    </li>
                    <li>
                      remove any copyright or other proprietary notations from
                      any materials and software on those websites;
                    </li>
                    <li>
                      transfer the materials to another person or ’mirror’ the
                      materials on any other server;
                    </li>
                    <li>
                      knowingly or negligently use those websites or any of
                      their associated services in a way that abuses or disrupts
                      our networks or any other service International Advisory,
                      Products and Systems Ltd provides;
                    </li>
                    <li>
                      use those websites or their associated services to
                      transmit or publish any harassing, indecent, obscene,
                      fraudulent, or unlawful material;
                    </li>
                    <li>
                      use those websites or their associated services in
                      violation of any applicable laws or regulations;
                    </li>
                    <li>
                      use those websites in conjunction with sending
                      unauthorized advertising or spam;
                    </li>
                    <li>
                      harvest, collect, or gather user data without the user’s
                      consent; or
                    </li>
                    <li>
                      use those websites or their associated services in such a
                      way that may infringe the privacy, intellectual property
                      rights, or other rights of third parties.
                    </li>
                  </ul>
                </div>
                <h2 className="heading">Intellectual Property</h2>
                <p className="paragraphhhh">
                  The intellectual property in the materials contained in those
                  websites are owned by or licensed to International Advisory,
                  Products and Systems Ltd and are protected by applicable
                  copyright and trademark law. We grant our users permission to
                  download one copy of the materials for personal,
                  non-commercial transitory use.
                  <br />
                  <br />
                  This constitutes the grant of a license, not a transfer of
                  title. This license shall automatically terminate if you
                  violate any of these restrictions or the Terms of Service, and
                  may be terminated by International Advisory, Products and
                  Systems Ltd at any time.
                </p>
                <h2 className="heading">User-Generated Content</h2>
                <p className="paragraphhhh">
                  You retain your intellectual property ownership rights over
                  the content you submit to us for publication on our websites.
                  We will never claim ownership of your content, but we do
                  require a license from you in order to use it.
                  <br />
                  <br />
                  When you use our websites or their associated services to
                  post, upload, share, or otherwise transmit content covered by
                  intellectual property rights, you grant to us a non-exclusive,
                  royalty-free, transferable, sub-licensable, worldwide license
                  to use, distribute, modify, run, copy, publicly display,
                  translate, or otherwise create derivative works of your
                  content in a manner that is consistent with your privacy
                  preferences and our Privacy Policy.
                  <br />
                  <br />
                  The license you grant us can be terminated at any time by
                  deleting your content or account. However, to the extent that
                  we (or our partners) have used your content in connection with
                  commercial or sponsored content, the license will continue
                  until the relevant commercial or post has been discontinued by
                  us.
                  <br />
                  <br />
                  You give us permission to use your username and other
                  identifying information associated with your account in a
                  manner that is consistent with your privacy preferences and
                  our Privacy Policy.
                </p>
                <h2 className="heading">Registration and Payments</h2>
                <p className="paragraphhhh">
                  In order to use any of our services, you need to create an
                  account. Any information provided needs to be accurate and
                  updated if necessary. Customers are liable for maintaining
                  their accounts and are fully responsible for all activities
                  occurring under their accounts. Should anybody gain
                  unauthorized access to your account, you need to notify us
                  immediately. International Advisory, Products and Systems Ltd
                  is not liable for any loss or damage arising from failure to
                  maintain the confidentiality of your account.
                  <br />
                  <br />
                  When creating your account, you are required to provide your
                  financial information relevant to the chosen payment method.
                  In some cases, you may be requested to provide your credit
                  card details. Please note that any payments made on our
                  platform will be processed by PayPal, Stripe, Square, Google
                  Payments, or Apple Pay. As such, we will not be responsible
                  for processing or retaining any of your financial information.
                  PayPal, Stripe, Square, Google Payments, or Apple Pay will
                  handle all of your financial information per their respective
                  Terms of Service, and you agree to comply with such Terms of
                  Service.
                </p>
                <h2 className="heading">
                  Disclaimer and Limitations of Liability
                </h2>
                <p className="paragraphhhh">
                  The websites and materials provided by International Advisory,
                  Products and Systems Ltd., including but not limited to
                  <a style={{ color: "#A072EB" }} href="https://www.i-aps.com">
                    {" "}
                    https://www.i-aps.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kaptechgroup.com"
                  >
                    {" "}
                    https://kaptechgroup.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://www.kapsurvey.com"
                  >
                    {" "}
                    https://www.kapsurvey.com
                  </a>
                  ,
                  <a style={{ color: "#A072EB" }} href="https://www.kaporg.com">
                    {" "}
                    https://www.kaporg.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="http://www.kapinsights.com"
                  >
                    {" "}
                    http://www.kapinsights.com
                  </a>
                  , and
                  <a style={{ color: "#A072EB" }} href="https://kapcourse.com">
                    {" "}
                    https://kapcourse.com
                  </a>
                  , are made available to users on an ’as is’ basis.
                  International Advisory, Products and Systems Ltd disclaims all
                  warranties, express or implied, to the extent permitted by
                  law. This includes but is not limited to warranties of
                  merchantability, fitness for a particular purpose, or
                  non-infringement of intellectual property rights. Furthermore,
                  no advice or information, whether oral or written, obtained by
                  you from International Advisory, Products and Systems Ltd or
                  through the services shall create any warranty not expressly
                  stated in these terms.
                  <br />
                  <br />
                  International Advisory, Products and Systems Ltd and its
                  suppliers shall not be liable for any direct, indirect,
                  incidental, special, consequential, or exemplary damages,
                  including but not limited to damages for loss of profits,
                  goodwill, use, data, or other intangible losses (even if
                  International Advisory, Products and Systems Ltd has been
                  advised of the possibility of such damages), resulting from
                  the use or the inability to use the services or any other
                  matter relating to the services. This limitation of liability
                  applies whether the alleged liability is based on contract,
                  tort, negligence, strict liability, or any other basis, even
                  if International Advisory, Products and Systems Ltd has been
                  advised of the possibility of such damage.
                  <br />
                  <br />
                  Given the nature of the services provided by International
                  Advisory, Products and Systems Ltd, especially in the context
                  of humanitarian and development sectors, users acknowledge the
                  inherent risks involved in data collection and the use of
                  technology-driven solutions. Users are solely responsible for
                  ensuring their compliance with all applicable laws and
                  regulations, including but not limited to data protection and
                  privacy laws. The responsibility for obtaining the necessary
                  consents and permissions for data collection, particularly
                  when involving minors or sensitive data, rests with the user.
                  <br />
                  <br />
                  The liability of International Advisory, Products and Systems
                  Ltd and its affiliates, directors, officers, employees,
                  agents, suppliers, and licensors is limited to the maximum
                  extent permitted by law. If you are dissatisfied with any
                  portion of the services, or with any of these terms, your sole
                  and exclusive remedy is to discontinue using the services.
                  Some jurisdictions do not allow the exclusion of certain
                  warranties or the limitation or exclusion of liability for
                  incidental or consequential damages, so some of the above
                  limitations may not apply to you.
                </p>
                <h2 className="heading">Accuracy of Materials</h2>
                <p className="paragraphhhh">
                  The materials appearing on our websites are not comprehensive
                  and are for general information purposes only. International
                  Advisory, Products and Systems Ltd does not warrant or make
                  any representations concerning the accuracy, likely results,
                  or reliability of the use of the materials on those websites,
                  or otherwise relating to such materials or on any resources
                  linked to those websites. You should not rely solely on the
                  said materials to make decisions, we advise you to consult
                  other, more complete sources of information. You are solely
                  responsible for the strict reliance on the materials posted on
                  our websites. We have the right to change the information on
                  our websites without prior notice and users are responsible to
                  monitor said changes.
                </p>
                <h2 className="heading">Additional Tools</h2>
                <p className="paragraphhhh">
                  Please note that our websites may include third-party tools
                  which are outside of our control. The access to said tools is
                  provided on an ’as is’ basis with no warranties, expressed or
                  implied, and without any endorsement. We are not liable for
                  the use of the third-party tools provided on our websites. You
                  are solely responsible for any risks associated with using
                  said tools, we recommend reading the relevant third-party
                  provider’s Terms of Service. International Advisory, Products
                  and Systems Ltd has the right to offer new third-party tools
                  in the future.
                </p>
                <h2 className="heading">Links</h2>
                <p className="paragraphhhh">
                  International Advisory, Products and Systems Ltd has not
                  reviewed all of the sites linked to its websites and is not
                  responsible for the contents of any such linked site. The
                  inclusion of any link does not imply endorsement, approval or
                  control by International Advisory, Products and Systems Ltd of
                  the site. Use of any such linked site is at your own risk, and
                  we strongly advise you make your own investigations with
                  respect to the suitability of those sites.
                </p>
                <h2 className="heading">Inaccuracies and Mistakes</h2>
                <p className="paragraphhhh">
                  Please note that any content available on our websites may
                  contain typographical errors, inaccuracies, or mistakes
                  related to the service’s description, pricing, promotion, and
                  availability. We have the right to cancel or update any
                  existing services should any information provided by us be
                  proven inaccurate. Said information can be amended at any time
                  without prior notice. Please note that we are not obligated to
                  clarify any of the information related to our websites and
                  services, including pricing information, except as required by
                  law.
                </p>
                <h2 className="heading">Indemnification</h2>
                <p className="paragraphhhh">
                  You agree to defend, indemnify, and hold us harmless,
                  including our subsidiaries, affiliates, and all of our
                  respective officers, agents, partners, and employees, from and
                  against any loss, damage, liability, claim, or demand,
                  including reasonable attorneys’ fees and expenses, made by any
                  third party due to or arising out of: (1) your Contributions;
                  (2) use of the websites; (3) breach of these Terms of Service;
                  (4) any breach of your representations and warranties set
                  forth in these Terms of Service; (5) your violation of the
                  rights of a third party, including but not limited to
                  intellectual property rights; or (6) any overt harmful act
                  toward any other user of the websites with whom you connected
                  via the websites.
                  <br />
                  <br />
                  Notwithstanding the foregoing, we reserve the right, at your
                  expense, to assume the exclusive defense and control of any
                  matter for which you are required to indemnify us, and you
                  agree to cooperate, at your expense, with our defense of such
                  claims. We will use reasonable efforts to notify you of any
                  such claim, action, or proceeding which is subject to this
                  indemnification upon becoming aware of it.
                </p>
                <h2 className="heading">Right to Terminate</h2>
                <p className="paragraphhhh">
                  We may suspend or terminate your right to use our websites and
                  terminate these Terms of Service immediately upon written
                  notice to you for any breach of these Terms of Service.
                </p>
                <h2 className="heading">Severance</h2>
                <p className="paragraphhhh">
                  Any term of these Terms of Service which is wholly or
                  partially void or unenforceable is severed to the extent that
                  it is void or unenforceable. The validity of the remainder of
                  these Terms of Service is not affected.
                </p>
                <h2 className="heading">Entire Agreement</h2>
                <p className="paragraphhhh">
                  These Terms of Service and any policies or operating rules
                  posted by us on the websites or with respect to the websites
                  constitute the entire agreement and understanding between you
                  and us. Our failure to exercise or enforce any right or
                  provision of these Terms of Service shall not operate as a
                  waiver of such right or provision. These Terms of Service
                  operate to the fullest extent permissible by law. We may
                  assign any or all of our rights and obligations to others at
                  any time. We shall not be responsible or liable for any loss,
                  damage, delay, or failure to act caused by any cause beyond
                  our reasonable control.
                  <br />
                  <br />
                  If any provision or part of a provision of these Terms of
                  Service is determined to be unlawful, void, or unenforceable,
                  that provision or part of the provision is deemed severable
                  from these Terms of Service and does not affect the validity
                  and enforceability of any remaining provisions. There is no
                  joint venture, partnership, employment, or agency relationship
                  created between you and us as a result of these Terms of
                  Service or use of the websites. You agree that these Terms of
                  Service will not be construed against us by virtue of having
                  drafted them. You hereby waive any and all defenses you may
                  have based on the electronic form of these Terms of Service
                  and the lack of signing by the parties hereto to execute these
                  Terms of Service.
                </p>
                <h2 className="heading">Governing Law</h2>
                <p className="paragraphhhh">
                  These Terms of Service are governed by and construed in
                  accordance with the laws of Virginia. You irrevocably submit
                  to the exclusive jurisdiction of the cour ts in that State or
                  location.
                </p>
              </div>{" "}
            </TabPane>
            <TabPane tab="Privacy Policy" key="2">
              <div className="scrollable-content">
                <h2 className="firstHeading">Privacy policy</h2>
                <p className="paragraphhhh">
                  These Terms of Service were last updated on March 4, 2024.{" "}
                  <br></br> <br></br>
                  Your privacy is important to us. It is International Advisory,
                  Products and Systems Ltd’s policy to respect your privacy and
                  comply with any applicable law and regulation regarding any
                  personal information we may collect about you, including
                  across our websites, 
                  <a style={{ color: "#A072EB" }} href="https://www.i-aps.com">
                    {" "}
                    https://www.i-aps.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kaptechgroup.com"
                  >
                    {" "}
                    https://kaptechgroup.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://www.kapsurvey.com"
                  >
                    {" "}
                    https://www.kapsurvey.com
                  </a>
                  ,
                  <a style={{ color: "#A072EB" }} href="https://www.kaporg.com">
                    {" "}
                    https://www.kaporg.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kapinsights.com"
                  >
                    {" "}
                    https://kapinsights.com
                  </a>
                  , and
                  <a style={{ color: "#A072EB" }} href="https://kapcourse.com">
                    {" "}
                    https://kapcourse.com
                  </a>
                  , and other sites we own and operate.
                  <br></br> <br></br>
                  Personal information is any information about you which can be
                  used to identify you. This includes information about you as a
                  person (such as name, address, and date of birth), your
                  devices, payment details, and even information about how you
                  use a website or online service.
                  <br />
                  <br />
                  In the event our sites contain links to third-party sites and
                  services, please be aware that those sites and services have
                  their own privacy policies. After following a link to any
                  third-party content, you should read their posted privacy
                  policy information about how they collect and use personal
                  information. This Privacy Policy does not apply to any of your
                  activities after you leave one of our sites.
                </p>
                <h2 className="heading">Information We Collect</h2>
                <p className="paragraphhhh">
                  Information we collect falls into one of two categories:
                  “voluntarily provided” information and “automatically
                  collected” information.
                  <br />
                  <br />
                  “Voluntarily provided” information refers to any information
                  you knowingly and actively provide us when using or
                  participating in any of our services and promotions.
                  <br />
                  <br />
                  “Automatically collected” information refers to any
                  information automatically sent by your devices in the course
                  of accessing our products and services.{" "}
                </p>
                <h2 className="heading">Log Data</h2>
                <p className="paragraphhhh">
                  When you visit one of our websites, our servers may
                  automatically log the standard data provided by your web
                  browser. It may include your device’s Internet Protocol (IP)
                  address, your browser type and version, the pages you visit,
                  the time and date of your visit, the time spent on each page,
                  and other details about your visit.
                  <br />
                  <br />
                  Additionally, if you encounter certain errors while using our
                  sites, we may automatically collect data about the error and
                  the circumstances surrounding its occurrence. This data may
                  include technical details about your device, what you were
                  trying to do when the error happened, and other technical
                  information relating to the problem. You may or may not
                  receive notice of such errors, even in the moment they occur,
                  that they have occurred, or what the nature of the error is.
                  <br />
                  <br />
                  Please be aware that while this information may not be
                  personally identifying by itself, it may be possible to
                  combine it with other data to personally identify individual
                  persons.
                </p>
                <h2 className="heading">Device Data</h2>
                <div className="paragraphhhh">
                  When you visit our websites or interact with our services, we
                  may automatically collect data about your device, such as:
                  <br />
                  <br />
                  <ul>
                    <li>Device type</li>
                    <li>Operating system</li>
                    <li>Unique device identifiers</li>
                    <li>Geo-location data</li>
                  </ul>{" "}
                  Data we collect can depend on the individual settings of your
                  device and software. We recommend checking the policies of
                  your device manufacturer or software provider to learn what
                  information they make available to us.
                </div>
                <h2 className="heading">Personal information</h2>
                <div className="paragraphhhh">
                  We may ask for personal information — for example, when you
                  subscribe to our newsletter or when you contact us — which may
                  include one or more of the following:
                  <br />
                  <br />
                  <ul>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Social media profiles</li>
                    <li>Date of birth</li>
                    <li>Phone/mobile number</li>
                    <li>Home/mailing address</li>
                    <li>Work-related information</li>
                    <li>Company name</li>
                    <li>Title</li>
                  </ul>
                </div>
                <h2 className="heading">Sensitive Information</h2>
                <div className="paragraphhhh">
                  “Sensitive information” or “special categories of data” is a
                  subset of personal information that is given a higher level of
                  protection. Examples of sensitive information include
                  information relating to your racial or ethnic origin,
                  political opinions, religion, trade union or other
                  professional associations or memberships, philosophical
                  beliefs, sexual orientation, sexual practices or sex life,
                  criminal records, health information, or biometric
                  information.
                  <br />
                  <br />
                  The types of sensitive information that we may collect about
                  you include:
                  <br />
                  <br />
                  <ul>
                    <li>Gender</li>
                  </ul>
                  We will not collect sensitive information about you without
                  first obtaining your consent, and we will only use or disclose
                  your sensitive information as permitted, required, or
                  authorized by law.
                </div>
                <h2 className="heading">User-Generated Content</h2>
                <p className="paragraphhhh">
                  We consider “user-generated content” to be materials (text,
                  image and/or video content) voluntarily supplied to us by our
                  users for the purpose of publication, processing, or usage on
                  our platform. All user-generated content is associated with
                  the account or email address used to submit the materials.
                  <br />
                  <br />
                  Please be aware that any content you submit for the purpose of
                  publication will be public after posting (and subsequent
                  review or vetting process). Once published, it may be
                  accessible to third parties not covered under this privacy
                  policy.
                </p>
                <h2 className="heading">Transaction Data</h2>
                <p className="paragraphhhh">
                  Transaction data refers to data that accumulates over the
                  normal course of operation on our platform. This may include
                  transaction records, stored files, user profiles, analytics
                  data and other metrics, as well as other types of information,
                  created or generated, as users interact with our services.
                </p>
                <h2 className="heading">
                  Legitimate Reasons for Processing Your Personal Information
                </h2>
                <p className="paragraphhhh">
                  We only collect and use your personal information when we have
                  a legitimate reason for doing so. In which instance, we only
                  collect personal information that is reasonably necessary to
                  provide our services to you.
                </p>
                <h2 className="heading">Collection and Use of Information</h2>
                <div className="paragraphhhh">
                  We may collect personal information from you when you do any
                  of the following on our websites:
                  <br />
                  <br />
                  <ul>
                    <li>Register for an account</li>
                    <li>Purchase a subscription</li>
                    <li>
                      Sign up to receive updates from us via email or social
                      media channels
                    </li>
                    <li>
                      Use a mobile device or web browser to access our content
                    </li>
                    <li>
                      Contact us via email, social media, or on any similar
                      technologies
                    </li>
                    <li>When you mention us on social media</li>
                  </ul>
                  We may collect, hold, use, and disclose information for the
                  following purposes, and personal information will not be
                  further processed in a manner that is incompatible with these
                  purposes:
                  <br /> <br />
                  <ul>
                    <li>
                      to provide you with our platform’s core features and
                      services
                    </li>
                    <li>
                      to enable you to customize or personalize your experience
                      of our websites
                    </li>
                    <li>to deliver products and/or services to you</li>
                    <li>to contact and communicate with you</li>
                    <li>
                      Contact us via email, social media, or on any similar
                      technologies
                    </li>
                    <li>
                      for analytics, market research, and business development,
                      including to operate and improve our websites, associated
                      applications, and associated social media platforms
                    </li>
                    <li>
                      for advertising and marketing, including to send you
                      promotional information about our products and services
                      and information about third parties that we consider may
                      be of interest to you
                    </li>
                    <li>
                      to enable you to access and use our websites, associated
                      applications, and associated social media platforms
                    </li>
                    <li>
                      for internal record keeping and administrative purposes
                    </li>
                    <li>
                      to comply with our legal obligations and resolve any
                      disputes that we may have
                    </li>
                    <li>
                      to attribute any content (e.g. posts and comments) you
                      submit that we publish on our websites
                    </li>
                    <li>
                      for security and fraud prevention, and to ensure that our
                      sites and apps are safe, secure, and used in line with our
                      terms of use
                    </li>
                    <li>
                      for technical assessment, including to operate and improve
                      our app, associated applications, and associated social
                      media platforms
                    </li>
                  </ul>
                  We may combine voluntarily provided and automatically
                  collected personal information with general information or
                  research data we receive from other trusted sources. For
                  example, If you provide us with your location, we may combine
                  this with general information about currency and language to
                  provide you with an enhanced experience of our sites and
                  service.
                </div>
                <h2 className="heading">
                  Security of Your Personal Information
                </h2>
                <p className="paragraphhhh">
                  When we collect and process personal information, and while we
                  retain this information, we will protect it within
                  commercially acceptable means to prevent loss and theft, as
                  well as unauthorized access, disclosure, copying, use or
                  modification. <br />
                  <br />
                  Although we will do our best to protect the personal
                  information you provide to us, we advise that no method of
                  electronic transmission or storage is 100% secure and no one
                  can guarantee absolute data security.
                  <br />
                  <br />
                  You are responsible for selecting any password and its overall
                  security strength, ensuring the security of your own
                  information within the bounds of our services. For example,
                  ensuring any passwords associated with accessing your personal
                  information and accounts are secure and confidential.
                </p>
                <h2 className="heading">
                  How Long We Keep Your Personal Information
                </h2>
                <p className="paragraphhhh">
                  We keep your personal information only for as long as we need
                  to. This time period may depend on what we are using your
                  information for, in accordance with this privacy policy. For
                  example, if you have provided us with personal information as
                  part of creating an account with us, we may retain this
                  information for the duration your account exists on our
                  system. If your personal information is no longer required for
                  this purpose, we will delete it or make it anonymous by
                  removing all details that identify you.
                  <br />
                  <br />
                  However, if necessary, we may retain your personal information
                  for our compliance with a legal, accounting, or reporting
                  obligation or for archiving purposes in the public interest,
                  scientific, or historical research purposes or statistical
                  purposes.
                </p>

                <h2 className="heading">Children’s Privacy</h2>
                <p className="paragraphhhh">
                  We do not aim any of our products or services directly at
                  children under the age of 13 and we do not knowingly collect
                  personal information about children under 13.
                </p>
                <h2 className="heading">
                  Disclosure of Personal Information to Third Parties
                </h2>
                <div className="paragraphhhh">
                  We may disclose personal information to:
                  <br />
                  <br />
                  <ul>
                    <li>a parent, subsidiary or affiliate of our company</li>
                    <li>
                      third-party service providers for the purpose of enabling
                      them to provide their services, including (without
                      limitation) IT service providers, data storage, hosting
                      and server providers,ad networks,analytics,error loggers,
                      debt collectors, maintenance or problem-solving
                      providers,marketingoradvertisingproviders,professional
                      advisors, and payment systems operators
                    </li>
                    <li>our employees, contractors, and/or related entities</li>
                    <li>
                      our existing or potential agents or business partners
                    </li>
                    <li>
                      credit reporting agencies, courts, tribunals, and
                      regulatory authorities, in the event you fail to pay for
                      goods or services we have provided to you
                    </li>
                    <li>
                      courts, tribunals, regulatory authorities, and law
                      enforcement officers, as required by law, in connection
                      with any actual or prospective legal proceedings, or in
                      order to establish, exercise, or defend our legal rights
                    </li>
                    <li>
                      third parties, including agents or sub-contractors who
                      assist us in providing information, products, services, or
                      direct marketing to you
                    </li>
                    <li>third parties to collect and process data</li>
                    <li>
                      an entity that buys, or to which we transfer all or
                      substantially all of our assets and business
                    </li>
                  </ul>
                  Third parties we currently use include:
                  <br /> <br />
                  <ul>
                    <li>Google Analytics</li>
                    <li>Open Web Analytics</li>
                    <li>to deliver products and/or services to you</li>
                    <li>to contact and communicate with you</li>
                    <li>MailChimp</li>
                    <li>SendGrid</li>
                    <li>Google AdSense</li>
                    <li>Bing Ads</li>
                    <li>LinkedIn</li>
                    <li>Facebook</li>
                    <li>WhatsApp</li>
                    <li>Instagram</li>
                    <li>X</li>
                    <li>Paypal</li>
                    <li>Stripe</li>
                    <li>Square</li>
                    <li>Google Payments</li>
                    <li>Apple Pay</li>
                    <li>Amazon Web Services</li>
                    <li>Google Cloud</li>
                    <li>Microsoft Azure</li>
                    <li>ServerMania</li>
                  </ul>
                </div>
                <h2 className="heading">
                  International Transfers of Personal Information
                </h2>
                <p className="paragraphhhh">
                  The personal information we collect is stored and/or processed
                  in United States, or where we or our partners, affiliates, and
                  third-party providers maintain facilities.
                  <br />
                  <br />
                  The countries to which we store, process, or transfer your
                  personal information may not have the same data protection
                  laws as the country in which you initially provided the
                  information. If we transfer your personal information to third
                  parties in other countries: (i) we will perform those
                  transfers in accordance with the requirements of applicable
                  law; and (ii) we will protect the transferred personal
                  information in accordance with this privacy policy.
                </p>
                <h2 className="heading">
                  Your Rights and Controlling Your Personal Information
                </h2>
                <p className="paragraphhhh">
                  <span style={{ color: "#A072EB" }}>Your choice:</span> By
                  providing personal information to us, you understand we will
                  collect, hold, use, and disclose your personal information in
                  accordance with this privacy policy. You do not have to
                  provide personal information to us, however, if you do not, it
                  may affect your use of our websites or the products and/or
                  services offered on or through them.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>
                    Information from third parties:
                  </span>
                  If we receive personal information about you from a third
                  party, we will protect it as set out in this privacy policy.
                  If you are a third party providing personal information about
                  somebody else, you represent and warrant that you have such
                  person’s consent to provide the personal information to us.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>
                    Marketing permission: 
                  </span>
                  If you have previously agreed to us using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by contacting us using the details below.
                  policy.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Access:</span> You may
                  request details of the personal information that we hold about
                  you.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Correction: </span>If you
                  believe that any information we hold about you is inaccurate,
                  out of date, incomplete, irrelevant, or misleading, please
                  contact us using the details provided in this privacy policy.
                  We will take reasonable steps to correct any information found
                  to be inaccurate, incomplete, misleading, or out of date.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Non-discrimination: </span>
                  We will not discriminate against you for exercising any of
                  your rights over your personal information. Unless your
                  personal information is required to provide you with a
                  particular service or offer (for example processing
                  transaction data), we will not deny you goods or services
                  and/or charge you different prices or rates for goods or
                  services, including through granting discounts or other
                  benefits, or imposing penalties, or provide you with a
                  different level or quality of goods or services. policy.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>
                    Downloading of Personal Information:
                  </span>
                   We provide a means for you to download the personal
                  information you have shared through our sites. Please contact
                  us for more information.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>
                    Notification of data breaches:
                  </span>
                    We will comply with laws applicable to us in respect of any
                  data breach. policy.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Complaints:</span> If you
                  believe that we have breached a relevant data protection law
                  and wish to make a complaint, please contact us using the
                  details below and provide us with full details of the alleged
                  breach. We will promptly investigate your complaint and
                  respond to you, in writing, setting out the outcome of our
                  investigation and the steps we will take to deal with your
                  complaint. You also have the right to contact a regulatory
                  body or data protection authority in relation to your
                  complaint.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Unsubscribe:</span> To
                  unsubscribe from our email database or opt-out of
                  communications (including marketing communications), please
                  contact us using the details provided in this privacy policy,
                  or opt-out using the opt-out facilities provided in the
                  communication. We may need to request specific information
                  from you to help us confirm your identity.
                </p>
                <h2 className="heading">Use of Cookies</h2>
                <p className="paragraphhhh">
                  We use ’cookies’ to collect information about you and your
                  activity across our sites. A cookie is a small piece of data
                  that our websites store on your computer, and access each time
                  you visit, so we can understand how you use our sites. This
                  helps us serve you content based on preferences you have
                  specified. Please refer to our Cookie Policy for more
                  information.
                </p>
                <h2 className="heading">Business Transfers</h2>
                <p className="paragraphhhh">
                  If we or our assets are acquired, or in the unlikely event
                  that we go out of business or enter bankruptcy, we would
                  include data, including your personal information, among the
                  assets transferred to any parties who acquire us. You
                  acknowledge that such transfers may occur, and that any
                  parties who acquire us may, to the extent permitted by
                  applicable law, continue to use your personal information
                  according to this policy, which they will be required to
                  assume as it is the basis for any ownership or use rights we
                  have over such information.
                </p>
                <h2 className="heading">Limits of Our Policy</h2>
                <p className="paragraphhhh">
                  Our websites may link to external sites that are not operated
                  by us. Please be aware that we have no control over the
                  content and policies of those sites, and cannot accept
                  responsibility or liability for their respective privacy
                  practices.
                </p>
                <h2 className="heading">Changes to This Policy</h2>
                <p className="paragraphhhh">
                  At our discretion, we may change our privacy policy to reflect
                  updates to our business processes, current acceptable
                  practices, or legislative or regulatory changes. If we decide
                  to change this privacy policy, we will post the changes here
                  at the same link by which you are accessing this privacy
                  policy.
                  <br />
                  <br />
                  If the changes are significant, or if required by applicable
                  law, we will contact you (based on your selected preferences
                  for communications from us) and all our registered users with
                  the new details and links to the updated or changed policy.
                  <br />
                  <br />
                  If required by law, we will get your permission or give you
                  the opportunity to opt in to or opt out of, as applicable, any
                  new uses of your personal information.
                </p>
                <h2 className="heading">
                  Additional Disclosures for Australian Privacy Act Compliance
                  (AU)
                </h2>
                <h2 className="heading">
                  International Transfers of Personal Information
                </h2>
                <p className="paragraphhhh">
                  Where the disclosure of your personal information is solely
                  subject to Australian privacy laws, you acknowledge that some
                  third parties may not be regulated by the Privacy Act and the
                  Australian Privacy Principles in the Privacy Act. You
                  acknowledge that if any such third party engages in any act or
                  practice that contravenes the Australian Privacy Principles,
                  it would not be accountable under the Privacy Act, and you
                  will not be able to seek redress under the Privacy Act.
                </p>
                <h2 className="heading">
                  Additional Disclosures for General Data Protection Regulation
                  (GDPR) Compliance (EU)
                </h2>
                <h2 className="heading">Data Controller / Data Processor</h2>
                <p className="paragraphhhh">
                  The GDPR distinguishes between organisations that process
                  personal information for their own purposes (known as ’data
                  controllers’) and organisations that process personal
                  information on behalf of other organisations (known as ’data
                  processors’). We, International Advisory, Products and Systems
                  Ltd, located at the address provided in our Contact Us
                  section, are a Data Controller and/or Processor with respect
                  to the personal information you provide to us.
                </p>
                <h2 className="heading">
                  Legal Bases for Processing Your Personal Information
                </h2>
                <p className="paragraphhhh">
                  We will only collect and use your personal information when we
                  have a legal right to do so. In which case, we will collect
                  and use your personal information lawfully, fairly, and in a
                  transparent manner. If we seek your consent to process your
                  personal information, and you are under 16 years of age, we
                  will seek your parent or legal guardian’s consent to process
                  your personal information for that specific purpose. Our
                  lawful bases depend on the services you use and how you use
                  them. This means we only collect and use your information on
                  the following grounds:
                </p>
                <h2 className="heading">Consent From You</h2>
                <p className="paragraphhhh">
                  Where you give us consent to collect and use your personal
                  information for a specific purpose. You may withdraw your
                  consent at any time using the facilities we provide; however
                  this will not affect any use of your information that has
                  already taken place. You may consent to providing your email
                  address for the purpose of receiving marketing emails from us.
                  While you may unsubscribe at any time, we cannot recall any
                  email we have already sent. If you have any further enquiries
                  about how to withdraw your consent, please feel free to
                  enquire using the details provided in the Contact Us section
                  of this privacy policy.
                </p>
                <h2 className="heading">
                  Performance of a Contract or Transaction
                </h2>
                <p className="paragraphhhh">
                  Where you have entered into a contract or transaction with us,
                  or in order to take preparatory steps prior to our entering
                  into a contract or transaction with you. For example, if you
                  purchase a product, service, or subscription from us, we may
                  need to use your personal and payment information in order to
                  process and deliver your order.
                </p>
                <h2 className="heading">Our Legitimate Interests</h2>
                <p className="paragraphhhh">
                  Where we assess it is necessary for our legitimate interests,
                  such as for us to provide, operate, improve and communicate
                  our services. We consider our legitimate interests to include
                  research and development, understanding our audience,
                  marketing and promoting our services, measures taken to
                  operate our services efficiently, marketing analysis, and
                  measures taken to protect our legal rights and interests.
                </p>
                <h2 className="heading">Compliance with Law</h2>
                <p className="paragraphhhh">
                  In some cases, we may have a legal obligation to use or keep
                  your personal information. Such cases may include (but are not
                  limited to) court orders, criminal investigations, government
                  requests, and regulatory obligations. If you have any further
                  enquiries about how we retain personal information in order to
                  comply with the law, please feel free to enquire using the
                  details provided in the Contact Us section of this privacy
                  policy.
                </p>
                <h2 className="heading">
                  International Transfers Outside of the European Economic Area
                  (EEA)
                </h2>
                <p className="paragraphhhh">
                  We will ensure that any transfer of personal information from
                  countries in the European Economic Area (EEA) to countries
                  outside the EEA will be protected by appropriate safeguards,
                  for example by using standard data protection clauses approved
                  by the European Commission, or the use of binding corporate
                  rules or other legally accepted means.
                </p>
                <h2 className="heading">
                  Your Rights and Controlling Your Personal Information
                </h2>
                <p className="paragraphhhh">
                  <span style={{ color: "#A072EB" }}>Ristrict:</span>  You have
                  the right to request that we restrict the processing of your
                  personal information if (i) you are concerned about the
                  accuracy of your personal information; (ii) you believe your
                  personal information has been unlawfully processed; (iii) you
                  need us to maintain the personal information solely for the
                  purpose of a legal claim; or (iv) we are in the process of
                  considering your objection in relation to processing on the
                  basis of legitimate interests.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>
                    Objecting to processing:
                  </span>
                   You have the right to object to processing of your personal
                  information that is based on our legitimate interests or
                  public interest. If this is done, we must provide compelling
                  legitimate grounds for the processing which overrides your
                  interests, rights, and freedoms, in order to proceed with the
                  processing of your personal information.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Data portability </span>
                   You may have the right to request a copy of the personal
                  information we hold about you. Where possible, we will provide
                  this information in CSV format or other easily readable
                  machine format. You may also have the right to request that we
                  transfer this personal information to a third party.
                  <br />
                  <br />
                  <span style={{ color: "#A072EB" }}>Deletion:</span>You may
                  have a right to request that we delete the personal
                  information we hold about you at any time, and we will take
                  reasonable steps to delete your personal information from our
                  current records. If you ask us to delete your personal
                  information, we will let you know how the deletion affects
                  your use of our websites or products and services. There may
                  be exceptions to this right for specific legal reasons which,
                  if applicable, we will set out for you in response to your
                  request. If you terminate or delete your account, we will
                  delete your personal information within 45 days of the
                  deletion of your account. Please be aware that search engines
                  and similar third parties may still retain copies of your
                  personal information that has been made public at least once,
                  like certain profile information and public comments, even
                  after you have deleted the information from our services or
                  deactivated your account.
                </p>
                <h2 className="heading">
                  Additional Disclosures for U.S. States Privacy Law Compliance
                </h2>
                <p className="paragraphhhh">
                  The following section includes provisions that comply with the
                  privacy laws of these states (California, Colorado, Delaware,
                  Florida, Virginia, and Utah) and is applicable only to the
                  residents of those states. Specific references to a particular
                  state (in a heading or in the text) are only a reference to
                  that state’s law and applies only to that state’s residents.
                  Non-state specific language applies to all of the states
                  listed above.
                </p>
                <h2 className="heading">Do Not Track</h2>
                <p className="paragraphhhh">
                  Some browsers have a ’Do Not Track’ feature that lets you tell
                  websites that you do not want to have your online activities
                  tracked. At this time, we do not respond to browser ’Do Not
                  Track’ signals We adhere to the standards outlined in this
                  privacy policy, ensuring we collect and process personal
                  information lawfully, fairly, transparently, and with
                  legitimate, legal reasons for doing so.{" "}
                </p>
                <h2 className="heading">Cookies and Pixels</h2>
                <p className="paragraphhhh">
                  At all times, you may decline cookies from our sites if your
                  browser permits. Most browsers allow you to activate settings
                  on your browser to refuse the setting of all or some cookies.
                  Accordingly, your ability to limit cookies is based only on
                  your browser’s capabilities. Please refer to the Cookies
                  section of this privacy policy for more information.
                </p>
                <h2 className="heading">California Privacy Laws - CPPA</h2>
                <p className="paragraphhhh">
                  Under California Civil Code Section 1798.83, if you live in
                  California and your business relationship with us is mainly
                  for personal, family, or household purposes, you may ask us
                  about the information we release to other organizations for
                  their marketing purposes. In accordance with your right to
                  non-discrimination, we may offer you certain financial
                  incentives permitted by the California Consumer Privacy Act,
                  and the California Privacy Rights Act (collectively, CCPA)
                  that can result in different prices, rates, or quality levels
                  for the goods or services we provide. Any CCPA-permitted
                  financial incentive we offer will reasonably relate to the
                  value of your personal information, and we will provide
                  written terms that describe clearly the nature of such an
                  offer. Participation in a financial incentive program requires
                  your prior opt-in consent, which you may revoke at any time.{" "}
                  <br /> <br />
                  Under California Civil Code Section 1798.83, if you live in
                  California and your business relationship with us is mainly
                  for personal, family, or household purposes, you may ask us
                  about the information we release to other organizations for
                  their marketing purposes. To make such a request, please
                  contact us using the details provided in this privacy policy
                  with “Request for California privacy information” in the
                  subject line. You may make this type of request once every
                  calendar year. We will email you a list of categories of
                  personal information we revealed to other organisations for
                  their marketing purposes in the last calendar year, along with
                  their names and addresses. Not all personal information shared
                  in this way is covered by Section 1798.83 of the California
                  Civil Code.
                </p>
                <h2 className="heading">California Notice of Collection</h2>
                <div className="paragraphhhh">
                  In the past 12 months, we have collected the following
                  categories of personal information enumerated in the CCPA:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Identifiers, such as name, email address, phone number,
                      account name, IP address, and an ID or number assigned to
                      your account.
                    </li>
                    <li>
                      Demographics, such as your age or gender. This category
                      includes data that may qualify as protected
                      classifications under other California or federal laws.
                    </li>
                    <li>
                      Customer records, such as billing and shipping address,
                      and credit or debit card data.
                    </li>
                    <li>
                      Use a mobile device or web browser to access our content
                    </li>
                    <li>
                      Commercial information, such as products or services
                      history and purchases.
                    </li>
                    <li>
                      Internet activity, such as your interactions with our
                      service.
                    </li>
                    <li>
                      Audio or visual data, such as photos or videos you share
                      with us or post on the service.
                    </li>
                    <li>Geolocation data.</li>
                    <li>
                      Employment and education data, such as data you provide
                      when you apply for a job with us.
                    </li>
                    <li>
                      Inferences, such as information about your interests,
                      preferences and favorites.
                    </li>
                  </ul>
                  For more information on information we collect, including the
                  sources we receive information from, review the “Information
                  We Collect” section. We collect and use these categories of
                  personal information for the business purposes described in
                  the “Collection and Use of Information” section, including to
                  provide and manage our Service.
                </div>
                <h2 className="heading">Right to Know and Delete</h2>
                <div className="paragraphhhh">
                  You have rights to delete your personal information we
                  collected and know certain information about our data
                  practices in the preceding 12 months. In particular, you have
                  the right to request the following from us:
                  <br />
                  <br />
                  <ul>
                    <li>
                      The categories of personal information we have collected
                      about you;
                    </li>
                    <li>
                      The categories of sources from which the personal
                      information was collected;
                    </li>
                    <li>
                      The categories of personal information about you we
                      disclosed for a business purpose or sold;
                    </li>
                    <li>
                      The categories of third parties to whom the personal
                      information was disclosed for a business purpose or sold;
                    </li>
                    <li>
                      The business or commercial purpose for collecting or
                      selling the personal information; and
                    </li>
                    <li>
                      The specific pieces of personal information we have
                      collected about you.
                    </li>
                  </ul>
                  To exercise any of these rights, please contact us using the
                  details provided in this privacy policy.
                </div>
                <h2 className="heading">Shine the Light</h2>
                <p className="paragraphhhh">
                  In addition to the rights discussed above, you have the right
                  to request information from us regarding the manner in which
                  we share certain personal information as defined by applicable
                  statute with third parties and affiliates for their own direct
                  marketing purposes. <br /> <br />
                  To receive this information, send us a request using the
                  contact details provided in this privacy policy. Requests must
                  include “Privacy Rights Request” in the first line of the
                  description and include your name, street address, city,
                  state, and ZIP code.
                </p>
                <h2 className="heading">Contact Us</h2>
                <p className="paragraphhhh">
                  For any questions or concerns regarding your privacy, you may
                  contact us using the following details:<br></br> Customer
                  Service:
                  <br></br>
                  <a
                    style={{ color: "#A072EB" }}
                    href="mailto:info@kaptechgroup.com"
                  >
                    info@kaptechgroup.com
                  </a>
                </p>
              </div>
            </TabPane>
            <TabPane tab="Cookie Policy" key="3">
              <div className="scrollable-content">
                <h2 className="firstHeading">Cookie policy</h2>
                <p className="paragraphhhh">
                  These Terms of Service were last updated on March 4, 2024.{" "}
                  <br></br> <br></br>
                  We use cookies to help improve your experience of our websites
                  at
                  <a style={{ color: "#A072EB" }} href="https://www.i-aps.com">
                    {" "}
                    https://www.i-aps.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kaptechgroup.com"
                  >
                    {" "}
                    https://kaptechgroup.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://www.kapsurvey.com"
                  >
                    {" "}
                    https://www.kapsurvey.com
                  </a>
                  ,
                  <a style={{ color: "#A072EB" }} href="https://www.kaporg.com">
                    {" "}
                    https://www.kaporg.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kapinsights.com"
                  >
                    {" "}
                    https://kapinsights.com
                  </a>
                  , and
                  <a style={{ color: "#A072EB" }} href="https://kapcourse.com">
                    {" "}
                    https://kapcourse.com
                  </a>
                  . This cookie policy is part of International Advisory,
                  Products and Systems Ltd’s privacy policy. It covers the use
                  of cookies between your device and our sites. We also provide
                  basic information on third-party services we may use, who may
                  also use cookies as part of their service. This policy does
                  not cover their cookies. . <br></br> <br></br>
                  If you don’t wish to accept cookies from us, you should
                  instruct your browser to refuse cookies from
                  <a style={{ color: "#A072EB" }} href="https://www.i-aps.com">
                    {" "}
                    https://www.i-aps.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kaptechgroup.com"
                  >
                    {" "}
                    https://kaptechgroup.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://www.kapsurvey.com"
                  >
                    {" "}
                    https://www.kapsurvey.com
                  </a>
                  ,
                  <a style={{ color: "#A072EB" }} href="https://www.kaporg.com">
                    {" "}
                    https://www.kaporg.com
                  </a>
                  ,
                  <a
                    style={{ color: "#A072EB" }}
                    href="https://kapinsights.com"
                  >
                    {" "}
                    https://kapinsights.com
                  </a>
                  , and
                  <a style={{ color: "#A072EB" }} href="https://kapcourse.com">
                    {" "}
                    https://kapcourse.com
                  </a>
                  . In such a case, we may be unable to provide you with some of
                  your desired content and services.
                </p>
                <h2 className="heading">What is a cookie?</h2>
                <p className="paragraphhhh">
                  A cookie is a small piece of data that a website stores on
                  your device when you visit. It typically contains information
                  about the website itself, a unique identifier that allows the
                  site to recognise your web browser when you return, additional
                  data that serves the cookie’s purpose, and the lifespan of the
                  cookie itself. <br />
                  <br />
                  Cookies are used to enable certain features (e.g. logging in),
                  track site usage (e.g. analytics), store your user settings
                  (e.g. time zone, notification preferences), and to personalize
                  your content (e.g. advertising, language).
                  <br />
                  <br />
                  Cookies set by the website you are visiting are usually
                  referred to as first-party cookies. They typically only track
                  your activity on that particular site.
                  <br />
                  <br />
                  Cookies set by other sites and companies (i.e. third parties)
                  are called third-party cookies They can be used to track you
                  on other websites that use the same third-party service.
                </p>

                <h2 className="heading">
                  Types of cookies and how we use them
                </h2>
                <h2 className="heading22">Essential cookies</h2>
                <p className="paragraphhhh">
                  Essential cookies are crucial to your experience of a website,
                  enabling core features like user logins, account management,
                  shopping carts, and payment processing.
                  <br />
                  We use essential cookies to enable certain functions on our
                  websites.
                </p>
                <h2 className="heading22">Performance cookies</h2>
                <p className="paragraphhhh">
                  Performance cookies track how you use a website during your
                  visit. Typically, this information is anonymous and
                  aggregated, with information tracked across all site users.
                  They help companies understand visitor usage patterns,
                  identify and diagnose problems or errors their users may
                  encounter, and make better strategic decisions in improving
                  their audience’s overall website experience. These cookies may
                  be set by the website you’re visiting (first-party) or by
                  third-party services. They do not collect personal information
                  about you.
                  <br />
                  We use performance cookies on our sites.
                </p>
                <h2 className="heading22">Functionality cookies</h2>
                <p className="paragraphhhh">
                  Functionality cookies are used to collect information about
                  your device and any settings you may configure on the website
                  you’re visiting (like language and time zone settings). With
                  this information, websites can provide you with customized,
                  enhanced, or optimized content and services. These cookies may
                  be set by the website you’re visiting (first-party) or by
                  third-party services.
                  <br />
                  We use functionality cookies for selected features on our
                  sites.
                </p>
                <h2 className="heading22">Targeting/advertising cookies</h2>
                <p className="paragraphhhh">
                  Targeting/advertising cookies help determine what promotional
                  content is most relevant and appropriate to you and your
                  interests. Websites may use them to deliver targeted
                  advertising or limit the number of times you see an
                  advertisement. This helps companies improve the effectiveness
                  of their campaigns and the quality of content presented to
                  you. These cookies may be set by the website you’re visiting
                  (first-party) or by third-party services.
                  Targeting/advertising cookies set by third-parties may be used
                  to track you on other websites that use the same third-party
                  service.
                  <br />
                  We use targeting/advertising cookies on our sites.
                </p>

                <h2 className="heading">
                  How Can You Control Our Website’s Use of Cookies?
                </h2>
                <p className="paragraphhhh">
                  You have the right to decide whether to accept or reject
                  cookies on our websites. You can manage your cookie
                  preferences in our Cookie Consent Manager. The Cookie Consent
                  Manager allows you to select which categories of cookies you
                  accept or reject. Essential cookies cannot be rejected as they
                  are strictly necessary to provide you with the services on our
                  websites.
                  <br />
                  You may also be able to set or amend your cookie preferences
                  by managing your web browser settings. As each web browser is
                  different, please consult the instructions provided by your
                  web browser (typically in the ’help’ section). If you choose
                  to refuse or disable cookies you may still use our websites,
                  though some of their functionality may not be available to
                  you.
                </p>
                <h2 className="heading">
                  How Often Will We Update This Cookie Policy?
                </h2>
                <p className="paragraphhhh">
                  We may update this Cookie Policy from time to time in order to
                  reflect any changes to the cookies and related technologies we
                  use, or for other operational, legal or regulatory reasons.
                  <br /> <br />
                  Each time you use our websites, the current version of the
                  Cookie Policy will apply. When you use our websites, you
                  should check the date of this Cookie Policy (which appears at
                  the top of this document) and review any changes since the
                  last version.
                </p>
                <h2 className="heading">
                  Where Can You Obtain Further Information?
                </h2>
                <p className="paragraphhhh">
                  For any questions or concerns regarding our Cookie Policy, you
                  may contact us using the following details:
                  <br></br>Customer Service: <br></br>
                  <a
                    style={{ color: "#A072EB" }}
                    href="mailto:info@kaptechgroup.com"
                  >
                    info@kaptechgroup.com
                  </a>
                </p>
              </div>
            </TabPane>
          </Tabs>
        </Modal>
      </div>
      <Modal
        title="Enter your code"
        visible={isCodeModalVisible}
        onOk={handleCodeModalOk}
        onCancel={handleCodeModalCancel}
        footer={[
          <Button key="back" onClick={handleCodeModalCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCodeModalOk}>
            Submit Code
          </Button>,
        ]}
        className="enter-your-code-modal"
      >
        <label className="please-check-text">
          Please check your email to get the code!
        </label>
        <Input
          style={{ marginTop: "1rem" }}
          placeholder="Type your code here"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />{" "}
      </Modal>
    </section>
  );
};
export default Register;

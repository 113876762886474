import React, { useState } from "react";
import { Form, Input, Button, Typography, Modal } from "antd";
import icon from "../assets/img/forgotPassword.png";
import wave from "../assets/img/waveSvgg.svg";
import "./ForgotPassword.css";
import kapinsightsLogo from "../assets/img/kapinsightsLogoo.png";
import email from "../assets/img/forgotPassEmail.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Spin } from "antd";

const { Title, Paragraph } = Typography;

const ForgotPassword: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    setLoading(true);
    // const payload = {
    //   email: values.email,
    // };

    try {
      // Send a POST request to the register endpoint
      const response = await axios.post(
        `https://aitools.kapinsights.com/Users/forgot-password/?email=${values.email}`
      );
      console.log("useri qe u regjistru eshte: ", response);
      if (response) {
        setTimeout(
          () =>
            toast.success(
              "Check your inbox to access the token for reset your password"
            ),
          0
        );
        setTimeout(() => {
          setLoading(false);
          setIsModalVisible(true);
        }, 4000);
      }

      // Redirect to login page after successful registration
    } catch (error: any) {
      // If there is an error during registration, display it
      toast.error(error.response?.data?.detail || "failed!");
      setLoading(false);
    }
  };

  // Function to handle modal closure
  const handleOk = () => {
    setIsModalVisible(false);
    navigate("/reset-password");
  };

  return (
    <div className="forgot-password-container">
      <ToastContainer />

      <div className="kapinsightsLogo">
        <img src={kapinsightsLogo} alt="kapinsights logo" />
      </div>
      <div className="container">
        <img src={icon} alt="forgot password" className="forgotPassImg" />
        <div className="forgot-password-form-container">
          <Title level={2} className="title">
            Forgot Your Password?
          </Title>
          <Paragraph className="paragraph">
            We’re here to help! Please enter the email address associated with
            your account. We will send you instructions to reset your password.
          </Paragraph>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form
              name="forgot_password"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Typography className="emailLabel">Email</Typography>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Type your email" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="forgot-password-form-button"
                >
                  Send email
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
      <div>
        <img src={wave} alt="wave" className="wave" />
      </div>

      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Got it!
          </Button>,
        ]}
        style={{ width: "670px" }} // Ensuring it is set here
        wrapClassName="custom-modal-forgotPass"
      >
        <img src={email} alt="Email sent" className="modal-image" />
        <h2 className="email-title">Email sent!</h2>
        <p className="email-paragraph">
          Check your inbox to access the link to reset your password.
        </p>
      </Modal>
    </div>
  );
};

export default ForgotPassword;

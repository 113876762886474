import React, { useEffect } from "react";
import { Layout } from "antd";
import TopBar from "../../Topbar/Header";
import "./ProjectDashboard.css";
import DashboardImg from "../../../assets/img/dashbordImage.png";
import { useParams } from "react-router-dom";

const { Content } = Layout;

const ProjectDashboard: React.FC = () => {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      localStorage.setItem("currentProjectId", id);
    }
  }, [id]);

  return (
    <div className="project-dashboard">
      <Content
        className="content-style"
        style={{ padding: "8rem 4rem 0 4rem" }}
      >
        {/* <div style={{ padding: "2rem 4rem" }}> */}
        <div className="welcome-message">
          <h1 className="animated-title">Welcome to KAPinsights</h1>
        </div>
        {/* </div> */}
        <div style={{ marginTop: "5 rem" }}>
          <img
            src={DashboardImg}
            alt="dashboard img"
            className="dashboardImg"
          />
        </div>
      </Content>
    </div>
  );
};

export default ProjectDashboard;

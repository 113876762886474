import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Select, DatePicker, message } from "antd";
import { get, post, put } from "../../../utils/apiUtils";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";

const { Option } = Select;

function ProjectModal({ visible, onCancel, onCreate, projectId, mode }: any) {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [managers, setProjectManagers] = useState<any>([]);
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchProjectManager = async () => {
      try {
        const response = await get<any>(
          `users/project-managers?workspace_id=${workspaceId}`
        );
        if (response.type === "success") {
          setProjectManagers(response.data);
        }
      } catch (error) {
        message.error("Failed to load managers data!");
      }
    };

    fetchProjectManager();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await get<any>(`workspaces/${workspaceId}/countries/`);
        console.log("countries response::", response);
        if (response.type === "success") {
          setCountries(response.data); // Assuming response data is the array of countries
        }
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        message.error("Failed to load countries data!");
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchTeamMember = async () => {
      try {
        const response = await get<any>(`members-by-workspace/${workspaceId}`);
        if (response.type === "success") {
          setTeamMembers(response.data);
        }
      } catch (error) {
        message.error("Failed to load managers data!");
      }
    };

    fetchTeamMember();
  }, []);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (["edit", "view"].includes(mode) && projectId) {
        try {
          const response = await get<any>(`projects/${projectId}`);
          if (response?.type === "success") {
            const {
              name,
              description,
              project_manager,
              team_members,
              country,
              start_date,
              end_date,
            } = response.data;

            form.setFieldsValue({
              name,
              description,
              manager: project_manager.id,
              member: team_members.map((tm: any) => tm.id),
              country: country.id,
              startDate: moment(start_date, "YYYY-MM-DD"),
              endDate: moment(end_date, "YYYY-MM-DD"),
            });
          } else {
            console.error("Failed to fetch project details:", response.type);
            message.error("Failed to fetch project details: " + response.type);
          }
        } catch (error: any) {
          console.error("Error fetching project details:", error);
          message.error("Failed to fetch project details: " + error.message);
        }
      }
    };

    fetchProjectDetails();
  }, [projectId, mode, form]);

  // useEffect(() => {
  //   const fetchProjectDetails = async () => {
  //     if (["edit", "view"].includes(mode) && projectId) {
  //       try {
  //         const response = await get<any>(`projects/${projectId}`);
  //         if (response?.type === "success") {
  //           const { name, description, workspace_id } = response.data;
  //           form.setFieldsValue({
  //             name: name,
  //             description: description,
  //             manager: "Manager 1",
  //             workspace_id: workspace_id,
  //           });
  //         } else {
  //           console.error("Failed to fetch project details:", response.type);
  //           message.error("Failed to fetch project details: " + response.type);
  //         }
  //       } catch (error: any) {
  //         console.error("Error fetching project details:", error);
  //         message.error("Failed to fetch project details: " + error.message);
  //       }
  //     }
  //   };

  //   fetchProjectDetails();
  // }, [projectId, mode, form]);

  // const handleSubmit = async (values: any) => {
  //   setLoading(true);

  //   // Forming the payload based on whether it's an edit or create action
  //   const payload =
  //     mode === "edit"
  //       ? {
  //           name: values.name,
  //           description: values.description,
  //           project_manager: {
  //             id: values.manager, // manager id, assuming the manager field captures the ID
  //             username: managers.find(
  //               (manager: any) => manager.id === values.manager
  //             )?.username, // extracting username from state
  //           },
  //           team_member_ids: values.member, // assuming this is an array of IDs from the form
  //           country_id: values.country,
  //           start_date: values.startDate.format("YYYY-MM-DD"),
  //           end_date: values.endDate.format("YYYY-MM-DD"),
  //           workspace_id: "ac44f458-c483-421d-9bc9-36eaf70245ec",
  //         }
  //       : {
  //           name: values.name,
  //           description: values.description,
  //           manager_id: values.manager,
  //           team_member_ids: values.member, // This now directly accepts an array from the form
  //           country_id: values.country,
  //           start_date: values.startDate.format("YYYY-MM-DD"),
  //           end_date: values.endDate.format("YYYY-MM-DD"),
  //           workspace_id: "ac44f458-c483-421d-9bc9-36eaf70245ec",
  //         };

  //   try {
  //     const response =
  //       mode === "edit"
  //         ? await put<any>(`projects/${projectId}/edit`, payload) // Adjusted for edit endpoint
  //         : await post<any>(`projects/`, payload); // Simplified for create endpoint

  //     if (response?.type === "success") {
  //       message.success(
  //         `Project ${mode === "edit" ? "updated" : "created"} successfully!`
  //       );
  //       onCreate(values);
  //     } else {
  //       message.error(
  //         `Failed to ${mode === "edit" ? "update" : "create"} project: ${
  //           response.type
  //         }`
  //       );
  //     }
  //   } catch (error: any) {
  //     message.error(
  //       `Failed to ${mode === "edit" ? "update" : "create"} project: ${
  //         error.message
  //       }`
  //     );
  //   }

  //   setLoading(false);
  //   form.resetFields();
  //   onCancel(); // Close modal after submission
  // };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    // Mbledhja e të dhënave të anëtarëve të ekipit nga zgjedhjet e bëra në formë
    const teamMemberData = values.member.map((memberId: any) => {
      const member = teamMembers.find((m: any) => m.id === memberId);
      return { id: member.id, username: member.username };
    });

    // Përgatitja e ngarkesës së të dhënave për dërgim
    const payload =
      mode === "edit"
        ? {
            name: values.name,
            description: values.description,
            project_manager: {
              id: values.manager,
              username: managers.find(
                (manager: any) => manager.id === values.manager
              )?.username,
            },
            team_members: teamMemberData,
            country_id: values.country,
            start_date: values.startDate.format("YYYY-MM-DD"),
            end_date: values.endDate.format("YYYY-MM-DD"),
          }
        : {
            name: values.name,
            description: values.description,
            manager_id: values.manager,
            team_member_ids: values.member,
            country_id: values.country,
            start_date: values.startDate.format("YYYY-MM-DD"),
            end_date: values.endDate.format("YYYY-MM-DD"),
            workspace_id: workspaceId,
          };

    try {
      const response =
        mode === "edit"
          ? await put<any>(`projects/${projectId}/edit`, payload)
          : await post<any>(`projects/`, payload);

      if (response?.type === "success") {
        message.success(
          `Project ${mode === "edit" ? "updated" : "created"} successfully!`
        );
        onCreate(values);
      } else {
        message.error(
          `Failed to ${mode === "edit" ? "update" : "create"} project: ${
            response.type
          }`
        );
      }
    } catch (error: any) {
      message.error(
        `Failed to ${mode === "edit" ? "update" : "create"} project: ${
          error.message
        }`
      );
    }

    setLoading(false);
    form.resetFields();
    onCancel(() => {
      form.resetFields();
    });
  };

  useEffect(() => {
    if (visible && mode === "create") {
      form.resetFields();
      // Reset any other related state or selections
    }
  }, [visible, mode, form]);
  // const handleSubmit = async (values: any) => {
  //   setLoading(true);

  //   if (mode === "edit") {
  //     try {
  //       const response = await put<any>(`project/${projectId}`, {
  //         name: values.name,
  //         description: values.description,
  //         workspace_id: "98d21b03-7f86-4daf-9858-89ec95942cd6", // Ensure this is being captured somewhere in your form
  //       });
  //       if (response.type === "success") {
  //         message.success("Project updated successfully!");
  //         onCreate(values);
  //         setLoading(false);
  //       } else {
  //         message.error(response.type);
  //       }
  //     } catch (error: any) {
  //       message.error("Failed to update project: " + error.message);
  //     }
  //   } else {
  //     try {
  //       const response = await post<any>(
  //         `projects/?workspace_id=98d21b03-7f86-4daf-9858-89ec95942cd6`,
  //         { name: values.name, description: values.description }
  //       );
  //       if (response?.type === "success") {
  //         message.success("Project created successfully!");
  //         form.resetFields();
  //         onCreate(values);
  //         setLoading(false);
  //       }
  //     } catch (error: any) {
  //       message.error("Failed to create project: " + error.message);
  //     }
  //   }
  //   form.resetFields();
  //   onCancel(); // Close modal after submission
  // };

  return (
    <Modal
      visible={visible}
      title={
        mode === "edit"
          ? "Edit Project"
          : mode === "view"
          ? "View Project"
          : "New Project"
      }
      className="new-project"
      okText={mode === "edit" ? "Update Project" : "Create Project"}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: loading }}
      footer={
        mode === "view"
          ? [
              <Button key="back" onClick={onCancel}>
                Close
              </Button>,
            ]
          : undefined
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label="Project title"
          className="title"
          rules={[
            {
              required: true,
              message: "Please input the title of the project!",
            },
          ]}
        >
          <Input disabled={mode === "view"} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Project description"
          className="title"
        >
          <Input.TextArea disabled={mode === "view"} />
        </Form.Item>
        <Form.Item
          name="manager"
          label="Project manager"
          className="title"
          rules={[
            { required: true, message: "Please select the project manager!" },
          ]}
        >
          {/* <Select
            showSearch
            placeholder="Select a manager"
            disabled={mode === "view"}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {managers?.map((manager: any) => (
              <Option key={manager.id} value={manager.id}>
                {manager.username}
              </Option>
            ))}
          </Select> */}
          <Select
            showSearch
            placeholder="Select a manager"
            disabled={mode === "view"}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {managers.map((manager: any) => (
              <Option key={manager.id} value={manager.id}>
                {manager.username}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="member"
          label="Team members"
          className="title"
          rules={[
            { required: true, message: "Please select the team members!" },
          ]}
        >
          {/* <Select
            mode="multiple"
            showSearch
            placeholder="Select team members"
            disabled={mode === "view"}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {teamMembers?.map((member: any) => (
              <Option key={member.id} value={member.id}>
                {member.username}
              </Option>
            ))}
          </Select> */}
          <Select
            mode="multiple"
            showSearch
            placeholder="Select team members"
            disabled={mode === "view"}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {teamMembers.map((member: any) => (
              <Option key={member.id} value={member.id}>
                {member.username}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="country"
          label="Country"
          rules={[{ required: true, message: "Please select a country!" }]}
          style={{ width: "100%" }}
          className="title-style"
        >
          <Select
            showSearch
            placeholder="Select your country"
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={mode === "view"}
          >
            {countries.map((country: any) => (
              <Option key={country.id} value={country.id}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="date"
          label="Start Date"
          className="title"
          rules={[{ required: true, message: "Please choose the start date" }]}
        >
          <DatePicker disabled={mode === "view"} />
        </Form.Item> */}
        <div className="date-style">
          <Form.Item
            name="startDate"
            label="Start Date"
            className="title"
            rules={[
              { required: true, message: "Please choose the start date" },
            ]}
          >
            <DatePicker disabled={mode === "view"} />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="End Date"
            className="title"
            rules={[{ required: true, message: "Please choose the end date" }]}
          >
            <DatePicker disabled={mode === "view"} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

export default ProjectModal;

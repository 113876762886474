import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Tabs,
  Typography,
  Upload,
  message,
  Spin,
  Table,
  Space,
  Divider,
  Radio,
  TableColumnsType,
  DatePicker,
  Form,
  Collapse,
} from "antd";
import { toast } from "react-toastify";
// import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import { Modal } from "antd";
import HeaderAITool from "../../../../assets/img/header.png";
import "./index.css";
// import HeaderOfList from "../../../assets/img/headerForList.png";
import HeaderOfUserManagement from "../../../assets/img/userManagement1.png";
import {
  PlusOutlined,
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownloadOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { get } from "../../../utils/apiUtils";
import MemberModal from "../MemberModal";
import { getUserRole } from "../../../hooks/getUserRoles";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  gender: string;
  location: string;
  languages: string;
  role: string;
  workspaces: string;
  actions: JSX.Element;
}

// const columns: TableColumnsType<DataType> = [
//   {
//     title: "History of translations",
//     dataIndex: "projectname",
//     // render: (text: string) => <a>{text}</a>,
//   },
//   {
//     title: "Type",
//     dataIndex: "type_name",
//   },
//   {
//     title: "Date",
//     dataIndex: "date",
//     render: (text) =>
//       text === "Invalid date" ? (
//         <span style={{ color: "red" }}>{text}</span>
//       ) : (
//         text
//       ),
//   },
//   {
//     title: "",
//     dataIndex: "actions",
//   },
// ];

const data: DataType[] = [
  // Assuming data extracted from the second image
  {
    key: "1",
    name: "Full name",
    email: "user@gmail.com",
    gender: "Female",
    location: "Turkey",
    languages: "Turkish",
    role: "Manager",
    workspaces: "SY-APS",
    actions: <div>Actions here</div>,
  },
  {
    key: "2",
    name: "Full name",
    email: "user@gmail.com",
    gender: "Female",
    location: "Albania",
    languages: "Albanian, Amharic",
    role: "Manager",
    workspaces: "SY-APS",
    actions: <div>Actions here</div>,
  },
  // Add other entries similarly
];
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record: DataType) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

function MembersList() {
  const userRole = getUserRole();
  const [selectionType, setSelectionType] = useState<"checkbox">("checkbox");
  const [searchText, setSearchText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<DataType[]>(data);
  const [typeFilter, setTypeFilter] = useState<string>("Type");
  const [dateFilter, setDateFilter] = useState<Dayjs | string>("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [translationList, setTranslationList] = useState([]);
  const [translationTypes, setTranslationTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [genders, setGenders] = useState([]);
  const [countries, setCountries] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [roles, setRoles] = useState([]);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectedGender, setSelectedGender] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState(null);
  const [modalMode, setModalMode] = useState("create");
  const [errorInResponse, setErrorInResponse] = useState<any>("");
  const [form] = Form.useForm();
  const [, forceUpdate] = useState<any>();

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataType, b: DataType) => a.name.localeCompare(b.name),
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    // {
    //   title: "Gender",
    //   dataIndex: "gender",
    //   key: "gender",
    // },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a: DataType, b: DataType) =>
        a.location.localeCompare(b.location),
    },
    // {
    //   title: "Languages",
    //   dataIndex: "languages",
    //   key: "languages",
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a: DataType, b: DataType) => a.role.localeCompare(b.role),
    },
    {
      title: "Workspaces",
      dataIndex: "workspaces",
      key: "workspaces",
      sorter: (a: DataType, b: DataType) =>
        a.workspaces.localeCompare(b.workspaces),
    },
    {
      //   title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined
            onClick={() => viewMemberDetails(record.key)}
            style={{ fill: "#A072EB", color: "#A072EB" }}
          />
          <EditOutlined
            onClick={() => openModalForEdit(record.key)}
            style={{ fill: "#A072EB", color: "#A072EB" }}
          />
          <DeleteOutlined
            onClick={() => deleteMember(record.key)}
            style={{ fill: "#A072EB", color: "#A072EB" }}
          />
        </Space>
      ),
    },
  ];

  const openModalForEdit = (memberId: any) => {
    setModalMode("edit");
    setCurrentMemberId(memberId);
    setIsModalVisible(true);
  };
  const showModalForCreate = () => {
    setModalMode("create");
    setCurrentMemberId(null);
    setIsModalVisible(true);
  };
  const viewMemberDetails = (memberId: any) => {
    setCurrentMemberId(memberId);
    setModalMode("view");
    setIsModalVisible(true);
  };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await get<any>("countries/");
        if (response.type === "success") {
          setCountries(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        toast.error("Failed to load countries data!");
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await get<any>("Users/workspace-roles/");
        console.log("respone in roleS:", response);
        if (response.type === "success") {
          setRoles(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch roles:", error);
        message.error("Failed to load roles data!");
      }
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const response = await get<any>("get-all-workspaces");
        if (response.type === "success") {
          setWorkspaces(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch workspace:", error);
        message.error("Failed to load workspace data!");
      }
    };
    fetchWorkspaces();
  }, []);
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await get<any>("Users/languages/");
        if (response.type === "success") {
          setLanguages(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch languages:", error);
        message.error("Failed to load language data!");
      }
    };
    fetchLanguages();
  }, []);
  useEffect(() => {
    const fetchGenders = async () => {
      try {
        const response = await get<any>("genders");
        if (response.type === "success") {
          setGenders(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch gender:", error);
        message.error("Failed to load gender data!");
      }
    };
    fetchGenders();
  }, []);
  const filterData = (): void => {
    const lowercasedSearchText = searchText.toLowerCase();
    const filtered = translationList.filter((item: any) => {
      const searchTextMatch = item.projectname
        .toLowerCase()
        .includes(lowercasedSearchText);
      const typeMatch = typeFilter === "Type" || item.type_name === typeFilter;
      const dateMatch =
        !dateFilter || dayjs(item.date).isSame(dayjs(dateFilter), "day");
      return searchTextMatch && typeMatch && dateMatch;
    });
    setFilteredData(filtered);
  };

  const onCreate = (values: any) => {
    console.log("Received values of form: ", values);
    // Here you could add new project to the state or send it to the backend
    setVisible(false);
  };

  // const deleteMember = (id: any) => {
  //   Modal.confirm({
  //     title: "Are you sure you want to delete this translation?",
  //     content: (
  //       <div style={{ marginBottom: "1rem" }}>
  //         This action cannot be undone.
  //       </div>
  //     ),
  //     okText: <div style={{ width: "150px" }}>Yes</div>,
  //     okType: "danger",
  //     cancelText: <div style={{ width: "150px" }}>No</div>,
  //     onOk: async () => {
  //       try {
  //         setLoading(true); // Set loading to true while deleting

  //         // Make an API call to delete the translation
  //         const token = localStorage.getItem("token"); // Retrieve token
  //         await axios.delete(
  //           `https://aitools.kapinsights.com/translations/${id}`,
  //           {
  //             headers: {
  //               Accept: "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         message.success("Translation deleted successfully");

  //         // Refresh the list of translations
  //         await fetchTranslations();
  //       } catch (error: any) {
  //         message.error("Failed to delete translation: " + error.message);
  //       } finally {
  //         setLoading(false); // Ensure loading is false after operation
  //       }
  //     },
  //   });
  // };

  const deleteMember = (id: any) => {
    Modal.confirm({
      title: "Delete member",
      content: (
        <>
          <span style={{ textAlign: "center" }}>
            You're about to delete this Member. This action can’t be undone.{" "}
          </span>
          <br />
          <strong>Are you sure you want to continue? </strong>
        </>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "delete-member-modal",
      onOk: async () => {
        setLoading(true);
        try {
          const token = localStorage.getItem("token");
          console.log("Token:", token);

          const response = await axios.delete(
            `https://aitools.kapinsights.com/Users/workspace-members/${id}/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
              },
            }
          );

          console.log("Delete Response:", response); // Debugging line

          if (response.status === 200) {
            message.success("Member deleted successfully");
            // Refresh the list after deletion
            fetchData();
          } else {
            message.error(
              `Failed to delete the member: ${response.statusText}`
            );
          }
        } catch (error: any) {
          console.error("Error deleting member:", error);
          if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
            message.error(
              `Failed to delete the member: ${
                error.response.data.message || error.response.statusText
              }`
            );
          } else {
            message.error(`Failed to delete the member: ${error.message}`);
          }
        } finally {
          setLoading(false); // Stop loading indication
        }
      },
    });
  };

  //   const deleteMember = async (id: any) => {
  //     try {
  //       console.log("id", id);
  //       // Confirm deletion with the user before proceeding
  //       if (
  //         !window.confirm("Are you sure you want to delete this translation?")
  //       ) {
  //         return;
  //       }

  //       setLoading(true); // Set loading to true while deleting

  //       // Make an API call to delete the translation
  //       const token = localStorage.getItem("token"); // Retrieve token
  //       await axios.delete(`https://aitools.kapinsights.com/project/${id}`, {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       message.success("Project deleted successfully");

  //       // Refresh the list of translations
  //       await fetchData();
  //     } catch (error: any) {
  //       message.error("Failed to delete translation: " + error.message);
  //     } finally {
  //       setLoading(false); // Ensure loading is false after operation
  //     }
  //   };

  // Utility function to validate date format
  const isValidDate = (dateStr: any, format = "MM/DD/YYYY") => {
    return dayjs(dateStr, format).isValid();
  };

  //   const fetchTranslations = async () => {
  //     setLoading(true);
  //     const token = localStorage.getItem("token");
  //     let response = await get<any>("users/translations", {});
  //     if (response.type === "success") {
  //       const fetchedTranslations = response.data.map((item: any) => {
  //         // Validate the date format before adding to state
  //         const isValid = isValidDate(item.date);
  //         if (!isValid) {
  //           console.error(`Invalid date format for item with id ${item.id}`);
  //         }
  //         // setJsonName(item.type_name)
  //         console.log("item::", item);
  //         return {
  //           ...item,
  //           key: item.id,
  //           date: isValid
  //             ? dayjs(item.date).format("MM/DD/YYYY")
  //             : "Invalid date",
  //           actions: (
  //             <Space size="middle" className="actions">
  //               {item.type_name !== "excel" && (
  //                 <CopyOutlined onClick={() => copyTranslation(item.id)} />
  //               )}
  //               <DownloadOutlined
  //                 onClick={() =>
  //                   downloadTranslation(item.id, item.projectname, item.type_name)
  //                 }
  //               />
  //               <DeleteOutlined onClick={() => deleteMember(item.id)} />
  //             </Space>
  //           ),
  //         };
  //       });
  //       setTranslationList(fetchedTranslations);
  //       setFilteredData(fetchedTranslations); // Set filtered data here after fetching
  //       setLoading(false);
  //     }
  //   };
  const handleHideFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchData = async (
    searchTerm = "",
    selectedGender = "",
    selectedCountry = "",
    selectedLanguage = "",
    selectedWorkspace = "",
    selectedRole = ""
  ) => {
    setLoading(true); // Start loading
    try {
      const queryParams = new URLSearchParams({ skip: "0", limit: "10" });

      if (searchTerm) queryParams.append("name", searchTerm);
      if (selectedGender) queryParams.append("gender_id", selectedGender);
      if (selectedCountry) queryParams.append("country_id", selectedCountry);
      if (selectedLanguage) queryParams.append("language_id", selectedLanguage);
      if (selectedWorkspace)
        queryParams.append("workspace_id", selectedWorkspace);
      if (selectedRole) queryParams.append("role_id", selectedRole);

      const response = await get<any>(`member-list?${queryParams.toString()}`);
      console.log("memberListt donika:", response);
      if (response.type === "success") {
        const membersData = response.data.map((member: any) => ({
          key: member.id,
          name: member.username,
          //   email: member.email ?? "",
          //   gender: member.gender ?? "",
          location: member.location ?? "",
          //   languages: member.languages?.join(", ") ?? "",
          role: member.role ?? "",
          workspaces: member.workspaces?.join(",  ") ?? "",
          actions: (
            <Space size="middle">
              <EyeOutlined onClick={() => viewMemberDetails(member.id)} />
              <EditOutlined onClick={() => openModalForEdit(member.id)} />
              <DeleteOutlined onClick={() => deleteMember(member.id)} />
            </Space>
          ),
        }));
        setFilteredData(membersData);
      } else {
        //   message.error(response?.data?.response?.data.detail);
        setErrorInResponse(response?.data?.response?.data.detail);
      }
    } catch (error) {
      console.log("error donikaaaaaaaaaaaaaaa", error);
      message.error("Failed to load member data!");
    } finally {
      setLoading(false); // Stop loading indication
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get<any>("users/translations", {});
  //       const projects = response.data.map((project: any) => ({
  //         key: project.id,
  //         projectname: project.name,
  //         manager: project.manager,
  //         linkedsurveys: project.surveys,
  //         members: project.members,
  //         languages: project.languages.map((lang: any) => lang.name),
  //       }));
  //       setFilteredData(projects);
  //     } catch (error) {
  //       console.error("Error fetching projects:", error);
  //       message.error("Failed to load projects.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  useEffect(() => {
    filterData();
  }, [searchText, typeFilter, dateFilter, translationList]);

  const handleCreate = (values: any) => {
    setVisible(false);
    fetchData();
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const clearAllFilters = () => {
    setSelectedCountry(null);
    // setSelectedGender(null);
    setSelectedWorkspace(null);
    setSelectedRole(null);
    // setSelectedLanguage(null);
    setSearchText("");

    form.resetFields();
    forceUpdate({});

    fetchData();
  };

  return (
    <>
      <div className="user-management-layout" style={{ marginLeft: 0 }}>
        <Content className="content-styles">
          <div className="header-image">
            <img
              src={HeaderOfUserManagement}
              alt="user management"
              style={{ width: "100%" }}
            />
          </div>
          <Form
            form={form}
            className="survey-form-user-management"
            layout="vertical"
            initialValues={{
              gender: "",
              country: "",
              language: "",
              workspace: "",
              role: "",
            }}
          >
            <div className="card">
              <div className="title-button">
                <h2>Workspace members</h2>
                {userRole !== "Guest" && (
                  <div className="reset-filters-button">
                    <Button
                      className="button-style"
                      // disabled={errorInResponse ?? false}
                      onClick={showModalForCreate}
                    >
                      <PlusOutlined /> Add member
                    </Button>
                  </div>
                )}
              </div>
              <p>Here’s the list of the members in your workspace</p>
              <div className="searchManagerCountry-section">
                <div className="searchManagerCity-innerSection">
                  {showFilters && (
                    <div className="search-part">
                      <Form.Item
                        name="search"
                        label="Search"
                        className="title-filter-style"
                      >
                        <Search
                          placeholder="Search member"
                          onSearch={(value) =>
                            fetchData(value, selectedCountry)
                          }
                          style={{ width: "100%" }}
                          enterButton
                          onChange={(e) => setSearch(e.target.value)}
                          allowClear
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div className="buttons">
                    <Button
                      className="clear-all-buttonStyle"
                      onClick={clearAllFilters}
                    >
                      Clear all
                    </Button>

                    <Button
                      className="hide-all-buttonStyle"
                      onClick={handleHideFilters}
                      icon={showFilters ? <UpOutlined /> : <DownOutlined />}
                    >
                      {showFilters ? "Hide filters" : "Show filters"}
                    </Button>
                  </div>
                </div>
                {showFilters && (
                  <>
                    <div className="searchManagerCity-innerSection">
                      <div className="mananger-part">
                        <Form.Item
                          name="gender"
                          label="Gender"
                          className="title-filter-style"
                        >
                          <Select
                            showSearch
                            placeholder="Select a gender"
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value: any) => {
                              setSelectedGender(value);
                              fetchData(search, value);
                            }}
                            allowClear
                          >
                            {genders?.map((gender: any) => (
                              <Option key={gender.id} value={gender.id}>
                                {gender.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="country-part">
                        <Form.Item
                          name="country"
                          label="Country"
                          className="title-filter-style"
                        >
                          <Select
                            showSearch
                            placeholder="Select a country"
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value: any) => {
                              setSelectedCountry(value);
                              form.setFieldsValue({ country: value });
                              fetchData(search, selectedGender, value);
                            }}
                            allowClear
                          >
                            {countries.map((country: any) => (
                              <Option key={country.id} value={country.id}>
                                {country.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="country-part">
                        <Form.Item
                          name="language"
                          label="Language"
                          className="title-filter-style"
                        >
                          <Select
                            showSearch
                            placeholder="Select a language"
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value: any) => {
                              setSelectedLanguage(value);
                              fetchData(
                                search,
                                selectedGender,
                                selectedCountry,
                                value
                              );
                            }}
                            allowClear
                          >
                            {languages.map((language: any) => (
                              <Option key={language.id} value={language.id}>
                                {language.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="searchManagerCity-innerSection">
                      <div className="mananger-part">
                        <Form.Item
                          name="workspace"
                          label="Workspace"
                          className="title-filter-style"
                          style={{ marginBottom: "unset" }}
                        >
                          <Select
                            showSearch
                            placeholder="Select a workspace"
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value: any) => {
                              setSelectedWorkspace(value);
                              fetchData(
                                search,
                                selectedGender,
                                selectedCountry,
                                selectedLanguage,
                                value
                              );
                            }}
                            allowClear
                          >
                            {workspaces?.map((workspace: any) => (
                              <Option key={workspace.id} value={workspace.id}>
                                {workspace.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="country-part">
                        <Form.Item
                          name="role"
                          label="Role"
                          className="title-filter-style"
                          style={{ marginBottom: "unset" }}
                        >
                          <Select
                            showSearch
                            placeholder="Select a role"
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value: any) => {
                              setSelectedRole(value);
                              fetchData(
                                search,
                                selectedGender,
                                selectedCountry,
                                selectedLanguage,
                                selectedWorkspace,
                                value
                              );
                            }}
                            allowClear
                          >
                            {roles.map((role: any) => (
                              <Option key={role.id} value={role.id}>
                                {role.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div>
                {errorInResponse ? (
                  <Typography.Text
                    type="danger"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "20px",
                    }}
                  >
                    {errorInResponse}
                  </Typography.Text>
                ) : (
                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    className="dd"
                    columns={columns}
                    dataSource={filteredData}
                    scroll={{ x: 700 }}
                  />
                )}
              </div>
            </div>
          </Form>
        </Content>
        <MemberModal
          visible={isModalVisible}
          onCancel={handleModalClose}
          onCreate={handleCreate}
          memberId={currentMemberId}
          mode={modalMode}
        />
      </div>
    </>
  );
}

export default MembersList;

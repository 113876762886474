import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Select, message, Spin, Space } from "antd";
import { get, post, put } from "../../../utils/apiUtils";
import axios, { AxiosRequestConfig } from "axios";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getUserRole } from "../../../hooks/getUserRoles";

const { Option } = Select;

interface Role {
  id: number; // or string, depending on your actual data structure
  name: string;
}

function MemberModal({ visible, onCancel, onCreate, memberId, mode }: any) {
  const UserRole = getUserRole();
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState<any>([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [cities, setCities] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  console.log("member id:", memberId);
  // useEffect(() => {
  //   const fetchMemberDetails = async () => {
  //     if (["edit", "view"].includes(mode) && memberId) {
  //       try {
  //         const response = await get<any>(`members/${memberId}/edit-details`);
  //         if (response?.type === "success") {
  //           const { full_name, email, country_id, role, workspaces } =
  //             response.data;
  //           form.setFieldsValue({
  //             fullName: full_name,
  //             email: email,
  //             role: role,
  //             workspace: workspaces,
  //             country: country_id,
  //           });
  //         } else {
  //           console.error("Failed to fetch project details:", response.type);
  //           message.error("Failed to fetch project details: " + response.type);
  //         }
  //       } catch (error: any) {
  //         console.error("Error fetching project details:", error);
  //         message.error("Failed to fetch project details: " + error.message);
  //       }
  //     }
  //   };

  //   fetchMemberDetails();
  // }, [memberId, mode, form]);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (["edit", "view"].includes(mode) && memberId) {
        try {
          const response = await get<any>(`members/${memberId}/edit-details`);
          if (response?.type === "success") {
            const {
              username,
              email,
              country_id,
              city_id,
              workspace_ids,
              role_id,
            } = response.data;

            // Fetch cities for the selected country
            await handleCountryChange(country_id.toString());

            form.setFieldsValue({
              fullName: username,
              email: email,
              country: country_id,
              city: city_id, // Set city_id here, will update to name after cities are loaded
              workspaces: workspace_ids,
              role: role_id,
            });
          } else {
            console.error("Failed to fetch member details:", response.type);
            message.error("Failed to fetch member details: " + response.type);
          }
        } catch (error: any) {
          console.error("Error fetching member details:", error);
          message.error("Failed to fetch member details: " + error.message);
        }
      }
    };

    if (visible) {
      fetchMemberDetails();
    }
  }, [memberId, mode, form, visible]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://aitools.kapinsights.com/countries/"
        );
        console.log("countries response::", response);
        setCountries(response.data); // Assuming response data is the array of countries
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        message.error("Failed to load countries data!");
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        let response;
        if (UserRole === "Project Manager") {
          response = await get<any>("Users/project-roles/");
        } else {
          response = await get<any>("Users/workspace-roles/");
        }

        if (response.type === "success") {
          setRoles(response.data);
        }
      } catch (error) {
        message.error("Failed to load roles data!");
      }
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const response = await get<any>("get-all-workspaces");
        if (response.type === "success") {
          setWorkspaces(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch workspace:", error);
        message.error("Failed to load workspace data!");
      }
    };
    fetchWorkspaces();
  }, []);

  //   useEffect(() => {
  //     const fetchCities = async () => {
  //       try {
  //         const response = await get<any>("cities/");
  //         if (response.type === "success") {
  //           setCities(response.data);
  //         }
  //       } catch (error) {
  //         console.error("Failed to fetch workspace:", error);
  //         message.error("Failed to load workspace data!");
  //       }
  //     };
  //     fetchCities();
  //   }, []);

  // const handleSubmit = async (values: any) => {
  //   setLoading(true);

  //   if (mode === "edit") {
  //     try {
  //       const response = await put<any>(`members/${memberId}/edit`, {
  //         username: values.fullName,
  //         email: values.email,
  //         role_id: values.role,
  //         workspace_ids: values.workspace,
  //         country_id: values.country,
  //         city_id: values.city,
  //       });
  //       if (response.type === "success") {
  //         message.success("Member updated successfully!");
  //         onCreate(values);
  //         setLoading(false);
  //       } else {
  //         message.error(response.type);
  //       }
  //     } catch (error: any) {
  //       message.error("Failed to update member: " + error.message);
  //     }
  //   } else {
  //     try {
  //       const response = await post<any>("Users/add-member/", {
  //         username: values.fullName,
  //         email: values.email,
  //         role_id: values.role,
  //         workspace_ids: values.workspace,
  //         country_id: values.country,
  //         city_id: values.city,
  //       });
  //       if (response?.type === "success") {
  //         message.success("Member created successfully!");
  //         form.resetFields();
  //         onCreate(values);
  //         setLoading(false);
  //       }
  //     } catch (error: any) {
  //       message.error("Failed to create member: " + error.message);
  //     }
  //   }
  //   form.resetFields();
  //   onCancel(); // Close modal after submission
  // };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    // Define payload structure common to both edit and create operations
    // const workspaceRoles = values.workspacesRoles.map((wr: any) => ({
    //   workspace_id: wr.workspace,
    //   role_id: wr.role,
    //   role_name: roles.find((r: any) => r.id === wr.role)?.name || "", // Ensure you have 'roles' data available
    // }));

    // const payload = {
    //   username: values.fullName,
    //   email: values.email,
    //   country_id: values.country,
    //   city_id: values.city,
    //   workspace_roles: workspaceRoles,
    // };
    console.log("values.workspaces", values.workspaces);
    const payload = {
      username: values.fullName,
      email: values.email,
      country_id: values.country,
      city_id: values.city,
      workspace_ids: values.workspaces, // Assume workspaces field contains an array of workspace IDs
      role_id: values.role, // Single role ID
    };

    try {
      let response;
      if (mode === "edit") {
        // Update member
        response = await put<any>(`members/${memberId}/edit`, payload);
      } else {
        // Create new member
        response = await post<any>("Users/add-member/", payload);
      }

      // Handle response
      if (response?.type === "success") {
        message.success(
          `${
            mode === "edit" ? "Member updated" : "Member created"
          } successfully!`
        );
        onCreate(values);
        form.resetFields();
      } else {
        message.error(
          `Failed to ${mode === "edit" ? "update" : "create"} member: ${
            response.type
          }`
        );
      }
    } catch (error: any) {
      message.error(
        `Failed to ${mode === "edit" ? "update" : "create"} member: ${
          error.message
        }`
      );
    } finally {
      setLoading(false); // Ensure loading is set to false regardless of the outcome
      onCancel(() => {
        form.resetFields();
        // Additional state reset logic if necessary
      });
    }
  };
  useEffect(() => {
    if (visible && mode === "create") {
      form.resetFields();
      // Reset any other related state or selections
    }
  }, [visible, mode, form]);
  // const handleSubmit = async (values: any) => {
  //   console.log("Form Values:", values);
  //   setLoading(true);

  //   const payload = {
  //     username: values.fullName,
  //     email: values.email,
  //     role_id: values.role,
  //     workspace_ids: values.workspace,
  //     country_id: values.country,
  //   };
  //   let headers: AxiosRequestConfig["headers"] = {
  //     "Content-Type": "application/json",
  //   };

  //   try {
  //     const response = await post<any>("Users/add-member/", payload, {
  //       headers,
  //     });
  //     console.log("response in adding member", response);

  //     if (response.type === "success") {
  //       message.success("New member added successfully!");
  //       form.resetFields();
  //       onCreate(values);
  //       setLoading(false);
  //     } else {
  //       message.error(`Error: ${response.data.message}`);
  //       setLoading(false);
  //     }
  //   } catch (error: any) {
  //     console.error("Failed to add member:", error);
  //     message.error(`Failed to add member: ${error.message || error}`);
  //   }
  // };
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        workspacesRoles: [
          { workspace: undefined, role: undefined }, // Vlerat paraprake, mund t'i ndryshoni në bazë të nevojave
        ],
      });
    }
  }, [visible, form]);

  const handleCountryChange = async (countryId: string) => {
    form.setFieldsValue({ city: undefined }); // Reset city
    try {
      const response = await get<any>(`countries/${countryId}/cities/`);
      if (response.type === "success") {
        setCities(response.data);

        if (mode === "edit") {
          // Set city name in form after cities have been loaded
          const memberCity = response.data.find(
            (c: any) => c.id === form.getFieldValue("city")
          );
          form.setFieldsValue({
            city: memberCity ? memberCity.name : undefined,
          });
        }
      } else {
        setCities([]);
        message.error("Failed to load cities!");
      }
    } catch (error) {
      console.error("Failed to fetch cities:", error);
      message.error("Failed to load cities!");
      setCities([]); // Clear cities on error
    }
  };

  // const handleCountryChange = async (countryId: string) => {
  //   form.setFieldsValue({ city: undefined }); // Reset cities dropdown when country changes
  //   try {
  //     const response = await get<any>(`countries/${countryId}/cities/`);
  //     if (response.type === "success") {
  //       setCities(response.data);
  //     } else {
  //       setCities([]);
  //       message.error("Failed to load cities!");
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch cities:", error);
  //     message.error("Failed to load cities!");
  //     setCities([]); // Clear cities on error
  //   }
  // };

  return (
    <Modal
      visible={visible}
      title={
        mode === "edit"
          ? "Edit member information"
          : mode === "view"
          ? "View member"
          : "New member"
      }
      // okText={
      //   loading ? (
      //     <Spin size="small" style={{ color: "white" }} />
      //   ) : (
      //     "Add New Member"
      //   )
      // }
      okText={mode === "edit" ? "Save changes" : "Add new member"}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: loading }}
      className="user-management-modal"
      footer={
        mode === "view"
          ? [
              <Button key="back" onClick={onCancel}>
                Close
              </Button>,
            ]
          : undefined
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="new_member_form"
        className="form-style"
      >
        <div style={{ marginTop: "2rem" }}>
          <label className="personal-information">Personal information</label>
        </div>
        <div className="first-section-modal">
          <Form.Item
            name="fullName"
            label="Full name"
            rules={[{ required: true, message: "Please input the full name!" }]}
            style={{ width: "100%" }}
            className="title-style"
          >
            <Input disabled={mode === "view"} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input the email address!",
                type: "email",
              },
            ]}
            style={{ width: "100%" }}
            className="title-style"
          >
            <Input disabled={mode === "view"} />
          </Form.Item>
        </div>

        <Form.Item
          name="workspaces"
          label="Select Workspaces"
          rules={[
            {
              required: true,
              message: "Please select at least one workspace!",
            },
          ]}
          style={{ width: "100%" }}
        >
          <Select
            disabled={mode === "view"}
            mode="multiple"
            placeholder="Select workspaces"
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {workspaces.map((workspace: any) => (
              <Option key={workspace.id} value={workspace.id}>
                {workspace.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="role"
          label="Select Role"
          rules={[{ required: true, message: "Please select a role!" }]}
          style={{ width: "100%" }}
        >
          <Select
            disabled={mode === "view"}
            placeholder="Select a role"
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {roles.map((role: any) => (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.List name="workspacesRoles">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field: any) => (
                <Space
                  key={field.key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                    background: "#fbe3f3",
                    padding: "1rem 1rem 0",
                  }}
                  align="baseline"
                >
                  <div className="second-section-modal">
                    {/* <Form.Item
                      {...field}
                      name={[field.name, "workspace"]}
                      fieldKey={[field.fieldKey, "workspace"]}
                      rules={[{ required: true, message: "Missing workspace" }]}
                      className="workspace-item"
                    >
                      <Select placeholder="Select workspace">
                        {workspaces.map((workspace: any) => (
                          <Option key={workspace.id} value={workspace.id}>
                            {workspace.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "role"]}
                      fieldKey={[field.fieldKey, "role"]}
                      rules={[{ required: true, message: "Missing role" }]}
                      className="role-item"
                    >
                      <Select placeholder="Select role">
                        {roles.map((role: any) => (
                          <Option key={role.id} value={role.id}>
                            {role.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item> *
                    <Form.Item
                      {...field}
                      name={[field.name, "workspace"]}
                      fieldKey={[field.fieldKey, "workspace"]}
                      label="Workspace" // Added label
                      rules={[{ required: true, message: "Missing workspace" }]}
                      className="workspace-item"
                    >
                      <Select
                        placeholder="Select workspace"
                        dropdownMatchSelectWidth={false}
                      >
                        {workspaces.map((workspace: any) => (
                          <Option key={workspace.id} value={workspace.id}>
                            {workspace.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "role"]}
                      fieldKey={[field.fieldKey, "role"]}
                      label="Role" // Added label
                      rules={[{ required: true, message: "Missing role" }]}
                      className="role-item"
                    >
                      <Select placeholder="Select role">
                        {roles.map((role: any) => (
                          <Option key={role.id} value={role.id}>
                            {role.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                className="enroll-button-style"
              >
                Enroll to another workspace
              </Button>
            </>
          )}
        </Form.List> */}

        <label className="personal-information">Location</label>
        <div className="third-section-modal">
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Please select a country!" }]}
            style={{ width: "100%" }}
            className="title-style"
          >
            <Select
              showSearch
              placeholder="Select your country"
              onChange={handleCountryChange} // Add this handler
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={mode === "view"}
            >
              {countries.map((country: any) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="city"
            label="City/Village"
            style={{ width: "100%" }}
            className="title-style"
          >
            <Select
              showSearch
              placeholder="Select a city"
              disabled={!form.getFieldValue("country") || mode === "view"}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((city: any) => (
                <Option key={city.id} value={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

export default MemberModal;

import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Select, message } from "antd";
import { get, post, put } from "../../../utils/apiUtils";
import TextArea from "antd/es/input/TextArea";
import { getUserRole } from "../../../hooks/getUserRoles";

const { Option } = Select;

function WorkspaceModal({
  visible,
  onCancel,
  onCreate,
  workspaceId,
  mode,
}: any) {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const userRole = getUserRole();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await get<any>("countries/");
        setCountries(response.data);
      } catch (error) {
        message.error("Failed to load countries data!");
      }
    };
    fetchCountries();
  }, []);

  const fetchWorkspaceDetails = async () => {
    if (["edit", "view"].includes(mode) && workspaceId) {
      try {
        const response = await get<any>(`get-workspace-by-id/${workspaceId}`);
        if (response?.type === "success") {
          const { name, description, organization_id, country_ids } =
            response.data;
          form.setFieldsValue({
            name,
            description,
            organization_id,
            country_ids,
          });
        } else {
          message.error("Failed to fetch project details: " + response.type);
        }
      } catch (error: any) {
        message.error("Failed to fetch project details: " + error.message);
      }
    }
  };

  useEffect(() => {
    console.log("calling the mode", mode);

    fetchWorkspaceDetails();
  }, [workspaceId, mode, form]);

  let organization_id = localStorage.getItem("organizationId");
  console.log("orgId:", organization_id);
  console.log("mode:", mode);
  const handleSubmit = async (values: any) => {
    setLoading(true);

    if (mode === "edit") {
      try {
        const response = await put<any>(`workspaces/${workspaceId}`, {
          name: values.name,
          description: values.description,
          country_ids: values.country_ids,
        });
        if (response.type === "success") {
          message.success("Workspace updated successfully!");
          onCreate(values);
          setLoading(false);
          fetchWorkspaceDetails();
        } else {
          message.error(response.type);
        }
      } catch (error: any) {
        message.error("Failed to update project: " + error.message);
      }
    } else {
      try {
        const response = await post<any>(
          // `workspaces/?organization_id=${organization_id}&country_id=${values.country_id}`,
          `workspaces/?organization_id=${organization_id}`,
          {
            name: values.name,
            description: values.description,
            country_ids: values.country_ids,
          }
        );
        if (response?.type === "success") {
          message.success("Workspace created successfully!");
          form.resetFields();
          onCreate(values);
          setLoading(false);
        }
      } catch (error: any) {
        message.error("Failed to create workspace: " + error.message);
      }
    }
    form.resetFields();
    onCancel(() => {
      form.resetFields();
    });
  };
  useEffect(() => {
    if (visible && mode === "create") {
      form.resetFields();
      // Reset any other related state or selections
    }
  }, [visible, mode, form]);
  console.log("Modal visible:", visible, "Mode:", mode);

  return (
    <Modal
      visible={visible}
      title={
        mode === "edit"
          ? "Edit Workspace"
          : mode === "view"
          ? "View Workspace"
          : "Add new workspace"
      }
      okText={mode === "edit" ? "Save changes" : "Add workspace"}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: loading }}
      footer={
        mode === "view"
          ? [
              <Button key="back" onClick={onCancel}>
                Close
              </Button>,
            ]
          : undefined
      }
      className="addEdit-workspace-modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label="Workspace name"
          rules={[
            {
              required: true,
              message: "Please input the name of the workspace!",
            },
          ]}
        >
          <Input placeholder="Enter workspace name" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} placeholder="Describe the workspace" />
        </Form.Item>
        <Form.Item
          name="country_ids"
          label="Workspace countries"
          rules={[
            { required: true, message: "Please select at least one country!" },
          ]}
        >
          <Select
            showSearch
            placeholder="Select your country"
            mode="multiple"
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries.map((country: any) => (
              <Option key={country.id} value={country.id}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default WorkspaceModal;

// import { Content } from "antd/es/layout/layout";
// import React, { useState } from "react";
// import SurveyBuilderHeader from "../../assets/img/surveyBuilderHeader.png";
// import { Button, Spin, Input, message } from "antd";
// import { SaveOutlined } from "@ant-design/icons";
// import { getUserRole } from "../../hooks/getUserRoles";
// import axios from "axios"; // Assuming you are using axios for API calls
// import { post } from "../../utils/apiUtils";
// import { useNavigate } from "react-router-dom";

// const UploadSurveyPage = () => {
//   const userRole = getUserRole();
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoadingForSave, setIsLoadingForSave] = useState(false);
//   const [surveyType, setSurveyType] = useState("");
//   const [file, setFile] = useState(null);

//   const handleFileChange = (e: any) => {
//     setFile(e.target.files[0]);
//     console.log("File selected:", e.target.files[0]); // Logging the selected file
//   };

//   console.log("file:", file);
//   const handleSave = async () => {
//     if (!file || !surveyType) {
//       alert("Please select a file and enter survey type.");
//       console.log("Missing file or survey type:", { file, surveyType });

//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append("survey_type", "string");

//     try {
//       setIsLoading(true);
//       setIsLoadingForSave(true);

//       const response = await post<any>("upload-survey", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       if (response.type === "success") {
//         message.success("Survey uploaded successfully");
//         navigate("/survey-builder");
//       } else {
//         message.success("Failed to upload survey");
//       }
//     } catch (error) {
//       console.error("Error uploading survey:", error);
//       alert("An error occurred while uploading the survey");
//     } finally {
//       setIsLoading(false);
//       setIsLoadingForSave(false);
//     }
//   };

//   return (
//     <div className="survey-builder-layout" style={{ marginLeft: 0 }}>
//       <Content
//         className="site-layout-background ddd"
//         style={{
//           padding: "0 24",
//           margin: 0,
//           minHeight: 280,
//         }}
//       >
//         <div className="header-image-inner-container">
//           <img
//             src={SurveyBuilderHeader}
//             alt="Survey Builder"
//             style={{ width: "100%" }}
//           />
//         </div>
//         <div
//           style={{
//             border: "2px solid #d9e3ff",
//             borderRadius: "8px",
//             padding: "16px",
//           }}
//         >
//           <div className="survey-header-forUpload">
//             <div className="input-container">
//               <Input
//                 type="file"
//                 onChange={handleFileChange}
//                 style={{ marginBottom: "16px" }}
//               />
//             </div>
//             <div className="buttons">
//               <Button
//                 icon={<SaveOutlined />}
//                 className="save-button"
//                 type="primary"
//                 style={{
//                   backgroundColor: "#A072EB",
//                   color: "white",
//                   border: "unset !important",
//                 }}
//                 onClick={handleSave}
//                 disabled={isLoading || userRole === "Guest"}
//               >
//                 {isLoadingForSave ? <Spin /> : "Save the survey"}
//               </Button>
//             </div>
//           </div>
//         </div>
//       </Content>
//     </div>
//   );
// };

// export default UploadSurveyPage;

import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import SurveyBuilderHeader from "../../assets/img/surveyBuilderHeader.png";
import { Button, Spin, Input, message, Typography, Upload } from "antd";
import { InboxOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { getUserRole } from "../../hooks/getUserRoles";
import axios from "axios"; // Assuming you are using axios for API calls
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../utils/apiUtils";

const { Title } = Typography;
const { Dragger } = Upload;

const UploadSurveyPage = () => {
  const { id: projectId } = useParams();
  const userRole = getUserRole();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForSave, setIsLoadingForSave] = useState(false);
  const [surveyType, setSurveyType] = useState("string");
  const [fileList, setFileList] = useState<any>([]);

  const handleFileChange = (info: any) => {
    const { file, fileList } = info;

    if (file.status !== "uploading") {
      console.log(file, fileList);
    }

    if (file.status === "done") {
      message.success(`${file.name} file selected successfully.`);
    } else if (file.status === "error") {
      message.error(`${file.name} file selection failed.`);
    }

    const newFileList = fileList.map((file: any) => ({
      uid: file.uid,
      name: file.name,
      status: file.status,
      originFileObj: file.originFileObj || file,
    }));

    setFileList(newFileList);
  };

  const handleSave = async () => {
    if (fileList.length === 0 || !surveyType) {
      alert("Please select a file and enter survey type.");
      console.log("Missing file or survey type:", { fileList, surveyType });
      return;
    }

    const fileToUpload = fileList[0].originFileObj;
    const formData = new FormData();
    formData.append("file", fileToUpload);
    formData.append("survey_type", surveyType);
    if (projectId) {
      formData.append("project_id", projectId);
    } else {
      // Handle the case where projectId is undefined
      throw new Error("Project ID is undefined");
    }
    try {
      setIsLoading(true);
      setIsLoadingForSave(true);

      const response = await post<any>("upload-survey/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.type === "success") {
        message.success("Survey uploaded successfully");
        setFileList([]);
        setSurveyType("");
        // navigate(`/survey-builder/${projectId}`);
      } else {
        message.error("Failed to upload survey");
      }
    } catch (error) {
      console.error("Error uploading survey:", error);
      alert("An error occurred while uploading the survey");
    } finally {
      setIsLoading(false);
      setIsLoadingForSave(false);
    }
  };

  const handleRedirectToSurveyBuilder = () => {
    navigate(`/survey-builder/${projectId}`);
  };
  return (
    <div className="survey-builder-layout" style={{ marginLeft: 0 }}>
      <Content
        className="site-layout-background ddd"
        style={{
          padding: "0 24",
          margin: 0,
          minHeight: 280,
        }}
      >
        <div className="header-image-inner-container">
          <img
            src={SurveyBuilderHeader}
            alt="Survey Builder"
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            // border: "2px solid #d9e3ff",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <div className="survey-header-forUpload">
            <div
              className="first-section-info"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
                marginBottom: "1rem",
              }}
            >
              <Title
                level={5}
                style={{
                  marginBottom: "16px",
                  color: "rgba(0, 0, 0, 0.45)",
                  marginTop: "unset",
                }}
              >
                Please upload your survey here{" "}
              </Title>
              <Button
                style={{
                  color: "#4a1c96",
                  backgroundColor: "unset",
                  border: "unset",
                  boxShadow: "unset",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                onClick={handleRedirectToSurveyBuilder}
              >
                Go to survey builder
              </Button>
            </div>

            <Dragger
              name="file"
              multiple={false}
              beforeUpload={(file: any) => {
                const isSupported = true; // Add your file type validation logic here
                if (!isSupported) {
                  message.error(`${file.name} is not a valid file.`);
                  return Upload.LIST_IGNORE;
                }
                setFileList([file]);
                return false;
              }}
              onChange={handleFileChange}
              fileList={fileList}
              accept=".xls,.xlsx"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "#9B51E0" }} />
              </p>
              <p className="ant-upload-text">
                Drag and drop files here, or click to select files
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files.
              </p>
            </Dragger>
            <div className="buttons-forUpload">
              <Button
                icon={<SaveOutlined />}
                className="save-button-upload"
                onClick={handleSave}
                disabled={isLoading || userRole === "Guest"}
              >
                {isLoadingForSave ? <Spin /> : "Upload the survey"}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default UploadSurveyPage;

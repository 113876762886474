import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUserRole } from "../hooks/getUserRoles";

const ProtectedRoute = ({
  allowedRoles,
  redirectPath = "/unauthorized",
}: any) => {
  const userRole = getUserRole();

  if (allowedRoles.includes(userRole)) {
    return <Outlet />;
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};

export default ProtectedRoute;

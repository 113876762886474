import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Card,
  Radio,
  Collapse,
  Spin,
  message,
  DatePicker,
  Checkbox,
  InputNumber,
  Row,
  Col,
} from "antd";
import "./index.css";
import { EyeOutlined, SaveOutlined, ExportOutlined } from "@ant-design/icons";
import TopBar from "../Topbar/Header";
import SurveyBuilderHeader from "../../assets/img/surveyBuilderHeader.png";
import { LanguageType } from "../AITools/SurveyGenerator/GenerateSurvey";
import { get, post } from "../../utils/apiUtils";
import axios, { AxiosRequestConfig } from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDrop from "../../assets/img/drag-and-drop.png";
import { getUserRole } from "../../hooks/getUserRoles";
import { useParams } from "react-router-dom";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { Group: RadioGroup } = Radio;
const { Panel } = Collapse;

type Language = {
  id: string;
  code: string;
  name: string;
};
type SubcategoryType = {
  id: string;
  subcategoryid: string;
  name: string;
};
type QuestionTypes = {
  id: string;
  questiontype: string;
};
interface ApiResponse {
  data: {
    questions_by_subcategory: {
      [key: string]: {
        subcategory_label: string;
        category_name: string;
        questions: Question[];
      };
    };
  };
}

interface Question {
  category: string;
  category_name: string;
  subcategory_name: string;
  subcategory_label_name: string;
  language_name: string;
  question_label_text: string;
  question_name: string;
  question_type: string;
  question_id: string;
  choice_listname: string[];
  choice_names: string[];
  choice_label_texts: string[];
  tags: string[];
  question_hint: string | null;
}

interface CategoryData {
  subcategory_label: string;
  category_name: string;
  questions: Question[];
}

interface Categories {
  [key: string]: CategoryData[];
}

// interface ApiResponse {
//   data: {
//     questions: Question[]; // Define `Question` based on the structure you expect
//   };
// }

// interface Question {
//   // Define properties of Question based on your actual API response
//   question_id: string;
//   question_label_text: string;
//   choice_label_texts: string[];
//   isAdded: boolean;
// }

const SurveyBuilder: React.FC = () => {
  const { id: projectId } = useParams();

  const userRole = getUserRole();

  const [supportedLanguages, setSupportedLanguages] = useState<LanguageType[]>(
    []
  );
  const [language, setLanguage] = useState<Language>();
  const [subcategoryLabels, setSubcategoryLabels] = useState<SubcategoryType[]>(
    []
  );
  const [subcategory, setSubcategory] = useState<SubcategoryType>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [categories, setCategories] = useState<any>({});
  const [questionTypes, setQuestionTypes] = useState<QuestionTypes[]>([]);
  const [questionType, setQuestionType] = useState<QuestionTypes>();
  const [isOpen, setIsOpen] = useState(true);
  const [selectIsOpen, setSelectIsOpen] = useState(true);
  const [availableQuestions, setAvailableQuestions] = useState<Question[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [surveyId, setSurveyId] = useState();
  const [questionLabelLike, setQuestionLabelLike] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [isLoadingForSave, setIsLoadingForSave] = useState(false);
  const [isLoadingForExport, setIsLoadingForExport] = useState(false);
  const [isHide, setIsHide] = useState(false);
  //for infitine scroll
  // const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);
  const isLoadingRef = useRef(false);

  const [hasMore, setHasMore] = useState(true);
  // const toggleCollapse = () => setIsOpen(!isOpen);
  const toggleCollapse = (questionId: any) => {
    setIsOpen(isOpen === questionId ? null : questionId);
  };
  const toggleSelectedCollapse = (questionId: any) => {
    setSelectIsOpen(selectIsOpen === questionId ? null : questionId);
  };
  const handleLanguageChange = (value: string) => {
    // console.log("value", value);

    const selectedLanguage = supportedLanguages.find(
      (lang) => lang.code === value
    );
    // console.log("selectedLanguage", selectedLanguage);

    if (selectedLanguage) {
      // console.log("language", language);

      setLanguage(selectedLanguage);
    }
  };

  const handleQuestionLabelLikeChange = (event: any) => {
    setQuestionLabelLike(event.target.value);
  };
  const handleSubcategoryChange = (value: string) => {
    const selectedSubcategory = subcategoryLabels?.find(
      (lang) => lang.subcategoryid === value
    );
    if (selectedSubcategory) {
      setSubcategory(selectedSubcategory); // Store the language ID when the language is changed
    }
  };
  // console.log("subcategory selecyted:::", subcategory);
  const handleQuestionTypeChange = (value: string) => {
    const selecteQuestionTypey = questionTypes.find(
      (lang) => lang.questiontype === value
    );
    if (selecteQuestionTypey) {
      setQuestionType(selecteQuestionTypey); // Store the language ID when the language is changed
    }
  };
  // const fetchFilteredQuestions = async () => {
  //   if (!hasMore) return; // Stop fetching if no more data is available
  //   setIsLoading(true);
  //   try {
  //     const params = {
  //       page: currentPage,
  //       page_size: 10,
  //       language_id: language?.id ?? "ecfb845a-86f0-476c-a4df-f101d35a96f9",
  //       subcategory_id: subcategory?.subcategoryid,
  //       question_type_id: questionType?.id,
  //       question_label_like: questionLabelLike,
  //       // Include other parameters as necessary
  //     };
  //     // Filter out undefined or null values
  //     const filteredParams = Object.fromEntries(
  //       Object.entries(params).filter(([_, v]) => v != null)
  //     );
  //     const response = await get<any>("get-questions", {
  //       params: filteredParams,
  //     });

  //     // console.log("response questions:", response);
  //     setCategories(response?.data?.questions_by_subcategory);
  //     if (response.type) {
  //       setQuestions(response.data.questions_by_subcategory);
  //       setIsLoading(false);

  //       setAvailableQuestions(response.data.questions);
  //     }
  //   } catch (error) {
  //     console.error("Gabim gjatë marrjes së pyetjeve:", error);
  //     setIsLoading(false);
  //   }
  // };
  // const fetchFilteredQuestions = useCallback(async () => {
  //   if (!hasMore) return; // Stop fetching if no more data is available
  //   setIsLoading(true);

  //   try {
  //     const params = {
  //       page: currentPage,
  //       page_size: 10,
  //       language_id: language?.id ?? "ecfb845a-86f0-476c-a4df-f101d35a96f9",
  //       subcategory_id: subcategory?.subcategoryid,
  //       question_type_id: questionType?.id,
  //       question_label_like: questionLabelLike,
  //     };

  //     const response = await get<any>("get-questions", { params });

  //     if (
  //       response.data.questions_by_subcategory &&
  //       Object.keys(response.data.questions_by_subcategory).length > 0
  //     ) {
  //       setCategories((prev: any) => ({
  //         ...prev,
  //         ...response.data.questions_by_subcategory,
  //       }));
  //       setCurrentPage((prevPage) => prevPage + 1); // Increment page number
  //     } else {
  //       setHasMore(false); // No more data available
  //     }
  //   } catch (error) {
  //     console.error("Error fetching questions:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, [
  //   currentPage,
  //   hasMore,
  //   language,
  //   subcategory,
  //   questionType,
  //   questionLabelLike,
  // ]);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Checks if the user is near the bottom of the page
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop >=
  //       document.documentElement.offsetHeight - 200
  //     ) {
  //       fetchFilteredQuestions();
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [fetchFilteredQuestions]); // Ensure fetchFilteredQuestions is stable via useCallback if it has dependencies

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Checks if the user is near the bottom of the page
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop >=
  //       document.documentElement.offsetHeight - 200
  //     ) {
  //       fetchFilteredQuestions();
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [fetchFilteredQuestions]);

  const fetchFilteredQuestions = useCallback(async () => {
    // console.log("in fetchFilteredQuestions");

    if (!hasMore || isLoadingRef.current) return;
    isLoadingRef.current = true;
    setIsLoadingQuestions(true);
    const currentPage = currentPageRef.current;
    // console.log("the language in the fetchFilteredQuestions", language);
    // console.log("currentPageeeee::", currentPage);
    const params = {
      page: currentPage,
      page_size: 10,
      language_id: language?.id ?? "ecfb845a-86f0-476c-a4df-f101d35a96f9",
      question_label_like: questionLabelLike,
      subcategory_id: subcategory?.subcategoryid,
      question_type_id: questionType?.id,
    };

    try {
      const response = await get<any>("get-questions", { params });
      // if (response.data.questions_by_subcategory) {
      //   if (currentPage === 1) {
      //     setCategories(response.data.questions_by_subcategory);
      //   } else {
      //     setCategories((prev: any) => ({
      //       ...prev,
      //       ...response.data.questions_by_subcategory,
      //     }));
      //   }
      // }
      // setHasMore(
      //   Object.keys(response.data.questions_by_subcategory).length > 0
      // );
      // if (response.data.questions_by_subcategory.length !== 0) {
      //   setCurrentPage((prev) => prev + 1);
      // }
      if (response.data.questions_by_subcategory) {
        if (currentPage === 1) {
          setCategories(response.data.questions_by_subcategory);
        } else {
          setCategories((prev: any) => ({
            ...prev,
            ...response.data.questions_by_subcategory,
          }));
        }
        setHasMore(
          Object.keys(response.data.questions_by_subcategory).length > 0
        );
        if (Object.keys(response.data.questions_by_subcategory).length !== 0) {
          currentPageRef.current += 1;
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      isLoadingRef.current = false;
      setIsLoadingQuestions(false);
    }
  }, [
    hasMore,
    // isLoadingQuestions,
    language,
    subcategory,
    questionType,
    questionLabelLike,
  ]);

  const fetchInitialFilteredQuestions = useCallback(async () => {
    // console.log("in fetchInitialFilteredQuestions");

    setCategories({});

    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    setIsLoadingQuestions(true);
    currentPageRef.current = 1;
    const currentPage = currentPageRef.current;

    const params = {
      page: 1,
      page_size: 10,
      language_id: language?.id ?? "ecfb845a-86f0-476c-a4df-f101d35a96f9",
      question_label_like: questionLabelLike,
      subcategory_id: subcategory?.subcategoryid,
      question_type_id: questionType?.id,
    };

    try {
      const response = await get<any>("get-questions", { params });
      // if (response.data.questions_by_subcategory) {
      //   if (currentPage === 1) {
      //     setCategories(response.data.questions_by_subcategory);
      //   } else {
      //     setCategories((prev: any) => ({
      //       ...prev,
      //       ...response.data.questions_by_subcategory,
      //     }));
      //   }
      // }
      // setHasMore(
      //   Object.keys(response.data.questions_by_subcategory).length > 0
      // );
      // if (response.data.questions_by_subcategory.length !== 0) {
      //   setCurrentPage((prev) => prev + 1);
      // }
      if (response.data.questions_by_subcategory) {
        if (currentPage === 1) {
          setCategories(response.data.questions_by_subcategory);
        } else {
          setCategories((prev: any) => ({
            ...prev,
            ...response.data.questions_by_subcategory,
          }));
        }
        setHasMore(
          Object.keys(response.data.questions_by_subcategory).length > 0
        );
        if (Object.keys(response.data.questions_by_subcategory).length !== 0) {
          currentPageRef.current += 1;
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      isLoadingRef.current = false;
      setIsLoadingQuestions(false);
    }
  }, [
    hasMore,
    // isLoadingQuestions,
    language,
    subcategory,
    questionType,
    questionLabelLike,
  ]);

  // const fetchLanguageChangedQuestions = async () => {
  //   if (!hasMore) return;
  //   setCategories({});
  //   setIsLoadingQuestions(true);
  //   const currentPage = currentPageRef.current;
  //   console.log("currentPage", currentPage);

  //   if (currentPage > 0) {
  //     for (let index = 1; index < currentPage; index++) {
  //       console.log("index", index);
  //       console.log("currentPageeeee::", currentPage);

  //       const params = {
  //         page: index,
  //         page_size: 10,
  //         language_id: language?.id ?? "ecfb845a-86f0-476c-a4df-f101d35a96f9",
  //         subcategory_id: subcategory?.subcategoryid,
  //         question_type_id: questionType?.id,
  //         question_label_like: questionLabelLike,
  //       };

  //       try {
  //         const response = await get<any>("get-questions", { params });
  //         if (response.data.questions_by_subcategory) {
  //           if (index === 1) {
  //             setCategories(response.data.questions_by_subcategory);
  //           } else {
  //             setCategories((prev: any) => ({
  //               ...prev,
  //               ...response.data.questions_by_subcategory,
  //             }));
  //           }
  //           setHasMore(
  //             Object.keys(response.data.questions_by_subcategory).length > 0
  //           );
  //         }
  //       } catch (error) {
  //         console.error("Error fetching questions:", error);
  //       } finally {
  //       }
  //     }
  //   }
  //   setIsLoadingQuestions(false);
  // };

  const fetchLanguageChangedQuestions = async () => {
    // console.log("in fetchLanguageChangedQuestions");

    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    setCategories({});
    setIsLoadingQuestions(true);
    const currentPage = currentPageRef.current;
    // console.log("currentPage", currentPage);
    // console.log("here below");

    if (currentPage > 0) {
      let tempPage = 1;
      let tempPageSize = (currentPage - 1) * 10;
      // console.log("tempPage", tempPage);
      // console.log("currentPage", currentPage);
      // console.log("tempPageSize", tempPageSize);

      const params = {
        page: tempPage,
        page_size: tempPageSize,
        language_id: language?.id ?? "ecfb845a-86f0-476c-a4df-f101d35a96f9",
        subcategory_id: subcategory?.subcategoryid,
        question_type_id: questionType?.id,
        question_label_like: questionLabelLike,
      };

      try {
        const response = await get<any>("get-questions", { params });
        if (response.data.questions_by_subcategory) {
          setCategories(response.data.questions_by_subcategory);
        }
        setHasMore(
          Object.keys(response.data.questions_by_subcategory).length > 0
        );
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        isLoadingRef.current = false;
        setIsLoadingQuestions(false);
      }
    }
  };

  // console.log("subcategory?.subcategoryid", subcategory?.subcategoryid);
  useEffect(() => {
    const handleScroll = () => {
      const nearBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 200;
      if (nearBottom && hasMore && !isLoadingRef.current) {
        fetchFilteredQuestions();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    fetchFilteredQuestions,
    hasMore,
    // , isLoadingQuestions
  ]);

  // useEffect(() => {
  //   const resetAndFetchData = () => {
  //     setCurrentPage(1); // Reset pagination
  //     setHasMore(true); // Assume more data is available
  //     setCategories({}); // Reset current categories
  //     fetchFilteredQuestions(); // Fetch new data with current filters
  //   };

  //   resetAndFetchData();
  // }, [language, subcategory, questionType, questionLabelLike]); // Dependencies

  // useEffect(() => {
  //   fetchFilteredQuestions(); // Initial fetch
  // }, []);
  useEffect(() => {
    // setIsLoading(true); // Set loading true initially
    fetchFilteredQuestions().then(() => {
      // setIsLoading(false); // Set loading false once data is fetched
    });
  }, []);

  useEffect(() => {
    // Fetch the supported languages from the endpoint when the component mounts
    const fetchSupportedLanguages = async () => {
      try {
        const response = await get("languages/", {});
        setSupportedLanguages(response.data as LanguageType[]);
      } catch (error) {
        console.error(
          "There was an error fetching the supported languages:",
          error
        );
      }
    };
    const fetchSubcategories = async () => {
      try {
        const response = await get<any>("subcategorylabels/", {});
        // console.log("response of category:", response);
        setSubcategoryLabels(response.data?.labels as SubcategoryType[]);
      } catch (error) {
        console.error(
          "There was an error fetching the supported languages:",
          error
        );
      }
    };
    const fetchQuestionType = async () => {
      try {
        const response = await get("question-types", {});
        if (Array.isArray(response.data)) {
          setQuestionTypes(response.data as QuestionTypes[]);
        } else {
          console.error(
            "Expected an array for question types, but received:",
            response.data
          );
        }
      } catch (error) {
        console.error("There was an error fetching the question types:", error);
      }
      //   setQuestionTypes(response.data as QuestionTypes[]);
      // } catch (error) {
      //   console.error(
      //     "There was an error fetching the supported languages:",
      //     error
      //   );
      // }
    };

    // fetchFilteredQuestions();
    fetchSupportedLanguages();
    fetchSubcategories();
    fetchQuestionType();
  }, []);

  // useEffect(
  //   () => {
  //     fetchFilteredQuestions();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },
  //   [
  //     // language,
  //     // subcategory,
  //     // questionType,
  //     // questionLabelLike,
  //   ]
  // );
  useEffect(() => {
    if (
      subcategory == undefined &&
      questionType == undefined &&
      questionLabelLike == ""
    ) {
      // this means that we are calling the reset field
      // console.log("we are reseting the value");
      fetchInitialFilteredQuestions();
    } else {
      // this means that we are using the language or one of the filters
      // console.log("we are using the filters");

      fetchLanguageChangedQuestions();
    }
  }, [subcategory, questionType, questionLabelLike]);

  useEffect(() => {
    fetchLanguageChangedQuestions();
  }, [language]);
  function getQuestionsByCategory(questionsData: any, categoryType: any) {
    if (questionsData[categoryType]) {
      return questionsData[categoryType].questions;
    } else {
      return [];
    }
  }

  const onDragEnd = (result: any) => {
    const { source, destination, type } = result;
    // console.log("source", source);
    // console.log("destination", destination);
    // console.log("type", type);
    if (!destination) return;

    if (source.droppableId !== destination.droppableId) {
      const type = source.index;
      // console.log("typeof type", typeof type === "string");
      // console.log("type", type);

      // this is for category
      if (typeof type === "string") {
        // Code for when myVar is a string
        // console.log("The category is: category");
        // console.log("questions", questions);

        const questionsForCategory = getQuestionsByCategory(categories, type);
        // console.log(questionsForCategory);
        // console.log("questionsForCategory", questionsForCategory);

        const filteredNewQuestions = questionsForCategory.filter(
          (newQuestion: { question_id: string }) =>
            !selectedQuestions.some(
              (existingQuestion) =>
                existingQuestion.question_id === newQuestion.question_id
            )
        );

        setSelectedQuestions((prevQuestions) => [
          ...prevQuestions,
          ...filteredNewQuestions,
        ]);

        if (!selectedCategories.includes(type)) {
          setSelectedCategories([...selectedCategories, type]);
        }
      } else {
        // console.log("The category is: quesiton");
        const newCategory = source.index?.subcategory_label_name;

        if (!selectedCategories.includes(newCategory)) {
          setSelectedCategories([...selectedCategories, newCategory]);
        }

        const newQuestion = source.index;

        const exists = selectedQuestions.some(
          (question: any) => question.question_id == newQuestion.question_id
        );

        if (!exists) {
          setSelectedQuestions([...selectedQuestions, newQuestion]);
        }
      }
    }
  };

  const handleSave = async () => {
    setIsLoadingForSave(true);
    try {
      const token = localStorage.getItem("token");

      // const response = await axios.post(
      //   "https://aitools.kapinsights.com/save_survey",
      //   selectedQuestions,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // if (response) {
      //   message.success(response?.data?.message);
      //   setSurveyId(response?.data?.survey_id);
      //   setIsLoadingForSave(false);
      // }
      let headers: AxiosRequestConfig["headers"] = {
        "Content-Type": "application/json",
      };
      const response = await post<any>(
        `save_survey?project_id=${projectId}`,
        selectedQuestions,
        {
          headers,
        }
      );
      if (response.type === "success") {
        message.success(response?.data?.message);
        setSurveyId(response?.data?.survey_id);
        setIsLoadingForSave(false);
      } else {
        throw new Error(`HTTP error! status: ${response.type}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("There was an error translating the text:", error);
      message.error("Survey failed");
      setIsLoadingForSave(false);
    }
  };

  const handleExport = async () => {
    let default_language_id = "ecfb845a-86f0-476c-a4df-f101d35a96f9";
    setIsLoadingForExport(true);
    try {
      const response = await get<any>(
        `download_survey/${surveyId}?default_language_id=${default_language_id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `survey.xlsx`);
      document.body.appendChild(link);
      link.click();
      // Clean up
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      message.success("Survey downloaded successfully");
      setIsLoadingForExport(false);
    } catch (error: any) {
      message.error("Survey failed");
      setIsLoadingForExport(false);
    }
  };

  const QuestionInput = ({ questionType, choiceLabelTexts }: any) => {
    const [showOptions, setShowOptions] = useState(true); // State to control visibility

    const toggleOptions = () => setShowOptions(!showOptions); // Toggle function

    switch (questionType) {
      case "Select One":
        return (
          //   <>
          //     {/* {isHide && ( */}
          //     <RadioGroup className="question-input-style">
          //       {choiceLabelTexts?.map((text: any, index: any) => (
          //         <Radio
          //           key={index}
          //           value={text}
          //           style={{
          //             border: "1px solid #9BACEB",
          //             backgroundColor: "white",
          //             padding: "5px 0.5rem",
          //             color: "#7C7C80",
          //             borderRadius: "5px",
          //           }}
          //         >
          //           {text}
          //         </Radio>
          //       ))}
          //     </RadioGroup>
          //     {/* )} */}
          //   </>
          // );
          <>
            {showOptions && (
              <RadioGroup className="question-input-style">
                {choiceLabelTexts?.map((text: any, index: any) => (
                  <Radio
                    key={index}
                    value={text}
                    style={{
                      border: "1px solid #9BACEB",
                      backgroundColor: "white",
                      padding: "5px 0.5rem",
                      color: "#7C7C80",
                      borderRadius: "5px",
                    }}
                  >
                    {text}
                  </Radio>
                ))}
              </RadioGroup>
            )}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a>
          </>
        );
      case "Select Multiple":
        return (
          <>
            {showOptions && (
              <Checkbox.Group className="question-input-style">
                {choiceLabelTexts?.map((text: any, index: any) => (
                  <Checkbox
                    key={index}
                    value={text}
                    style={{
                      border: "1px solid #9BACEB",
                      backgroundColor: "white",
                      padding: "5px 0.5rem",
                      borderRadius: "5px",
                      color: "#7C7C80",
                    }}
                  >
                    {text}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a>
          </>
        );
      case "Date and time":
        // Përdoret DatePicker për zgjedhjen e datës dhe orës
        return (
          <>
            {showOptions && (
              <DatePicker style={{ marginBottom: "1rem" }} showTime />
            )}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a>
          </>
        );
      case "Number":
        // Përdoret InputNumber për numrat
        return (
          <>
            {showOptions && (
              <InputNumber
                className="question-input-style"
                style={{ marginBottom: "1rem" }}
              />
            )}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a>
          </>
        );
      case "Note":
        // Përdoret TextArea për shënime
        return (
          <>
            {showOptions && (
              <TextArea style={{ marginBottom: "1rem" }} rows={4} />
            )}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a>
          </>
        );
      case "Text":
        return (
          <>
            {/* {showOptions && <Input style={{ marginBottom: "1rem" }} />}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a> */}
            {showOptions && (
              <TextArea style={{ marginBottom: "1rem" }} rows={4} />
            )}
            <a onClick={toggleOptions}>
              {showOptions ? "Hide Options" : "Show Options"}
            </a>
          </>
        );
      default:
        return null;
    }
  };
  const handleRemoveCategory = (categoryToRemove: any) => {
    const newCategories = selectedCategories.filter(
      (category) => category !== categoryToRemove
    );
    setSelectedCategories(newCategories);

    const newQuestions = selectedQuestions.filter((question: any) => {
      const questionCategory =
        question.category || question.subcategory_label_name;
      return questionCategory !== categoryToRemove;
    });
    setSelectedQuestions(newQuestions);
  };
  const handleRemoveQuestion = (questionIdToRemove: any) => {
    const questionToRemove = selectedQuestions.find(
      (question) => question.question_id === questionIdToRemove
    );
    const categoryOfRemovedQuestion = questionToRemove
      ? questionToRemove.category || questionToRemove.subcategory_label_name
      : null;

    const updatedQuestions = selectedQuestions.filter(
      (question) => question.question_id !== questionIdToRemove
    );
    setSelectedQuestions(updatedQuestions);

    const isCategoryEmpty = !updatedQuestions.some(
      (question) =>
        (question.category || question.subcategory_label_name) ===
        categoryOfRemovedQuestion
    );

    if (categoryOfRemovedQuestion && isCategoryEmpty) {
      const updatedCategories = selectedCategories.filter(
        (category) => category !== categoryOfRemovedQuestion
      );
      setSelectedCategories(updatedCategories);
    }
  };

  const handleResetFilters = () => {
    // setLanguage(undefined);
    setSubcategory(undefined);
    setQuestionType(undefined);
    setQuestionLabelLike("");
  };

  // console.log("selectedQuestions drag and drop", selectedQuestions);
  return (
    <>
      <div className="survey-builder-layout" style={{ marginLeft: 0 }}>
        <Content
          className="site-layout-background ddd"
          style={{
            padding: "0 24",
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="header-image-inner-container">
            {/* Assuming surveyGeneratorHeader contains the image seen in the first picture */}
            <img
              src={SurveyBuilderHeader}
              alt="Survey Builder"
              style={{ width: "100%" }}
            />
          </div>
          {/* Main content */}
          <div style={{ border: "2px solid #d9e3ff", borderRadius: "8px" }}>
            <div className="survey-header">
              <div className="buttons">
                <Button
                  icon={<SaveOutlined />}
                  className="save-button"
                  type="primary"
                  style={{
                    backgroundColor: "#A072EB",
                    color: "white",
                    border: "unset !important",
                  }}
                  onClick={handleSave}
                  disabled={isLoading || userRole === "Guest"}
                >
                  {isLoadingForSave ? <Spin /> : "Save the survey"}
                </Button>
                <Button
                  icon={<ExportOutlined />}
                  className="preview-button"
                  style={{
                    backgroundColor: "#4A1C96",
                    color: "white",
                    border: "unset",
                  }}
                  onClick={handleExport}
                  disabled={isLoading || userRole === "Guest"}
                >
                  {isLoadingForExport ? <Spin /> : "Export the survey"}
                </Button>
              </div>
            </div>
            {/* <hr style={{ color: "#A072EB", border: "1px solid" }} /> */}

            <div className="content-dd">
              <DragDropContext onDragEnd={onDragEnd}>
                {/* Question Database */}
                <Droppable droppableId="questions">
                  {(provided, snapshot) => (
                    <Card
                      title="Question database"
                      style={{
                        marginBottom: "16px",
                        width: "100%",
                        boxShadow: "0px 4px 4px rgba(111, 170, 241, 0.42)",
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {/* <div className="first-filters">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <label>Filter questions by subcategory </label>
                        <Select
                          className="custom-select"
                          showSearch
                          placeholder="Select one or various options"
                          // style={{ width: "100%", marginBottom: 24 }}
                          value={subcategory?.name}
                          onChange={handleSubcategoryChange}
                          filterOption={(input, option: any) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {subcategoryLabels?.map((sub) => (
                            <Option key={sub.id} value={sub.subcategoryid}>
                              {sub.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "0.5rem",
                        }}
                      >
                        <label>Filter questions by type </label>

                        <Select
                          showSearch
                          placeholder="Select one or various options"
                          style={{ width: "100%", marginBottom: 24 }}
                          value={questionType?.questiontype}
                          onChange={handleQuestionTypeChange}
                          filterOption={(input, option: any) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {questionTypes?.map((questiontype?) => (
                            <Option
                              key={questiontype?.id}
                              value={questiontype?.questiontype}
                            >
                              {questiontype?.questiontype}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div> */}
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <label>Filter questions by subcategory </label>
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            placeholder="Select one or various options"
                            value={subcategory?.name}
                            onChange={handleSubcategoryChange}
                            filterOption={(input, option: any) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {subcategoryLabels?.map((sub) => (
                              <Option key={sub.id} value={sub.subcategoryid}>
                                {sub.name}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col
                          span={12}
                          // style={{
                          //   display: "flex",
                          //   flexDirection: "column",
                          //   width: "100%",
                          //   gap: "0.5rem",
                          // }}
                        >
                          <label>Filter questions by type </label>
                          {/* 
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select one or various options"
                          // style={{ width: "100%", marginBottom: 24 }}
                          value={questionType?.questiontype}
                          onChange={handleQuestionTypeChange}
                          filterOption={(input, option: any) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {questionTypes?.map((questiontype?) => (
                            <Option
                              key={questiontype?.id}
                              value={questiontype?.questiontype}
                            >
                              {questiontype?.questiontype}
                            </Option>
                          ))}
                        </Select> */}
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select one or various options"
                            value={questionType?.questiontype}
                            onChange={handleQuestionTypeChange}
                            filterOption={(input, option: any) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {questionTypes.length > 0 &&
                              questionTypes.map((questionType) => (
                                <Option
                                  key={questionType.id}
                                  value={questionType.questiontype}
                                >
                                  {questionType.questiontype}
                                </Option>
                              ))}
                          </Select>
                        </Col>
                      </Row>
                      <div className="second-filters">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <label>Filter by language</label>
                          <Select
                            showSearch
                            placeholder="Select a language"
                            style={{ width: "100%", marginBottom: 24 }}
                            value={language?.name}
                            onChange={handleLanguageChange}
                            filterOption={(input, option: any) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {supportedLanguages?.map((lang) => (
                              <Option key={lang.id} value={lang.code}>
                                {lang.name} - {lang.code}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <label>Filter questions by usage </label>
                          <Select
                            disabled
                            showSearch
                            placeholder="Select a language"
                            style={{ width: "100%", marginBottom: 24 }}
                            value={language?.name}
                            onChange={handleLanguageChange}
                            filterOption={(input, option: any) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {supportedLanguages?.map((lang) => (
                              <Option key={lang.id} value={lang.code}>
                                {lang.name} - {lang.code}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <label>Search</label>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <Input.Search
                            placeholder="Search questions"
                            value={questionLabelLike}
                            onChange={handleQuestionLabelLikeChange}
                            onSearch={fetchFilteredQuestions}
                            style={{ marginBottom: "16px" }}
                          />
                          {/* <Button
                          onClick={fetchFilteredQuestions}
                          style={{
                            backgroundColor: "#CAE2FF",
                            border: "unset",
                          }}
                        >
                          Search
                        </Button> */}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          width: "100%",
                          marginBottom: "2rem",
                        }}
                      >
                        <label>Reset filters</label>
                        <Button onClick={handleResetFilters}>
                          Reset filters
                        </Button>
                      </div>
                      {isLoading ? (
                        <Spin size="large" />
                      ) : (
                        <>
                          {Object.keys(categories).map((cat: any, idx: any) => (
                            <>
                              {/* {console.log("cat:", cat)}
                            {console.log("idx:", idx)}
                            {console.log("categories[cat]:", categories[cat])} */}
                              {/* {categories[cat]?.questions?.map( */}
                              {/* (question: any, index: any) => ( */}
                              <Draggable
                                key={idx}
                                draggableId={`category-${idx}`}
                                index={cat}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow:
                                        "0px 4px 4px rgba(111, 170, 241, 0.42) !important",
                                    }}
                                  >
                                    <div className="cardd">
                                      <div
                                        className="collapsible-container"
                                        key={idx}
                                      >
                                        <button
                                          type="button"
                                          className={`collapsible ${
                                            isOpen === idx ? "active" : ""
                                          }`}
                                          onClick={() => toggleCollapse(idx)}
                                        >
                                          <div
                                            style={{
                                              alignItems: "center",
                                              gap: "1rem",
                                              display: "grid",
                                              gridAutoFlow: "column",
                                            }}
                                          >
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8.99992 17C11.1216 17 13.1564 16.1571 14.6567 14.6569C16.157 13.1566 16.9998 11.1217 16.9998 9C16.9998 6.87827 16.157 4.84344 14.6567 3.34315C13.1564 1.84285 11.1216 1 8.99992 1C6.87821 1 4.8434 1.84285 3.34312 3.34315C1.84285 4.84344 1 6.87827 1 9C1 11.1217 1.84285 13.1566 3.34312 14.6569C4.8434 16.1571 6.87821 17 8.99992 17Z"
                                                stroke="#1673E3"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M8.99979 5.30762V12.6922"
                                                stroke="#1673E3"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M5.30647 8.99902H12.691"
                                                stroke="#1673E3"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                            <label>
                                              {
                                                categories[cat]
                                                  .subcategory_label
                                              }
                                            </label>
                                          </div>

                                          <span className="collapsible-icon">
                                            {isOpen === idx ? (
                                              <svg
                                                width="14"
                                                height="8"
                                                viewBox="0 0 14 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 7L7 1L13 7"
                                                  stroke="#152C99"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                width="15"
                                                height="10"
                                                viewBox="0 0 15 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M0.996765 1L7.40386 8.0858L13.8109 1"
                                                  stroke="#152C99"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            )}
                                          </span>
                                        </button>
                                        {categories[cat]?.questions?.map(
                                          (q: any, i: any) => (
                                            // <Draggable
                                            //   key={q.question_id}
                                            //   draggableId={q.question_id}
                                            //   index={q}
                                            // >
                                            <Draggable
                                              key={q.question_id}
                                              draggableId={`question-${q.question_id}`}
                                              index={q}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <>
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={{
                                                        ...provided
                                                          .draggableProps.style,
                                                        marginBottom: "1rem",
                                                      }}
                                                    >
                                                      {isOpen === idx && (
                                                        <div className="inside-card">
                                                          <div className="inside-card-header">
                                                            <svg
                                                              width="18"
                                                              height="18"
                                                              viewBox="0 0 18 18"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M8.99992 17C11.1216 17 13.1564 16.1571 14.6567 14.6569C16.157 13.1566 16.9998 11.1217 16.9998 9C16.9998 6.87827 16.157 4.84344 14.6567 3.34315C13.1564 1.84285 11.1216 1 8.99992 1C6.87821 1 4.8434 1.84285 3.34312 3.34315C1.84285 4.84344 1 6.87827 1 9C1 11.1217 1.84285 13.1566 3.34312 14.6569C4.8434 16.1571 6.87821 17 8.99992 17Z"
                                                                stroke="#1673E3"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                              <path
                                                                d="M8.99979 5.30762V12.6922"
                                                                stroke="#1673E3"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                              <path
                                                                d="M5.30647 8.99902H12.691"
                                                                stroke="#1673E3"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                            </svg>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "flex-end",
                                                                gap: "1rem",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "12px",
                                                                  color:
                                                                    "#525252",
                                                                  border:
                                                                    "1px solid #B3B3B3 ",
                                                                  backgroundColor:
                                                                    "#E6E6E6",
                                                                  borderRadius:
                                                                    "8px",
                                                                  padding:
                                                                    "4px 13px",
                                                                }}
                                                              >
                                                                {
                                                                  q.language_name
                                                                }
                                                              </span>
                                                              <span
                                                                style={{
                                                                  backgroundColor:
                                                                    "#F4D9FF",
                                                                  borderRadius:
                                                                    "30px",
                                                                  padding:
                                                                    "4px 13px",
                                                                }}
                                                              >
                                                                {
                                                                  q.question_type
                                                                }
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="inside-card-content">
                                                            <p>
                                                              {
                                                                q.question_label_text
                                                              }
                                                            </p>

                                                            <QuestionInput
                                                              questionType={
                                                                q.question_type
                                                              }
                                                              choiceLabelTexts={
                                                                q.choice_label_texts
                                                              }
                                                            />
                                                            {/* <a
                                                            // href="#"
                                                            onClick={() =>
                                                              toggleCollapse(
                                                                q.question_id
                                                              )
                                                            }
                                                            // onClick={() =>
                                                            //   setIsHide(!isHide)
                                                            // }
                                                          >
                                                            Hide options
                                                          </a> */}
                                                            <hr
                                                              style={{
                                                                marginTop:
                                                                  "1rem",
                                                              }}
                                                            />

                                                            <div className="tags-container">
                                                              {q.tags?.map(
                                                                (
                                                                  tag: any,
                                                                  index: any
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="tag"
                                                                  >
                                                                    {tag}
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </>
                                                );
                                              }}
                                            </Draggable>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                              {/* )} */}
                            </>
                          ))}
                        </>
                      )}
                      {provided.placeholder}
                      {isLoadingQuestions && (
                        <div style={{ textAlign: "center" }}>
                          <Spin size="large" />
                        </div>
                      )}
                    </Card>
                  )}
                </Droppable>
                {/* here is selected question */}
                {/* here we drop the questions */}
                {/* Build Survey */}

                <Droppable droppableId="selectedQuestions">
                  {(provided, snapshot) => (
                    <Card
                      title="Your survey"
                      style={{ width: "100%" }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {selectedCategories?.length === 0 && (
                        <div
                          className="drag-drop-indicator"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={DragAndDrop}
                            alt="Drag and drop questions"
                            className="drag-and-drop"
                          />
                        </div>
                      )}

                      {selectedCategories?.map((cat: any, idx: any) => (
                        <>
                          <div>
                            <div className="cardd">
                              <div className="collapsible-container" key={idx}>
                                <button
                                  type="button"
                                  className={`collapsible ${
                                    isOpen === idx ? "active" : ""
                                  }`}
                                  onClick={() => toggleSelectedCollapse(idx)}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      gap: "1rem",
                                      display: "grid",
                                      gridAutoFlow: "column",
                                    }}
                                  >
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveCategory(cat);
                                      }}
                                    >
                                      <path
                                        d="M8.99992 17C11.1216 17 13.1564 16.1571 14.6567 14.6569C16.157 13.1566 16.9998 11.1217 16.9998 9C16.9998 6.87827 16.157 4.84344 14.6567 3.34315C13.1564 1.84285 11.1216 1 8.99992 1C6.87821 1 4.8434 1.84285 3.34312 3.34315C1.84285 4.84344 1 6.87827 1 9C1 11.1217 1.84285 13.1566 3.34312 14.6569C4.8434 16.1571 6.87821 17 8.99992 17Z"
                                        stroke="#1673E3"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M5.3064 8.99902H12.6909"
                                        stroke="#1673E3"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>

                                    <label>
                                      {cat}
                                      {/* {categories[cat].subcategory_label} */}
                                    </label>
                                  </div>

                                  <span className="collapsible-icon">
                                    {selectIsOpen === idx ? (
                                      <svg
                                        width="14"
                                        height="8"
                                        viewBox="0 0 14 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 7L7 1L13 7"
                                          stroke="#152C99"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="15"
                                        height="10"
                                        viewBox="0 0 15 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0.996765 1L7.40386 8.0858L13.8109 1"
                                          stroke="#152C99"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </button>

                                {selectedQuestions
                                  .filter(
                                    (q) => q.subcategory_label_name === cat
                                  )
                                  .map((q, i) => (
                                    <Draggable
                                      key={q.question_id}
                                      draggableId={q.question_id}
                                      index={i}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <>
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                ...provided.draggableProps
                                                  .style,
                                                marginBottom: "1rem",
                                              }}
                                            >
                                              {selectIsOpen === idx && (
                                                <div className="inside-card">
                                                  <div className="inside-card-header">
                                                    <svg
                                                      onClick={() =>
                                                        handleRemoveQuestion(
                                                          q.question_id
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      width="18"
                                                      height="18"
                                                      viewBox="0 0 18 18"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M8.99992 17C11.1216 17 13.1564 16.1571 14.6567 14.6569C16.157 13.1566 16.9998 11.1217 16.9998 9C16.9998 6.87827 16.157 4.84344 14.6567 3.34315C13.1564 1.84285 11.1216 1 8.99992 1C6.87821 1 4.8434 1.84285 3.34312 3.34315C1.84285 4.84344 1 6.87827 1 9C1 11.1217 1.84285 13.1566 3.34312 14.6569C4.8434 16.1571 6.87821 17 8.99992 17Z"
                                                        stroke="#1673E3"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                      <path
                                                        d="M5.3064 8.99902H12.6909"
                                                        stroke="#1673E3"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "flex-end",
                                                        gap: "1rem",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          color: "#525252",
                                                          border:
                                                            "1px solid #B3B3B3 ",
                                                          backgroundColor:
                                                            "#E6E6E6",
                                                          borderRadius: "8px",
                                                          padding: "4px 13px",
                                                        }}
                                                      >
                                                        {q.language_name}
                                                      </span>
                                                      <span
                                                        style={{
                                                          backgroundColor:
                                                            "#F4D9FF",
                                                          borderRadius: "30px",
                                                          padding: "4px 13px",
                                                        }}
                                                      >
                                                        {q.question_type}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="inside-card-content">
                                                    <p>
                                                      {q.question_label_text}
                                                    </p>

                                                    <QuestionInput
                                                      questionType={
                                                        q.question_type
                                                      }
                                                      choiceLabelTexts={
                                                        q.choice_label_texts
                                                      }
                                                    />
                                                    {/* <a
                                                    href="#"
                                                    onClick={() =>
                                                      toggleSelectedCollapse(
                                                        q.question_id
                                                      )
                                                    }
                                                  >
                                                    Hide options
                                                  </a> */}
                                                    <hr
                                                      style={{
                                                        marginTop: "1rem",
                                                      }}
                                                    />
                                                    <div className="tags-container">
                                                      {q.tags?.map(
                                                        (
                                                          tag: any,
                                                          index: any
                                                        ) => (
                                                          <span
                                                            key={index}
                                                            className="tag"
                                                          >
                                                            {tag}
                                                          </span>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        );
                                      }}
                                    </Draggable>
                                  ))}
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                          {/* </Draggable> */}
                          {/* )} */}
                        </>
                      ))}
                      {provided.placeholder}
                    </Card>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
};

export default SurveyBuilder;

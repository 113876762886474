// // PageLayout.tsx
// import React, { useEffect, useState } from "react";
// import { Layout } from "antd";
// import Sidebar from "./Sidebar";
// import TopBar from "./Topbar/Header";
// import { Outlet, Navigate } from "react-router-dom";
// import "./PageLayout.css";
// import { useLocation } from "react-router-dom";

// const { Content } = Layout;

// type PageLayoutProps = {
//   children?: React.ReactNode;
// };

// const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
//   const location = useLocation();

//   const [openSidebar, setOpenSidebar] = useState(false);
//   const isLoggedIn = !!localStorage.getItem("isAuthenticates");

//   useEffect(() => {
//     setOpenSidebar(false);
//   }, [location]);

//   if (!isLoggedIn) {
//     return <Navigate to="/" replace />;
//   }

//   return (
//     <>
//       <TopBar setOpenSidebar={setOpenSidebar} />
//       <Layout className="layout">
//         <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
//         <Content className="site-layout-background">
//           {/* Outlet- because if in App.tsx is calling inside route the element this is the reason
//           So  this is the new version to call child component */}
//           {children || <Outlet />}
//         </Content>
//       </Layout>
//     </>
//   );
// };

// export default PageLayout;

import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import TopBar from "./Topbar/Header";
import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./PageLayout.css";

const { Content } = Layout;

const PageLayout: React.FC = () => {
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const isLoggedIn = !!localStorage.getItem("isAuthenticates");

  useEffect(() => {
    setOpenSidebar(false);
  }, [location]);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const isProjectDetailsPage =
    location.pathname.includes("/projects/") &&
    location.pathname.includes("/details");

  const layoutClassName = `${
    isProjectDetailsPage || !location.pathname.startsWith("/projects")
      ? "layout"
      : "removeMarginInLayout"
  }`;
  return (
    <>
      <TopBar setOpenSidebar={setOpenSidebar} />
      <Layout className={layoutClassName}>
        {(isProjectDetailsPage ||
          !location.pathname.startsWith("/projects")) && (
          <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        )}
        <Content
          className={`site-layout-background ${
            isProjectDetailsPage || !location.pathname.startsWith("/projects")
              ? openSidebar
                ? ""
                : "full-width-content"
              : ""
          }`}
        >
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};

export default PageLayout;

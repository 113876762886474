import React, { useState, useEffect } from "react";
import {
  Table,
  message,
  Space,
  Modal,
  Pagination,
  Button,
  TableColumnsType,
} from "antd";
import {
  ReloadOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { get, put, remove } from "../../../../utils/apiUtils";
import dayjs, { Dayjs } from "dayjs";

interface DataType {
  key: React.Key;
  filename: string;
  type_name: string;
  date: string;
  time_remaining: string;
  actions: JSX.Element;
}

interface DeletedTranlsationsModalProps {
  visible: boolean;
  onCancel: () => void;
  projectId: any;
  restore: any;
}

const DeletedTranslation: React.FC<DeletedTranlsationsModalProps> = ({
  visible,
  onCancel,
  projectId,
  restore,
}) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionType, setSelectionType] = useState<"checkbox">("checkbox");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalProjects, setTotalProjects] = useState(0);
  const [deletedTranslations, setDeletedTranslations] = useState<DataType[]>(
    []
  );
  useEffect(() => {
    if (visible) {
      fetchDeletedTranslations();
    }
  }, [visible, currentPage, pageSize]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "History of translations",
      dataIndex: "filename",
      // render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Type",
      dataIndex: "type_name",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) =>
        text === "Invalid date" ? (
          <span style={{ color: "red" }}>{text}</span>
        ) : (
          text
        ),
    },
    {
      title: "Time remaining",
      dataIndex: "time_remaining",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled Project", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const deleteTranslation = async (id: any) => {
    try {
      if (
        !window.confirm("Are you sure you want to delete this translation?")
      ) {
        return;
      }

      setLoading(true);

      const token = localStorage.getItem("token");
      // await axios.delete(
      //   `https://aitools.kapinsights.com/translations/${id}/permanent`,
      //   {
      //     headers: {
      //       Accept: "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      let response = await remove(
        `translations/${id}/permanent?project_id=${projectId}`
      );

      if (response?.type === "success") {
        message.success("Translation deleted successfully");
        await fetchDeletedTranslations();
      }
    } catch (error: any) {
      message.error("Failed to delete translation: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const isValidDate = (dateStr: any, format = "MM/DD/YYYY") => {
    return dayjs(dateStr, format).isValid();
  };
  const restoreTranslation = async (id: any) => {
    try {
      setLoading(true);

      let response = await put<any>(
        `translations/${id}/restore?project_id=${projectId}`,
        {}
      );
      if (response.type === "success") {
        message.success(response?.data.message);
        await fetchDeletedTranslations();

        if (restore) {
          restore();
        }
      }
    } catch (error: any) {
      message.error("Failed to restore translation: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchDeletedTranslations = async () => {
    setLoading(true);
    setShowErrorMessage(false); // Reset the error message visibility before fetching

    let response = await get<any>(
      `translations/deleted?project_id=${projectId}`,
      {}
    );
    if (response.type === "success") {
      const fetchedTranslations = response.data.map((item: any) => {
        // Validate the date format before adding to state
        const isValid = isValidDate(item.date);
        if (!isValid) {
          console.error(`Invalid date format for item with id ${item.id}`);
        }
        return {
          ...item,
          key: item.id,
          date: isValid
            ? dayjs(item.date).format("MM/DD/YYYY")
            : "Invalid date",
          actions: (
            <Space size="middle" className="actions">
              <ReloadOutlined onClick={() => restoreTranslation(item.id)} />
              <DeleteOutlined onClick={() => deleteTranslation(item.id)} />
            </Space>
          ),
        };
      });
      setDeletedTranslations(fetchedTranslations);
      setLoading(false);
    } else {
      message.error(response.type);
    }
  };
  useEffect(() => {
    fetchDeletedTranslations();
  }, []);

  const handleTableChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <Modal
      title="Deleted Translations"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} className="go-back-button">
          Go back
        </Button>,
      ]}
      className="archived-project-modal"
      style={{ minWidth: "800px" }}
    >
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={deletedTranslations}
        scroll={{ x: 700 }}
      />
    </Modal>
  );
};

export default DeletedTranslation;

import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Tabs,
  Typography,
  Upload,
  message,
  Spin,
  Table,
  Space,
  Divider,
  Radio,
  TableColumnsType,
  DatePicker,
} from "antd";
// import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import { Modal } from "antd";
import TopBar from "../../../Topbar/Header";
import HeaderAITool from "../../../../assets/img/header.png";
import "./index.css";
import HeaderOfList from "../../../../assets/img/headerForList.png";
import {
  PlusOutlined,
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { get } from "../../../../utils/apiUtils";
import { getUserRole } from "../../../../hooks/getUserRoles";
import DeletedTranslation from "../DeletedTranslations";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

interface DataType {
  key: React.Key;
  filename: string;
  type_name: string;
  date: string;
  actions: JSX.Element;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "History of translations",
    dataIndex: "filename",
    // render: (text: string) => <a>{text}</a>,
  },
  {
    title: "Type",
    dataIndex: "type_name",
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (text) =>
      text === "Invalid date" ? (
        <span style={{ color: "red" }}>{text}</span>
      ) : (
        text
      ),
  },
  {
    title: "",
    dataIndex: "actions",
  },
];
const data: DataType[] = [
  {
    key: "1",
    filename: "Translation 1",
    type_name: "Text",
    date: "05/02/2024",
    actions: (
      <div className="actions">
        <CopyOutlined />
        <DownloadOutlined />
        <DeleteOutlined />
      </div>
    ),
  },
  {
    key: "2",
    filename: "Translation 2",
    type_name: "Json",
    date: "05/02/2024",
    actions: (
      <div className="actions">
        <CopyOutlined />
        <DownloadOutlined />
        <DeleteOutlined />
      </div>
    ),
  },
  {
    key: "3",
    filename: "Translation 3",
    type_name: "File",
    date: "05/02/2024",
    actions: (
      <div className="actions">
        <CopyOutlined />
        <DownloadOutlined />
        <DeleteOutlined />
      </div>
    ),
  },
];
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record: DataType) => ({
    disabled: record.filename === "Disabled User", // Column configuration not to be checked
    name: record.filename,
  }),
};

function AITranslation() {
  const userRole = getUserRole();
  const { id: projectId } = useParams();

  const [selectionType, setSelectionType] = useState<"checkbox">("checkbox");
  const [searchText, setSearchText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<DataType[]>(data);
  const [typeFilter, setTypeFilter] = useState<string>("Type");
  const [dateFilter, setDateFilter] = useState<Dayjs | string>("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [translationList, setTranslationList] = useState([]);
  const [translationTypes, setTranslationTypes] = useState([]);
  const [jsonName, setJsonName] = useState("");
  const [textName, setTextName] = useState("");
  const [excelName, setExcelName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);

  const navigate = useNavigate();

  const filterData = (): void => {
    const lowercasedSearchText = searchText.toLowerCase();
    const filtered = translationList.filter((item: any) => {
      const searchTextMatch = item.filename
        .toLowerCase()
        .includes(lowercasedSearchText);
      const typeMatch = typeFilter === "Type" || item.type_name === typeFilter;
      const dateMatch =
        !dateFilter || dayjs(item.date).isSame(dayjs(dateFilter), "day");
      return searchTextMatch && typeMatch && dateMatch;
    });
    setFilteredData(filtered);
  };
  const copyTranslation = async (id: React.Key) => {
    setLoading(true); // Set loading to true while the copy operation is in progress
    try {
      const response = await axios.get(
        `https://aitools.kapinsights.com/copy_translation/${id}?project_id=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );

      // Convert the blob to text
      const text = await response.data.text();

      // Check the content type of the response
      if (response.headers["content-type"].includes("application/json")) {
        // Parse JSON
        const json = JSON.parse(text);

        // Check if 'content' is an object
        if (json.content && typeof json.content === "object") {
          // Stringify the object
          await navigator.clipboard.writeText(JSON.stringify(json.content));
        } else {
          // If 'content' is just a string, copy it directly
          await navigator.clipboard.writeText(json.content);
        }
      } else {
        // If the content type is plain text, copy it directly
        await navigator.clipboard.writeText(text);
      }

      message.success("Translation copied to clipboard.");
    } catch (error) {
      console.error("Error copying the translation:", error);
      message.error("Failed to copy translation.");
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const openDeletedTranslationsModal = () => {
    setIsDeletedModalVisible(true);
  };

  const closeDeletedTranslationsModal = () => {
    setIsDeletedModalVisible(false);
  };
  const downloadTranslation = async (
    id: React.Key,
    filename: string,
    type: string
  ) => {
    setLoading(true); // Set loading to true while the download operation is in progress
    try {
      const response = await axios.get(
        `https://aitools.kapinsights.com/translations/download/${id}?project_id=${projectId}`,
        {
          responseType: "blob", // Important for file downloads
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("download", `${filename}.json`); // Assuming the file is a JSON file
      // document.body.appendChild(link);
      // link.click();
      // Determine the file extension based on the type
      const fileExtension =
        type === "excel" ? ".xlsx" : type === "json" ? ".json" : ".txt"; // default to plain text
      link.setAttribute("download", `${filename}${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      // Clean up
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      message.success("Translation downloaded successfully");
    } catch (error: any) {
      message.error("Failed to download translation: " + error.message);
    } finally {
      setLoading(false); // Set loading to false after the operation
    }
  };

  // const deleteTranslation = (id: any) => {
  //   Modal.confirm({
  //     title: "Are you sure you want to delete this translation?",
  //     content: (
  //       <div style={{ marginBottom: "1rem" }}>
  //         This action cannot be undone.
  //       </div>
  //     ),
  //     okText: <div style={{ width: "150px" }}>Yes</div>,
  //     okType: "danger",
  //     cancelText: <div style={{ width: "150px" }}>No</div>,
  //     onOk: async () => {
  //       try {
  //         setLoading(true); // Set loading to true while deleting

  //         // Make an API call to delete the translation
  //         const token = localStorage.getItem("token"); // Retrieve token
  //         await axios.delete(
  //           `https://aitools.kapinsights.com/translations/${id}`,
  //           {
  //             headers: {
  //               Accept: "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         message.success("Translation deleted successfully");

  //         // Refresh the list of translations
  //         await fetchTranslations();
  //       } catch (error: any) {
  //         message.error("Failed to delete translation: " + error.message);
  //       } finally {
  //         setLoading(false); // Ensure loading is false after operation
  //       }
  //     },
  //   });
  // };

  const deleteTranslation = async (id: any) => {
    try {
      // Confirm deletion with the user before proceeding
      if (
        !window.confirm("Are you sure you want to delete this translation?")
      ) {
        return;
      }

      setLoading(true); // Set loading to true while deleting

      // Make an API call to delete the translation
      const token = localStorage.getItem("token"); // Retrieve token
      await axios.delete(
        `https://aitools.kapinsights.com/translations/${id}?project_id=${projectId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success("Translation deleted successfully");

      // Refresh the list of translations
      await fetchTranslations();
    } catch (error: any) {
      message.error("Failed to delete translation: " + error.message);
    } finally {
      setLoading(false); // Ensure loading is false after operation
    }
  };

  // Utility function to validate date format
  const isValidDate = (dateStr: any, format = "MM/DD/YYYY") => {
    return dayjs(dateStr, format).isValid();
  };

  const fetchTranslations = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let response = await get<any>(
      `users/translations?project_id=${projectId}`,
      {}
    );
    if (response.type === "success") {
      const fetchedTranslations = response.data.map((item: any) => {
        // Validate the date format before adding to state
        const isValid = isValidDate(item.date);
        if (!isValid) {
          console.error(`Invalid date format for item with id ${item.id}`);
        }
        // setJsonName(item.type_name)
        console.log("item::", item);
        return {
          ...item,
          key: item.id,
          date: isValid
            ? dayjs(item.date).format("MM/DD/YYYY")
            : "Invalid date",
          actions: (
            <Space size="middle" className="actions">
              {item.type_name !== "excel" && (
                <CopyOutlined onClick={() => copyTranslation(item.id)} />
              )}
              <DownloadOutlined
                onClick={() =>
                  downloadTranslation(item.id, item.filename, item.type_name)
                }
              />
              <DeleteOutlined onClick={() => deleteTranslation(item.id)} />
            </Space>
          ),
        };
      });
      setTranslationList(fetchedTranslations);
      setFilteredData(fetchedTranslations); // Set filtered data here after fetching
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchTranslations();
  //   setTypeFilter("Type"); // Make sure "All" is a valid type that lets all items pass
  //   setDateFilter(""); // No date filter initially
  //   setSearchText(""); // No search filter initially
  // }, []);
  useEffect(() => {
    fetchTranslations();
  }, [projectId]);
  // Initial states should be non-restrictive
  const fetchTranslationTypes = () => {
    setLoading(true);
    axios
      .get("https://aitools.kapinsights.com/translation-types", {
        headers: {
          Accept: "application/json",
          // Include other headers as needed, e.g., Authorization if required
        },
      })
      .then((response) => {
        setTranslationTypes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Failed to fetch translation types: " + error.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchTranslationTypes();
  }, []); // The empty array ensures this effect runs only once after initial render

  const handleTypeChange = (value: any) => {
    setTypeFilter(value);
    filterData();
  };

  const handleDateChange = (date: Dayjs | null, dateString: string) => {
    setSelectedDate(date);
    setDateFilter(date ? date.format("YYYY-MM-DD") : ""); // Adjust the format as necessary
    filterData();
  };

  const handleReset = () => {
    setTypeFilter("Type");
    setDateFilter("");
    setSearchText("");
    filterData();
  };

  useEffect(() => {
    filterData();
  }, [searchText, typeFilter, dateFilter, translationList]);

  const onSearch = (value: string) => {
    setSearchText(value);
    filterData();
  };

  const handleRedirect = () => {
    navigate(`/add-ai-translation/${projectId}`);
  };

  return (
    <>
      <div
        style={{
          padding: "2rem",
        }}
        className="ai-translationList-layout"
      >
        <Content className="content-styles">
          <div
            style={{
              background: "#4C3AB1",
              position: "relative",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 1,
            }}
          >
            <img
              src={HeaderOfList}
              alt="AI Tool Header"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="translation-tool-content">
            <div className="title-button">
              <h2>Translations</h2>
              <div className="reset-filters-button">
                {userRole !== "Guest" && userRole !== "Quality Assurance" && (
                  <>
                    <Button className="button-style" onClick={handleRedirect}>
                      <PlusOutlined /> New translation
                    </Button>

                    <Button
                      className="button-style"
                      onClick={openDeletedTranslationsModal}
                      style={{ width: "100%" }}
                    >
                      <DeleteOutlined /> Deleted translations
                    </Button>

                    <Button className="button-style" onClick={handleReset}>
                      <DeleteOutlined /> Reset Filters
                    </Button>
                  </>
                )}
              </div>{" "}
            </div>
            <div className="filters-style">
              <Search
                placeholder="Search translations"
                onSearch={onSearch} // Make sure this is the function that's called when search is used
                style={{ width: "100%" }}
                enterButton // If you want a button to click for search
              />

              <Select
                placeholder="Type"
                style={{ width: "100%" }}
                onChange={handleTypeChange}
                value={typeFilter}
              >
                {translationTypes.map((type: any) => (
                  <Option key={type.id} value={type.type_name}>
                    {type.type_name}
                  </Option>
                ))}
              </Select>

              <DatePicker
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString)
                }
                style={{ width: "100%" }}
                format="MM/DD/YYYY"
              />
            </div>
            <div>
              <Table
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                className="ai-tools"
                columns={columns}
                dataSource={filteredData} // Make sure to use filteredData here
                scroll={{ x: 700 }}
              />
            </div>
          </div>
        </Content>

        <DeletedTranslation
          visible={isDeletedModalVisible}
          onCancel={closeDeletedTranslationsModal}
          projectId={projectId}
          restore={fetchTranslations}
        />
      </div>
    </>
  );
}

export default AITranslation;

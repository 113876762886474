import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Sidebar.css";
import MenuIcon from "../assets/img/MenuIcon1.png";
import surveyBuilderIcon from "../assets/img/surveyBuilderIcon.png";
import SurveyBuilderIcon from "../assets/img/Vector.png";
import UserManagementIcon from "../assets/img/userManagementIcon.png";
import WorkspacesIcon from "../assets/img/workspacesIcon.png";
import KapInsigntsLogo from "../assets/img/WhiteLogo.png";
import TranslationTool from "../assets/img/translateTool.png";
import { CloseOutlined } from "@ant-design/icons";
import { getUserRole } from "../hooks/getUserRoles";

interface SidebarProps {
  openSidebar: boolean;
  setOpenSidebar: (open: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ openSidebar, setOpenSidebar }) => {
  const userRole = getUserRole();
  const location = useLocation();
  const navigate = useNavigate();
  const { id: projectId } = useParams();

  const isProjectPath = location.pathname.startsWith("/projects");
  const isProjectDetailsPage =
    location.pathname.includes("/projects/") &&
    location.pathname.includes("/details");

  const isSurveyManagementPage =
    location.pathname.startsWith("/ai-survey-generator") ||
    location.pathname.startsWith("/survey-builder") ||
    location.pathname.startsWith("/upload-survey") ||
    location.pathname.startsWith("/ai-translation") ||
    location.pathname.startsWith("/add-ai-translation");

  const navigateToSurveyManagement = (path: any) => {
    navigate(path);
  };

  return (
    <div className={`sidebar ${!openSidebar ? "closed" : ""}`}>
      <div className="logo-section">
        <a href="/workspaces">
          <img src={KapInsigntsLogo} alt="kap logo" />
        </a>
        <div className="closeButton" onClick={() => setOpenSidebar(false)}>
          <CloseOutlined />
        </div>
      </div>
      <div className="menu-section">
        {(userRole === "admin" ||
          userRole === "Guest" ||
          userRole === "Technical Expert" ||
          userRole === "Data Collector" ||
          userRole === "Translation People" ||
          userRole === "Quality Assurance" ||
          userRole === "Project Manager") &&
          !isProjectPath &&
          !isSurveyManagementPage && (
            <>
              <div style={{ marginBottom: "40px" }}>
                <h2>Admin Management</h2>
                <ul>
                  <li>
                    <NavLink
                      to="/workspaces"
                      className={({ isActive }) =>
                        isActive ? "activeLink" : ""
                      }
                    >
                      <img
                        src={WorkspacesIcon}
                        alt="icon"
                        style={{ width: "21px", height: "21px" }}
                      />
                      Workspaces
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/members"
                      className={
                        userRole !== "admin" && userRole !== "Project Manager"
                          ? "display-none"
                          : ({ isActive }) => (isActive ? "activeLink" : "")
                      }
                    >
                      <img
                        src={UserManagementIcon}
                        alt="icon"
                        style={{ width: "21px", height: "21px" }}
                      />
                      User Management
                    </NavLink>
                  </li>
                </ul>
              </div>

              {(userRole === "admin" || userRole === "Project Manager") && (
                <>
                  <h2>Account settings</h2>
                  <ul>
                    <li>
                      <NavLink
                        to="/profile-information"
                        className={({ isActive }) =>
                          isActive ? "activeLink" : ""
                        }
                      >
                        <img
                          src={WorkspacesIcon}
                          alt="icon"
                          style={{ width: "21px", height: "21px" }}
                        />
                        Profile information
                      </NavLink>
                    </li>
                    {/* <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "activeLink" : "")}
                  >
                    <img
                      src={UserManagementIcon}
                      alt="icon"
                      style={{ width: "21px", height: "21px" }}
                    />
                    Log in information
                  </NavLink>
                </li> */}
                  </ul>
                </>
              )}
            </>
          )}
        {(userRole === "admin" ||
          userRole === "Project Manager" ||
          userRole === "Technical Expert" ||
          userRole === "Data Collector" ||
          userRole === "Translation People" ||
          userRole === "Quality Assurance" ||
          userRole === "Guest") &&
          (isProjectDetailsPage || isSurveyManagementPage) && (
            <>
              <h2>Survey Management</h2>
              <ul>
                {userRole !== "Translation People" &&
                  userRole !== "Quality Assurance" && (
                    <li>
                      <NavLink
                        to="/ai-survey-generator"
                        className={({ isActive }) =>
                          isActive ? "activeLink" : ""
                        }
                      >
                        <img
                          src={SurveyBuilderIcon}
                          alt="icon"
                          style={{ width: "21px", height: "21px" }}
                        />
                        AI Survey Generator
                      </NavLink>
                    </li>
                  )}

                {userRole !== "Technical Expert" &&
                  userRole !== "Translation People" &&
                  userRole !== "Quality Assurance" && (
                    <li>
                      <NavLink
                        to={
                          userRole === "admin" || userRole === "Project Manager"
                            ? `/upload-survey/${projectId}`
                            : `/survey-builder/${projectId}`
                        }
                        className={({ isActive }) =>
                          isActive ? "activeLink" : ""
                        }
                      >
                        <img
                          src={surveyBuilderIcon}
                          alt="icon"
                          style={{ width: "21px", height: "21px" }}
                        />
                        Survey Builder
                      </NavLink>
                    </li>
                  )}
                {userRole !== "Data Collector" && (
                  <li>
                    <NavLink
                      to={`/ai-translation/${projectId}`}
                      className={({ isActive }) =>
                        isActive ? "activeLink" : ""
                      }
                    >
                      <img
                        src={TranslationTool}
                        alt="icon"
                        style={{ width: "21px", height: "21px" }}
                      />
                      AI Translation
                    </NavLink>
                  </li>
                )}
              </ul>
            </>
          )}
        {/* {(userRole === "admin" || userRole === "User Management") && (
          <>
            <h2 style={{ marginTop: "40px" }}>Project Settings</h2>
            <ul>
              <li>
                <NavLink
                  to="/deleted-items"
                  className={({ isActive }) => (isActive ? "activeLink" : "")}
                >
                  <img src={MenuIcon} alt="icon" />
                  Deleted Items
                </NavLink>
              </li>
            </ul>
          </>
        )} */}
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Form,
  Card,
  Pagination,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  Spin,
  message,
} from "antd";
import {
  EllipsisOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./index.css";
import surveyGeneratorHeader from "../../assets/img/Banner2.png";
import axios from "axios";
import { toast } from "react-toastify";
import { get, post } from "../../utils/apiUtils";
import WorkspaceModal from "./WorkspaceModal";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "../../hooks/getUserRoles";

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Search } = Input;

interface Workspace {
  id: number;
  name: string;
  description: string;
}

interface ApiResponse {
  type: string;
  data: Workspace[];
}
const Workspaces: React.FC = () => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(null);
  const [modalMode, setModalMode] = useState("create");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [showProjects, setShowProjects] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://aitools.kapinsights.com/countries/"
        );
        console.log("countries response::", response);
        setCountries(response.data); // Assuming response data is the array of countries
      } catch (error) {
        console.error("Failed to fetch countries:", error);
        toast.error("Failed to load countries data!");
      }
    };
    fetchCountries();
    localStorage.removeItem("currentProjectId");
    localStorage.removeItem("currentWorkspaceId");
  }, []);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const openModalForEdit = (projectId: any) => {
    setModalMode("edit");
    setCurrentWorkspaceId(projectId);
    setIsModalVisible(true);
  };
  const showModalForCreate = () => {
    setModalMode("create");
    setCurrentWorkspaceId(null);
    setIsModalVisible(true);
  };
  // const viewProjectDetails = (projectId: any) => {
  //   setCurrentWorkspaceId(projectId);
  //   setModalMode("view");
  //   setIsModalVisible(true);
  // };

  // const fetchData = async () => {
  //   try {
  //     const response = await get<any>("workspaces/?skip=0&limit=10", {});
  //     if (response.type === "success") {
  //       console.log("Response from server:", response.data);
  //       setWorkspaces(response.data);
  //     } else {
  //       console.error("Error fetching data:", response);
  //       toast.error("Failed to load workspaces!");
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch workspaces:", error);
  //     toast.error("Failed to load workspaces!");
  //   }
  // };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const fetchData = async (searchTerm = "", countryId?: string) => {
    try {
      const queryParams = new URLSearchParams({
        skip: "0",
        limit: "10",
      });

      if (searchTerm) {
        queryParams.append("name", searchTerm);
      }
      if (countryId) {
        queryParams.append("country_id", countryId);
      }
      const response = await get<any>(
        `organization-workspaces/?${queryParams.toString()}`,
        {}
      );
      if (response.type === "success") {
        console.log("Response from server:", response.data);
        setWorkspaces(response.data);
      } else {
        console.error("Error fetching data:", response);
        toast.error("Failed to load workspaces!");
      }
    } catch (error) {
      console.error("Failed to fetch workspaces:", error);
      toast.error("Failed to load workspaces!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleWorkspaceClick = (workspaceId: any) => {
    // Navigate to the ProjectsList page with the workspaceId as a URL parameter
    navigate(`/projects/${workspaceId}`);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  const indexOfLastWorkspace = currentPage * pageSize;
  const indexOfFirstWorkspace = indexOfLastWorkspace - pageSize;
  const currentWorkspaces = workspaces.slice(
    indexOfFirstWorkspace,
    indexOfLastWorkspace
  );

  // const handleEdit = (workspace: Workspace) => {
  //   setSelectedWorkspace(workspace);
  //   form.setFieldsValue(workspace);
  //   setIsEditModalVisible(true);
  // };

  // const handleEditOk = () => {
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       if (selectedWorkspace) {
  //         const updatedWorkspace = { ...selectedWorkspace, ...values };
  //         const updatedWorkspaces = workspaces.map((workspace) =>
  //           workspace.id === selectedWorkspace.id ? updatedWorkspace : workspace
  //         );
  //         setWorkspaces(updatedWorkspaces);
  //         form.resetFields();
  //         setIsEditModalVisible(false);
  //       }
  //     })
  //     .catch((info) => console.log("Validate Failed:", info));
  // };

  // const handleEditOk = () => {
  //   setLoading(true);
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       if (selectedWorkspace) {
  //         const url = `https://aitools.kapinsights.com/workspaces/${selectedWorkspace.id}`;
  //         axios
  //           .put(url, {
  //             name: values.name,
  //             description: values.description,
  //             country_id: values.country_id,
  //           })
  //           .then((response) => {
  //             // Assuming the response contains the updated workspace data
  //             const updatedWorkspaces = workspaces.map((workspace) =>
  //               workspace.id === selectedWorkspace.id
  //                 ? response.data
  //                 : workspace
  //             );
  //             setWorkspaces(updatedWorkspaces);
  //             toast.success("Workspace updated successfully!");
  //             setIsEditModalVisible(false);
  //             form.resetFields();
  //           })
  //           .catch((error) => {
  //             console.error("Failed to update workspace:", error);
  //             toast.error("Error updating workspace!");
  //           });
  //       }
  //     })
  //     .catch((info) => {
  //       console.log("Validate Failed:", info);
  //     });
  //   setLoading(false);
  // };

  const handleDelete = (id: number) => {
    const url = `https://aitools.kapinsights.com/workspaces/${id}`;
    axios
      .delete(url)
      .then(() => {
        const filteredWorkspaces = workspaces.filter(
          (workspace) => workspace.id !== id
        );
        setWorkspaces(filteredWorkspaces);
        toast.success("Workspace deleted successfully!");
      })
      .catch((error) => {
        console.error("Failed to delete workspace:", error);
        toast.error("Error deleting workspace!");
      });
  };

  const menu = (workspace: Workspace) => (
    <Menu>
      {userRole !== "Guest" && (
        <>
          <Menu.Item
            key="edit"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              openModalForEdit(workspace.id);
            }}
          >
            Edit
          </Menu.Item>
          <Menu.Item
            key="delete"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              handleDelete(workspace.id);
            }}
          >
            Delete
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const handleOk = () => {
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       const newWorkspace: Workspace = {
  //         id: workspaces.length + 1,
  //         name: values.name,
  //         description: values.description,
  //       };
  //       setWorkspaces((prevWorkspaces) => [...prevWorkspaces, newWorkspace]);
  //       form.resetFields();
  //       setIsModalVisible(false);
  //     })
  //     .catch((info) => console.log("Validate Failed:", info));
  // };
  let organization_id = localStorage.getItem("organizationId");
  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        axios
          .post(
            // `https://api.yoursite.com/workspaces/?organization_id=${values.organization_id}&country_id=${values.country_id}`,
            // `https://aitools.kapinsights.com/workspaces/?organization_id=e12ae911-9f57-46a1-bd83-a4e4923152fa&country_id=${values.country_id}`,
            `https://aitools.kapinsights.com/workspaces/?organization_id=${organization_id}&country_id=${values.country_id}`,
            {
              name: values.name,
              description: values.description,
            }
          )
          .then((response) => {
            message.success("Workspace added successfully!");
            setWorkspaces((prevWorkspaces) => [
              ...prevWorkspaces,
              response.data,
            ]);
            form.resetFields();
            setIsModalVisible(false);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Failed to add workspace:", error);
            toast.error("Failed to add workspace!");
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleCreate = (values: any) => {
    console.log("Received values of form: ", values);
    setVisible(false);
    fetchData();
  };
  return (
    <>
      <div className="workspace-layout" style={{ marginLeft: 0 }}>
        <Content className="content-style">
          <div className="header-image">
            <img
              src={surveyGeneratorHeader}
              alt="workspaces"
              style={{ width: "100%" }}
            />
          </div>

          <Form className="survey-form-workspaces" layout="vertical">
            <div className="card">
              <div className="title-button">
                <h2>My workspaces</h2>
                <div className="reset-filters-button">
                  {(userRole === "admin" || userRole === "Project Manager") && (
                    <Button
                      className="button-style"
                      onClick={showModalForCreate}
                    >
                      <PlusOutlined /> New workspace
                    </Button>
                  )}
                </div>
              </div>

              <div className="searchCountry-section">
                <div className="search-part">
                  <Form.Item
                    name="search"
                    label="Search"
                    className="title-filter-style"
                    style={{ marginBottom: "unset" }}
                  >
                    <Search
                      placeholder="Search translations"
                      onSearch={(value) => fetchData(value, selectedCountry)}
                      style={{ width: "100%" }}
                      enterButton
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="country-part">
                  <Form.Item
                    name="country"
                    label="Country"
                    className="title-filter-style"
                    style={{ marginBottom: "unset" }}
                  >
                    <Select
                      showSearch
                      placeholder="Select a country"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value: any) => {
                        setSelectedCountry(value);
                        fetchData(search, value);
                      }}
                      allowClear
                    >
                      {countries.map((country: any) => (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <p>
                Below are the workspaces you are part of. Select the one you
                want to work on
              </p>
              <Row
                gutter={16}
                style={{ marginTop: "3rem" }}
                className="workspaces-card"
              >
                {currentWorkspaces.map((workspace) => (
                  <Col key={workspace.id} xs={24} sm={12} md={8} lg={8}>
                    <Card
                      style={{ cursor: "pointer" }}
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <div className="workspace-card-name-bottom">
                            {workspace.name}
                          </div>
                          {(userRole === "admin" ||
                            userRole === "Project Manager") && (
                            <Dropdown
                              overlay={menu(workspace)}
                              trigger={["click"]}
                            >
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation(); // Prevents the workspace card click event
                                }}
                              >
                                <EllipsisOutlined
                                  rotate={90}
                                  style={{ color: "white", fontSize: "20px" }}
                                />
                              </a>
                            </Dropdown>
                          )}
                        </div>
                      }
                      bordered={false}
                      className="small-card"
                      onClick={() => handleWorkspaceClick(workspace.id)}
                    >
                      {/* {workspace.description} */}
                    </Card>
                  </Col>
                ))}
              </Row>
              <Pagination
                style={{ display: "flex", justifyContent: "flex-end" }}
                current={currentPage}
                onChange={(page) => setCurrentPage(page)}
                total={workspaces.length}
                pageSize={pageSize}
                showSizeChanger={false}
              />
            </div>
          </Form>
        </Content>

        <WorkspaceModal
          visible={isModalVisible}
          onCancel={handleModalClose}
          onCreate={handleCreate}
          workspaceId={currentWorkspaceId}
          mode={modalMode}
        />
      </div>
    </>
  );
};

export default Workspaces;

import React, { useState, useEffect } from "react";
import TopBar from "../../../Topbar/Header";
import "./index.css";
import {
  Layout,
  Input,
  Button,
  Select,
  Tabs,
  Typography,
  Upload,
  message,
  Spin,
  Table,
  Space,
  Divider,
  Radio,
  TableColumnsType,
  DatePicker,
  Form,
} from "antd";
import { get } from "../../../../utils/apiUtils";
import surveyGeneratorHeader from "../../../../assets/img/surveyGeneratorHeader.png";
import surveyGeneratorPhoto from "../../../../assets/img/aiGeneratorPhoto.png";
import SurveyPreview from "./SurveyPreview"; // Make sure to create this component
import axios from "axios";
import { getUserRole } from "../../../../hooks/getUserRoles";

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

interface SurveyConfiguration {
  type: "both" | "quantitative" | "qualitative";
  length: "5-10" | "11-20" | "20+";
}
export type LanguageType = {
  id: string;
  name: string;
  code: string;
};
const AISurveyGenerator: React.FC = () => {
  const userRole = getUserRole();

  const [surveyConfiguration, setSurveyConfiguration] =
    useState<SurveyConfiguration>({
      type: "both",
      length: "5-10",
    });
  const [supportedLanguages, setSupportedLanguages] = useState<LanguageType[]>(
    []
  );
  const [selectedType, setSelectedType] = useState("");
  const [selectedLength, setSelectedLength] = useState("");
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [completeSurvey, setCompleteSurvey] = useState([]);
  const [surveyDetails, setSurveyDetails] = useState({
    description: "",
    language: "",
    type: "both",
    length: "5-10",
    generated: false,
  });
  // const handleGenerateSurvey = () => {
  //   setShowPreview(true);
  // };

  const transformSurveyData = (apiData: any) => {
    return apiData.map((item: any) => {
      let question_type;
      let options;

      switch (item.question_type) {
        case "Select One":
          question_type = "singleSelect";
          options = item.choice_label_texts;
          break;
        case "Select Multiple":
          question_type = "multiSelect";
          // options = item.choices.map((choice: any) => choice.choiceLabel);
          options = item.choice_label_texts;
          break;
        case "Number":
          question_type = "number";
          break;
        case "Date & Time":
          question_type = "dateTime";
          break;
        // Add additional cases for other question types if necessary
        default:
          question_type = "text";
      }

      // Based on the 'answerType', initialize other fields
      return {
        id: item.question_id,
        question: item.question_label_text,
        answerType: question_type,
        options: options || [], // Provide an empty array if no options
        textAnswer: "", // Initialize for 'text' input
        singleSelectAnswer: "", // Initialize for 'singleSelect'
        multipleSelectAnswers: [], // Initialize for 'multiSelect'
        numberAnswer: null, // Initialize for 'number' input; use null for no value
        dateAnswer: "", // Initialize for 'date' input
        timeAnswer: "", // Initialize for 'time' input
        dateTimeAnswer: "", // Initialize for 'dateTime' input
        uploadedFiles: [], // Initialize for 'upload' input
        phoneNumberAnswer: "", // Initialize for 'phoneNumber' input
        // Add any additional fields required by the SurveyAnswer interface
      };
    });
  };

  // Function to make an API call and fetch survey data
  // const fetchSurveyData = async (surveyDetails: any) => {};
  const handleGenerateSurvey = async (values: any) => {
    console.log("values", values); // Debug: log the values to the console.
    // Add a mapping for length here
    const surveyLengthMapping: Record<string, string> = {
      "5-10": "10",
      "11-20": "20",
      "20+": "20", // Adjust based on your needs
    };
    setIsLoading(true);
    try {
      // Ensure all required values are present
      if (values) {
        // Find the full language name using the language code
        const fullLanguageName = supportedLanguages.find(
          (lang) => lang.code === values.language
        )?.name;

        if (!fullLanguageName) {
          throw new Error("Language not found");
        }
        const maxLength =
          surveyLengthMapping[values.surveyLength] || values.surveyLength;

        console.log("maxlength:", maxLength);
        // const response = await post<any>("Survey_generator", selectedQuestions, {
        //   headers,
        // });
        const response = await axios.post(
          `https://aitools.kapinsights.com/Survey_generator/?language=${fullLanguageName}&Type=${values.surveyType}&length=${maxLength}`,
          {
            description: values.description,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("responseeee:", response);
        setCompleteSurvey(response?.data);
        const transformedData = transformSurveyData(response.data);
        console.log("translaformedData::", transformedData);
        setSurveyQuestions(transformedData);
        setShowPreview(true);
        // Set the survey configuration for preview
        setSurveyConfiguration({
          type: values.surveyType,
          length: values.surveyLength,
        });
        setIsLoading(false);
      } else {
        message.error("Please fill all required fields.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching survey data:", error);
      setIsLoading(false);
      message.error("Failed to fetch survey data.");
    }
  };

  // const handleLanguageChange = (value: string) => {
  //   console.log("value of  language", value);
  //   setLanguage(value); // Assuming you still need the code as a string for API calls
  // };

  const handleLanguageChange = (value: string) => {
    console.log("value of  language", value);
    const selectedLanguage = supportedLanguages.find(
      (lang) => lang.code === value
    );
    if (selectedLanguage) {
      setLanguage(selectedLanguage.id); // Store the language ID when the language is changed
    }
  };

  useEffect(() => {
    // Fetch the supported languages from the endpoint when the component mounts
    const fetchSupportedLanguages = async () => {
      try {
        const response = await get("languages/", {});
        setSupportedLanguages(response.data as LanguageType[]);
      } catch (error) {
        console.error(
          "There was an error fetching the supported languages:",
          error
        );
      }
    };

    fetchSupportedLanguages();
  }, []);
  const handleSurveyDetailChange = (changedValues: any, allValues: any) => {
    // Update the surveyDetails state with the form values
    setSurveyDetails(allValues);
  };

  const handleTypeChange = (e: any) => {
    setSelectedType(e.target.value);
  };
  const handleLengthChange = (e: any) => {
    setSelectedLength(e.target.value);
  };
  return (
    <>
      {!showPreview ? (
        <>
          <div className="layout-style" style={{ marginLeft: 0 }}>
            <Content
              className="content-style"
              style={{
                padding: "0 24",
                margin: 0,
                minHeight: 280,
              }}
            >
              <div className="header-image">
                {/* Assuming surveyGeneratorHeader contains the image seen in the first picture */}
                <img
                  src={surveyGeneratorHeader}
                  alt="AI Survey Generator"
                  style={{ width: "100%" }}
                />
              </div>

              <Form
                className="survey-form"
                layout="vertical"
                onFinish={handleGenerateSurvey}
                onValuesChange={handleSurveyDetailChange}
                initialValues={surveyDetails}
              >
                <div className="card">
                  <div className="survey-form-first-content">
                    <Form.Item
                      label="Select the language"
                      required
                      name="language"
                      // tooltip="This is a required field"
                      className="label"
                      rules={[
                        {
                          required: true,
                          message: "Please select a language",
                        },
                      ]}
                    >
                      {/* Replace with actual language options */}
                      <Select
                        showSearch
                        placeholder="Select a language"
                        style={{ width: "100%", marginBottom: 24 }}
                        value={language}
                        onChange={handleLanguageChange}
                        filterOption={(input, option: any) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {supportedLanguages?.map((lang) => (
                          <Option key={lang.id} value={lang.code}>
                            {lang.name} - {lang.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div className="survey-form-second-content">
                      <Form.Item
                        name="surveyType"
                        label="Select the survey type"
                        required
                        className="label"
                        rules={[
                          {
                            required: true,
                            message: "Please select a survey type",
                          },
                        ]}
                      >
                        <Radio.Group
                          className="custom-radio-group"
                          value={selectedType}
                          onChange={handleTypeChange}
                        >
                          <div
                            className={`radio-div ${
                              selectedType === "quantitative" ? "active" : ""
                            }`}
                          >
                            <Radio
                              className="custom-radio"
                              value="quantitative"
                              style={{ fontSize: "16px", color: "#999999" }}
                            >
                              Quantitative survey
                            </Radio>
                          </div>
                          <div
                            className={`radio-div ${
                              selectedType === "qualitative" ? "active" : ""
                            }`}
                          >
                            <Radio
                              className="custom-radio"
                              value="qualitative"
                              style={{ fontSize: "16px", color: "#999999" }}
                            >
                              Qualitative survey
                            </Radio>
                          </div>
                          <div
                            className={`radio-div ${
                              selectedType === "both" ? "active" : ""
                            }`}
                          >
                            <Radio
                              className="custom-radio"
                              value="both"
                              style={{ fontSize: "16px", color: "#999999" }}
                            >
                              A bit of both
                            </Radio>
                          </div>
                        </Radio.Group>
                        {/* <Radio.Group
                          onChange={handleTypeChange}
                          value={selectedType}
                        >
                          <div
                            className={`radio-div ${
                              selectedType === "quantitative" ? "active" : ""
                            }`}
                          >
                            <Radio value="quantitative" />
                            Quantitative survey
                          </div>
                          <div
                            className={`radio-div ${
                              selectedType === "qualitative" ? "active" : ""
                            }`}
                          >
                            <Radio value="qualitative" />
                            Qualitative survey
                          </div>
                          <div
                            className={`radio-div ${
                              selectedType === "both" ? "active" : ""
                            }`}
                          >
                            <Radio value="both" />A bit of both
                          </div>
                        </Radio.Group> */}
                      </Form.Item>

                      <Form.Item
                        name="surveyLength"
                        label="Select the survey length"
                        required
                        className="label"
                        rules={[
                          {
                            required: true,
                            message: "Please select a survey length",
                          },
                        ]}
                      >
                        <Radio.Group
                          className="custom-radio-group"
                          value={selectedLength}
                          onChange={handleLengthChange}
                        >
                          <div
                            className={`radio-second-div ${
                              selectedLength === "5-10" ? "active" : ""
                            }`}
                          >
                            <Radio
                              className="custom-radio"
                              value="5-10"
                              style={{ fontSize: "16px", color: "#999999" }}
                            >
                              <span>5-10</span>
                              <br />
                              Questions
                            </Radio>
                          </div>
                          <div
                            className={`radio-second-div ${
                              selectedLength === "11-20" ? "active" : ""
                            }`}
                          >
                            <Radio
                              className="custom-radio"
                              value="11-20"
                              style={{ fontSize: "16px", color: "#999999" }}
                            >
                              <span>11-20</span>
                              <br />
                              Questions
                            </Radio>
                          </div>
                          <div
                            className={`radio-second-div ${
                              selectedLength === "20+" ? "active" : ""
                            }`}
                          >
                            <Radio
                              className="custom-radio"
                              value="20+"
                              style={{ fontSize: "16px", color: "#999999" }}
                            >
                              <span>20+</span>
                              <br />
                              Questions
                            </Radio>
                          </div>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="first-inner-section">
                      <div className="first-inner-content">
                        <img
                          src={surveyGeneratorPhoto}
                          alt="survey generator"
                          className="surveyGeneratorImg"
                        />
                        <div className="discribe-your-survey-section">
                          <Form.Item
                            label="Describe your survey"
                            required
                            name="description"
                            // tooltip="This is a required field"
                            className="label"
                            rules={[
                              {
                                required: true,
                                message: "Please describe your survey",
                              },
                            ]}
                          >
                            <TextArea
                              placeholder="Enter details here"
                              autoSize={{ minRows: 3, maxRows: 20 }}
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              disabled={userRole === "Guest"}
                              htmlType="submit"
                              className="generateButton"
                              // style={{
                              //   width: "325px",
                              //   height: "43px",
                              //   backgroundColor: "#A072EB",
                              //   border: "none",
                              // }}
                            >
                              {isLoading ? <Spin /> : "Generate your survey"}
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Content>
          </div>
        </>
      ) : (
        <SurveyPreview
          config={surveyConfiguration}
          surveyQuestions={surveyQuestions}
          completeSurvey={completeSurvey}
          languageId={language} // Pass the language ID here
        />
      )}
    </>
  );
};
export default AISurveyGenerator;

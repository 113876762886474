import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AITranslation from "./components/AITools/AITranslation/AITranslationList";
import AddAITranslation from "./components/AITools/AITranslation/AddAIToolsTranslation";
import AISurveyGenerator from "./components/AITools/SurveyGenerator/GenerateSurvey";
import DeletedAiTranslation from "./components/DeletedItems";
import PageLayout from "./components/PageLayout";
import SurveyBuilder from "./components/FormBuilder";
import Unsubscribe from "./pages/Unsubscribe";
import Workspaces from "./components/Workspaces";
import { ToastContainer } from "react-toastify";
import ProjectsList from "./components/Projects/ProjectsList";
import MembersList from "./components/UserManagement/MembersList";
import ProjectDashboard from "./components/Projects/ProjectDashboard/ProjectDashboard";
import ProfileInformation from "./components/AccountSettings/ProfileInformation";
import ProtectedRoute from "./hooks/protectedRoute";
import Unauthorized from "./hooks/unauthorized";
import UploadSurveyPage from "./components/FormBuilder/UploadSurveyPage";

const App: React.FC = () => {
  return (
    <div className="app-container">
      <div className="content">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<PageLayout />}>
            {/* <Route
              path="/home"
              element={
                <ProtectedRoute
                  allowedRoles={["admin", "Guest", "Project Manager"]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<Home />} />
            </Route> */}
            <Route
              path="/profile-information"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Guest",
                    "Technical Expert",
                    "Data Collector",
                    "Translation People",
                    "Quality Assurance",
                    "Project Manager",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<ProfileInformation />} />
            </Route>
            <Route
              path="/ai-translation/:id"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Technical Expert",
                    "Translation People",
                    "Quality Assurance",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<AITranslation />} />
            </Route>
            <Route
              path="/add-ai-translation/:id"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Technical Expert",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<AddAITranslation />} />
            </Route>
            <Route
              path="/ai-survey-generator"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Technical Expert",
                    "Data Collector",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<AISurveyGenerator />} />
            </Route>
            {/* <Route
              path="/deleted-items"
              element={
                <ProtectedRoute
                  allowedRoles={["admin", "Project Manager"]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<DeletedAiTranslation />} />
            </Route> */}
            <Route
              path="/upload-survey/:id"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Data Collector",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<UploadSurveyPage />} />
            </Route>
            <Route
              path="/survey-builder/:id"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Data Collector",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<SurveyBuilder />} />
            </Route>
            <Route
              path="/workspaces"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Guest",
                    "Technical Expert",
                    "Data Collector",
                    "Translation People",
                    "Quality Assurance",
                    "Project Manager",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<Workspaces />} />
            </Route>
            <Route
              path="/projects/:workspaceId"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Technical Expert",
                    "Data Collector",
                    "Translation People",
                    "Quality Assurance",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<ProjectsList />} />
            </Route>
            <Route
              path="/projects/:id/details"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "Project Manager",
                    "Technical Expert",
                    "Data Collector",
                    "Translation People",
                    "Quality Assurance",
                    "Guest",
                  ]}
                  redirectPath="/unauthorized"
                />
              }
            >
              <Route path="" element={<ProjectDashboard />} />
            </Route>
            <Route
              path="/members"
              element={
                <ProtectedRoute allowedRoles={["admin", "Project Manager"]} />
              }
            >
              <Route path="" element={<MembersList />} />
            </Route>
          </Route>
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;

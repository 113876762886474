// import axios, { AxiosInstance } from "axios";

// const api: AxiosInstance = axios.create({
//   baseURL: "https://aitools.kapinsights.com/api/",
// });

// api.interceptors.request.use((config: any) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

// export default api;

import axios, { AxiosInstance } from "axios";

const api: AxiosInstance = axios.create({
  baseURL: "https://aitools.kapinsights.com/api/",
});

const refreshToken = async () => {
  console.log("inside the interceptor");

  try {
    const refresh_token = localStorage.getItem("refreshToken");
    console.log("ref", refresh_token);

    if (!refresh_token) {
      throw new Error("No refresh token available");
    }
    const response = await axios.post(
      `https://aitools.kapinsights.com/Users/token/refresh?refresh_token=${refresh_token}`,
      null,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    const { access_token } = response.data;
    localStorage.setItem("token", access_token);
    return access_token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

api.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const access_token = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return axios(originalRequest);
      } catch (error) {
        console.error("Error refreshing token:", error);
        throw error;
      }
    }
    return Promise.reject(error);
  }
);

export default api;

import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Input,
  InputNumber,
  DatePicker,
  TimePicker,
  Upload,
  Radio,
  Checkbox,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import survey from "../../../../assets/img/survey.png";
import {} from "antd";
import { UploadOutlined, PrinterOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { UploadFile } from "antd/lib/upload/interface";
import dayjs, { Dayjs } from "dayjs";
import { RadioChangeEvent } from "antd/lib/radio";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { UploadChangeParam } from "antd/lib/upload";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// ... rest of your code

// import "./index.css";
const { Content } = Layout;
const { Option } = Select;

interface SurveyPreviewProps {
  config: {
    type: "both" | "quantitative" | "qualitative";
    length: "5-10" | "11-20" | "20+";
  };
  surveyQuestions: any;
  completeSurvey: any;
  languageId: string; // Add this line
}

interface Question {
  id: number;
  text: string;
}
// interface SurveyAnswer {
//   question: string;
//   answer: string;
//   singleSelectAnswer?: string;
//   multipleSelectAnswers?: string[];
// }

interface SurveyAnswer {
  id: number;
  question: string;
  answerType:
    | "text"
    | "singleSelect"
    | "multiSelect"
    | "number"
    | "date"
    | "time"
    | "dateTime"
    | "upload"
    | "phoneNumber";
  options?: string[]; // For singleSelect and multiSelect
  textAnswer?: string; // For text input
  singleSelectAnswer?: string; // For singleSelect
  multipleSelectAnswers?: string[]; // For multiSelect
  numberAnswer?: number; // For number input
  dateAnswer?: string; // For date input
  timeAnswer?: string; // For time input
  dateTimeAnswer?: string; // For date and time input
  uploadedFiles?: UploadFile[]; // For Upload input
  phoneNumberAnswer?: string; // For phone number input
}

const SurveyPreview: React.FC<SurveyPreviewProps> = ({
  config,
  surveyQuestions,
  completeSurvey,
  languageId, // Make sure to destructure languageId here
}) => {
  const navigate = useNavigate();
  const { type, length } = config;
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [surveyId, setSurveyId] = useState();
  console.log("suveyQuestions:", surveyQuestions);
  console.log("completeSurvey:", completeSurvey);
  const questions: Question[] = [
    { id: 1, text: "How satisfied are you with our product?" },
    { id: 2, text: "What features do you use the most?" },
    { id: 3, text: "3What features do you use the most?" },
    { id: 4, text: "4What features do you use the most?" },
    { id: 5, text: "5What features do you use the most?" },
    { id: 6, text: "6What features do you use the most?" },
    // ... potentially more questions
  ].slice(0, length === "5-10" ? 5 : 10); // Adjust the number of questions shown based on the length
  const [surveyAnswers, setSurveyAnswers] =
    useState<SurveyAnswer[]>(surveyQuestions);
  console.log("languageId:", languageId);
  const [answers, setAnswers] = useState<SurveyAnswer[]>([
    {
      id: 1,
      question:
        "Q1. How would you rate your overall satisfaction with our coffee shop?",
      answerType: "singleSelect",
      options: ["Not satisfied", "Satisfied", "Very satisfied"],
    },
    {
      id: 2,
      question: "Q2. How would you rate the friendliness of our staff?",
      answerType: "multiSelect",
      options: ["Friendly", "Very Friendly"],
    },
    {
      id: 3,
      question: "Q3. How often do you visit our coffe shop?",
      answerType: "singleSelect",
      options: ["Daily", "Several times a week", "Once a week"],
    },
    {
      id: 4,
      question:
        "Q4. What decorate would you like to see added to our coffe shop?",
      answerType: "text",
      options: undefined,
    },
    {
      id: 5,
      question: "Q5. Which of our coffee varieties do you enjoy the most?",
      answerType: "multiSelect",
      options: ["Americano", "Latte", "Espresso"],
    },
    {
      id: 6,
      question:
        "Q6. If you could change one thing about our coffee shop, what would it be?",
      answerType: "text",
      options: undefined,
    },
    {
      id: 7,
      question:
        "Q7. What do you enjoy most about our coffee shop, and what can we improve?",
      answerType: "text",
      options: undefined,
    },
    {
      id: 8,
      question: "Q8. How many cups of coffee do you drink a day?",
      answerType: "number",
      numberAnswer: 0, // Initialize with null or a default number
    },

    // Example of a date question
    {
      id: 9,
      question: "Q9. When is your birthday?",
      answerType: "date",
      dateAnswer: "", // Initialize with an empty string or a default date
    },

    // Example of a time question
    {
      id: 10,
      question: "Q10. What time of day do you usually visit our coffee shop?",
      answerType: "time",
      timeAnswer: "", // Initialize with an empty string or a default time
    },

    // Example of a date and time question
    {
      id: 11,
      question: "Q11. When did you last visit our coffee shop?",
      answerType: "dateTime",
      dateTimeAnswer: "", // Initialize with an empty string or a default date-time
    },

    // Example of an upload question
    {
      id: 12,
      question: "Q12. Please upload a picture of your favorite coffee cup.",
      answerType: "upload",
      uploadedFiles: [], // Initialize with an empty array
    },

    // Example of a phone number question
    {
      id: 13,
      question: "Q13. What is your contact number?",
      answerType: "phoneNumber",
      phoneNumberAnswer: "", // Initialize with an empty string or a default phone number
    },
  ]);

  // const handleInputChange = (index: number, text: string) => {
  //   const newAnswers = [...answers];
  //   newAnswers[index].answer = text;
  //   setAnswers(newAnswers);
  // };
  // const handleRadioChange = (questionId: number, e: any) => {
  //   const newAnswers = answers.map((ans: any) => {
  //     if (ans.question === questionId) {
  //       return { ...ans, singleSelectAnswer: e.target.value };
  //     }
  //     return ans;
  //   });
  //   setAnswers(newAnswers);
  // };

  // const handleCheckboxChange = (
  //   questionId: number,
  //   checkedValues: string[]
  // ) => {
  //   const newAnswers = answers.map((ans: any) => {
  //     if (ans.question === questionId) {
  //       return { ...ans, multipleSelectAnswers: checkedValues };
  //     }
  //     return ans;
  //   });
  //   setAnswers(newAnswers);
  // };

  const handleInputChange = (questionId: string, text: string) => {
    setSurveyAnswers((prevAnswers) =>
      prevAnswers.map((answer: any) =>
        answer.id === questionId ? { ...answer, textAnswer: text } : answer
      )
    );
  };

  const handleRadioChange = (questionId: string, e: RadioChangeEvent) => {
    setSurveyAnswers((prevAnswers) =>
      prevAnswers.map((answer: any) =>
        answer.id === questionId
          ? { ...answer, singleSelectAnswer: e.target.value }
          : answer
      )
    );
  };

  const handleCheckboxChange = (
    questionId: string,
    checkedValues: CheckboxValueType[]
  ) => {
    setSurveyAnswers((prevAnswers) =>
      prevAnswers.map((answer: any) =>
        answer.id === questionId
          ? { ...answer, multipleSelectAnswers: checkedValues }
          : answer
      )
    );
  };

  console.log("surveyAnswers dddd", surveyAnswers);
  // Function to save the survey responses
  const handleSave = async () => {
    const requestBody = completeSurvey.map((completeQuestion: any) => {
      // Find the matching question in surveyAnswers by id
      const surveyAnswer = surveyAnswers.find(
        (answer) => answer.id === completeQuestion.question_id
      );

      // Determine the response value based on the answer type
      let responseValue;
      switch (surveyAnswer?.answerType) {
        case "text":
          responseValue = surveyAnswer.textAnswer;
          break;
        case "singleSelect":
          responseValue = surveyAnswer.singleSelectAnswer;
          break;
        case "multiSelect":
          responseValue = surveyAnswer.multipleSelectAnswers?.join(", ");
          break;
        case "number":
          responseValue = surveyAnswer.numberAnswer;
          break;
        case "date":
        case "time":
        case "dateTime":
          responseValue =
            surveyAnswer.dateAnswer ||
            surveyAnswer.timeAnswer ||
            surveyAnswer.dateTimeAnswer;
          break;
        default:
          responseValue = "";
      }

      // Merge the static data with the dynamic data from surveyAnswers
      return {
        ...completeQuestion, // Static data from completeSurvey
        response: responseValue, // Dynamic data from surveyAnswers
      };
    });
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://aitools.kapinsights.com/save_survey", // Your API endpoint
        requestBody, // The data payload
        {
          headers: {
            Authorization: `Bearer ${token}`, // Make sure the token is correctly included and valid
          },
          params: {
            language_id: languageId, // Pass languageId as a query parameter
          },
        }
      );
      console.log("Survey saved", response.data);
      message.success("Saved successfully");
      setSurveyId(response.data.survey_id); // Assuming your API returns the survey_id
      setExportModalVisible(true); // Show the export modal if needed
      setIsLoading(false);
    } catch (error) {
      console.error("Error saving survey", error);
      message.error("There was an error saving the survey.");
      setIsLoading(false);
    }
  };

  // const handleSave = async () => {
  //   const surveyData = {
  //     response: surveyAnswers.map((answer) => ({
  //       questionId: answer.id,
  //       response:
  //         answer.textAnswer ||
  //         answer.singleSelectAnswer ||
  //         (answer.multipleSelectAnswers &&
  //           answer.multipleSelectAnswers.join(", ")) ||
  //         answer.numberAnswer ||
  //         answer.dateAnswer ||
  //         answer.timeAnswer ||
  //         answer.dateTimeAnswer ||
  //         (answer.uploadedFiles &&
  //           answer.uploadedFiles.map((f) => f.name).join(", ")) ||
  //         answer.phoneNumberAnswer ||
  //         "",
  //     })),
  //   };

  //   try {
  //     const response = await axios.post(
  //       "http://15.185.185.85:5001/add-response/",
  //       surveyData
  //     );
  //     console.log("Survey saved", response.data?.survey_id);
  //     message.success("Saved successfully");
  //     setSurveyId(response?.data?.survey_id);
  //     setExportModalVisible(true); // Show the export modal
  //   } catch (error) {
  //     console.error("Error saving survey", error);
  //     message.error("There was an error saving the survey.");
  //   }

  // };

  // Function to export the survey responses
  const handleExport = async () => {
    if (!surveyId) {
      message.error("No survey ID available for export.");
      return;
    }
    try {
      const response = await axios.get(
        `https://aitools.kapinsights.com/download_survey/${surveyId}?default_language_id=${languageId}`,
        {
          responseType: "blob", // Important for file downloads
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Exported data:", response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // const fileExtension =
      //   type === "excel" ? ".xlsx" : type === "json" ? ".json" : ".txt"; // default to plain text
      link.setAttribute("download", `survey.xlsx"`);
      document.body.appendChild(link);
      link.click();
      // Clean up
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      message.success("Survey downloaded successfully");
      navigate("/workspaces");
    } catch (error) {
      console.error("Error exporting survey data", error);
      message.error("There was an error exporting the survey data.");
    }
    setExportModalVisible(false); // Hide the export modal after the export
  };

  // Handlers for new question types
  const handleNumericChange = (questionId: string, value: number | null) => {
    setSurveyAnswers((prevAnswers) =>
      prevAnswers.map((answer: any) =>
        answer.id === questionId
          ? { ...answer, numberAnswer: value || undefined }
          : answer
      )
    );
  };
  const handleDateChange = (questionId: string, date: Dayjs | null) => {
    setSurveyAnswers((prevAnswers) =>
      prevAnswers.map((answer: any) =>
        answer.id === questionId
          ? { ...answer, dateAnswer: date ? date.format("YYYY-MM-DD") : "" }
          : answer
      )
    );
  };

  const handleTimeChange = (questionId: number, time: Dayjs | null) => {
    setAnswers(
      answers.map((answer) =>
        answer.id === questionId
          ? { ...answer, timeAnswer: time ? time.format("HH:mm") : "" }
          : answer
      )
    );
  };

  const handleDateTimeChange = (questionId: number, dateTime: Dayjs | null) => {
    setAnswers(
      answers.map((answer) =>
        answer.id === questionId
          ? { ...answer, dateTimeAnswer: dateTime ? dateTime.format() : "" }
          : answer
      )
    );
  };

  const handleFileChange = (
    questionId: string,
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    setSurveyAnswers((prevAnswers) =>
      prevAnswers.map((answer: any) =>
        answer.id === questionId
          ? { ...answer, uploadedFiles: info.fileList }
          : answer
      )
    );
  };

  const handlePhoneNumberChange = (questionId: number, value: string) => {
    setAnswers(
      answers.map((answer) =>
        answer.id === questionId
          ? { ...answer, phoneNumberAnswer: value }
          : answer
      )
    );
  };

  // You will also need to handle the country code separately if you wish to store it
  const handleCountryCodeChange = (questionId: number, countryCode: string) => {
    // This would require your SurveyAnswer to have a new field for the country code
    setAnswers(
      answers.map((answer) =>
        answer.id === questionId
          ? { ...answer, phoneNumberCountryCode: countryCode }
          : answer
      )
    );
  };
  // The component for selecting country codes
  const prefixSelector = (
    <Select style={{ width: 70 }} disabled>
      {/* Map your country codes here */}
      <Option value="1">+1</Option>
      <Option value="39">+39</Option>
      {/* ... other country codes */}
    </Select>
  );
  // Rendering different types of answers based on the answer type
  const renderAnswerField = (answer: SurveyAnswer) => {
    switch (answer.answerType) {
      case "singleSelect":
        return (
          <Radio.Group
            onChange={(e) => handleRadioChange(answer.id.toString(), e)}
            value={answer.singleSelectAnswer}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {answer.options?.map((option, index) => (
                <div
                  style={{
                    // width: "100%",
                    // width: "955px",
                    border: "1px solid #9BACEB",
                    padding: "4.5px 1rem",
                    borderRadius: "5px ",
                  }}
                >
                  <Radio key={index} value={option} disabled>
                    {option}
                  </Radio>
                </div>
              ))}
            </div>
          </Radio.Group>
        );

      case "multiSelect":
        return (
          <Checkbox.Group
            onChange={(checkedValues) =>
              handleCheckboxChange(answer.id.toString(), checkedValues)
            }
            value={answer.multipleSelectAnswers}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              {answer.options?.map((option, index) => (
                <div
                  style={{
                    // width: "100%",
                    // width: "955px",
                    border: "1px solid #9BACEB",
                    padding: "4.5px 1rem",
                    borderRadius: "5px ",
                  }}
                >
                  <Checkbox key={index} value={option} disabled>
                    {option}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        );
      case "text":
        return (
          <Input
            type="text"
            disabled
            value={answer.textAnswer || ""}
            onChange={(e) =>
              handleInputChange(answer.id.toString(), e.target.value)
            }
            style={{
              height: "50px", // Set the height you want
              borderRadius: "5px", // Match your design
              padding: "12px", // More padding inside the input
              // Add any other styles you need to match your design
            }}
          />
        );
      case "number":
        return (
          <InputNumber
            min={1}
            max={100}
            disabled
            value={answer.numberAnswer || 1}
            onChange={(value) =>
              handleNumericChange(answer.id.toString(), value)
            }
          />
        );
      case "date":
        return (
          <DatePicker
            value={answer.dateAnswer ? dayjs(answer.dateAnswer) : undefined}
            onChange={(date: Dayjs | null) =>
              handleDateChange(answer.id.toString(), date)
            }
            disabled
          />
        );
      case "time":
        return (
          <TimePicker
            disabled
            value={
              answer.timeAnswer ? dayjs(answer.timeAnswer, "HH:mm") : undefined
            }
            onChange={(time: Dayjs | null) => handleTimeChange(answer.id, time)}
          />
        );
      case "dateTime":
        return (
          <DatePicker
            showTime
            disabled
            value={
              answer.dateTimeAnswer ? dayjs(answer.dateTimeAnswer) : undefined
            }
            onChange={(dateTime: Dayjs | null) =>
              handleDateTimeChange(answer.id, dateTime)
            }
          />
        );
      case "upload":
        return (
          <Upload
            disabled
            listType="picture"
            beforeUpload={() => false} // Do not automatically upload
            onChange={(info) => handleFileChange(answer.id.toString(), info)}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        );
      case "phoneNumber":
        return (
          <Input
            disabled
            addonBefore={prefixSelector}
            value={answer.phoneNumberAnswer || ""}
            onChange={(e) => handlePhoneNumberChange(answer.id, e.target.value)}
          />
        );
      // ... include other case statements for singleSelect, multiSelect
      default:
        return null;
    }
  };
  useEffect(() => {
    setSurveyAnswers(surveyQuestions);
  }, [surveyQuestions]);
  // Render the survey questions
  {
    console.log("surveyAnswers:", surveyAnswers);
  }
  const renderedQuestions = surveyAnswers.map((answer, index) => (
    <div key={answer.id} style={{ marginBottom: "3rem" }}>
      <p>{answer.question}</p>
      {renderAnswerField(answer)}
    </div>
  ));
  return (
    <>
      <div className="layout-style-preview">
        <div className="first-section" style={{ marginBottom: "1rem" }}>
          <img src={survey} alt="survey" />
          <div className="second-part-preview">
            <h3>Survey</h3>
            <hr
              className="hr"
              style={{
                border: "1px solid #9BACEB ",
                width: "850px",
              }}
            />
            <h1>Survey name</h1>
          </div>
        </div>
        {/* <div className="print-survey">
          <Button className="print-button">
            <PrinterOutlined />
            Print survey
          </Button>
        </div> */}
      </div>
      <div className="layout-style">
        <Content className="content-style">
          <div style={{ padding: "2rem 4rem", backgroundColor: "white" }}>
            <h1>Survey Section</h1>
            <hr />
            {/* {answers.map((ans, index) => (
              <div key={index}>
                <p>{ans.question}</p>
                <input
                  type="text"
                  style={{ width: "100%", height: "40px" }}
                  value={ans.answer}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              </div>
            ))} */}
            {/* {answers.map((answer) => (
              <div key={answer.id} style={{ marginBottom: "3rem" }}>
                <p>{answer.question}</p>
                {renderAnswerField(answer)}
              </div>
            ))} */}
            {renderedQuestions}
            {/* {answers.map((answer, index) => (
              <div key={answer.id} style={{ marginBottom: "3rem" }}>
                <p>{answer.question}</p>
                {answer.answerType === "text" && (
                  <input
                    type="text"
                    style={{ width: "100%", height: "40px" }}
                    value={answer.textAnswer || ""}
                    onChange={(e) =>
                      handleInputChange(answer.id, e.target.value)
                    }
                  />
                )}
                {answer.answerType === "singleSelect" && (
                  <Radio.Group
                    onChange={(e: any) => handleRadioChange(answer.id, e)}
                    value={answer.singleSelectAnswer}
                  >
                    {answer.options?.map((option) => (
                      <Radio key={option} value={option}>
                        {option}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
                {answer.answerType === "multiSelect" && (
                  <Checkbox.Group
                    onChange={(checkedValues: any) =>
                      handleCheckboxChange(answer.id, checkedValues)
                    }
                    value={answer.multipleSelectAnswers}
                  >
                    {answer.options?.map((option) => (
                      <Checkbox key={option} value={option}>
                        {option}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                )}
              </div>
            ))} */}

            <div
              className="save-survey"
              style={{ textAlign: "center", marginTop: "2rem" }}
            >
              <Button className="save-survey-btn" onClick={handleSave}>
                {isLoading ? <Spin /> : "Save"}
              </Button>
            </div>
          </div>
        </Content>
      </div>
      <Modal
        title="Export Survey"
        visible={exportModalVisible}
        onOk={handleExport}
        onCancel={() => setExportModalVisible(false)}
        style={{ textAlign: "center" }}
        footer={[
          <Button key="cancel" onClick={() => setExportModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="export" type="primary" onClick={handleExport}>
            Export
          </Button>,
        ]}
      >
        <p>
          To export your survey responses, please click the 'Export' button.
        </p>
        <Button
          key="export"
          type="primary"
          onClick={handleExport}
          style={{ border: "unset !important" }}
        >
          Export
        </Button>
      </Modal>
    </>
  );
};

export default SurveyPreview;
